import React from "react";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function MenuElentListActive() {
    return (
        <li className="list actives">
            <Link to="/news">
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="svg-icon-fill" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.79 22.75H6.21C3.47 22.75 1.25 20.52 1.25 17.78V10.37C1.25 9.00997 2.09 7.29997 3.17 6.45997L8.56 2.25997C10.18 0.999974 12.77 0.939974 14.45 2.11997L20.63 6.44997C21.82 7.27997 22.75 9.05997 22.75 10.51V17.79C22.75 20.52 20.53 22.75 17.79 22.75ZM9.48 3.43997L4.09 7.63997C3.38 8.19997 2.75 9.46997 2.75 10.37V17.78C2.75 19.69 4.3 21.25 6.21 21.25H17.79C19.7 21.25 21.25 19.7 21.25 17.79V10.51C21.25 9.54997 20.56 8.21997 19.77 7.67997L13.59 3.34997C12.45 2.54997 10.57 2.58997 9.48 3.43997Z" />
                        <path d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18C12.75 18.41 12.41 18.75 12 18.75Z" />
                    </svg>
                </span>
                {/* <span className="text">Новости</span> */}
            </Link>
        </li>
    )
}

function MenuElentListDisable() {
    return (
        <li className="list">
            <Link to="/news">
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="svg-icon-fill" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.79 22.75H6.21C3.47 22.75 1.25 20.52 1.25 17.78V10.37C1.25 9.00997 2.09 7.29997 3.17 6.45997L8.56 2.25997C10.18 0.999974 12.77 0.939974 14.45 2.11997L20.63 6.44997C21.82 7.27997 22.75 9.05997 22.75 10.51V17.79C22.75 20.52 20.53 22.75 17.79 22.75ZM9.48 3.43997L4.09 7.63997C3.38 8.19997 2.75 9.46997 2.75 10.37V17.78C2.75 19.69 4.3 21.25 6.21 21.25H17.79C19.7 21.25 21.25 19.7 21.25 17.79V10.51C21.25 9.54997 20.56 8.21997 19.77 7.67997L13.59 3.34997C12.45 2.54997 10.57 2.58997 9.48 3.43997Z" />
                        <path d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18C12.75 18.41 12.41 18.75 12 18.75Z" />
                    </svg>
                </span>
                {/* <span className="text">Новости</span> */}
            </Link>
        </li>
    )
}

function MenuElementHomeActive() {
    return (
        <li className="list actives">
            <Link to="/home">
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="svg-icon-fill" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.1205 13.5305C12.1005 13.5305 12.0705 13.5305 12.0505 13.5305C12.0205 13.5305 11.9805 13.5305 11.9505 13.5305C9.68047 13.4605 7.98047 11.6905 7.98047 9.51047C7.98047 7.29047 9.79047 5.48047 12.0105 5.48047C14.2305 5.48047 16.0405 7.29047 16.0405 9.51047C16.0305 11.7005 14.3205 13.4605 12.1505 13.5305C12.1305 13.5305 12.1305 13.5305 12.1205 13.5305ZM12.0005 6.97047C10.6005 6.97047 9.47047 8.11047 9.47047 9.50047C9.47047 10.8705 10.5405 11.9805 11.9005 12.0305C11.9305 12.0205 12.0305 12.0205 12.1305 12.0305C13.4705 11.9605 14.5205 10.8605 14.5305 9.50047C14.5305 8.11047 13.4005 6.97047 12.0005 6.97047Z" />
                        <path d="M12.0008 22.7503C9.31081 22.7503 6.74081 21.7503 4.75081 19.9303C4.57081 19.7703 4.49081 19.5303 4.51081 19.3003C4.64081 18.1103 5.38081 17.0003 6.61081 16.1803C9.59081 14.2003 14.4208 14.2003 17.3908 16.1803C18.6208 17.0103 19.3608 18.1103 19.4908 19.3003C19.5208 19.5403 19.4308 19.7703 19.2508 19.9303C17.2608 21.7503 14.6908 22.7503 12.0008 22.7503ZM6.08081 19.1003C7.74081 20.4903 9.83081 21.2503 12.0008 21.2503C14.1708 21.2503 16.2608 20.4903 17.9208 19.1003C17.7408 18.4903 17.2608 17.9003 16.5508 17.4203C14.0908 15.7803 9.92081 15.7803 7.44081 17.4203C6.73081 17.9003 6.26081 18.4903 6.08081 19.1003Z" />
                        <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" />
                    </svg>
                </span>
                {/* <span className="text">Профиль</span> */}
            </Link>
        </li>
    )
}

function MenuElementHomeDisable() {
    return (
        <li className="list">
            <Link to="/home">
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="svg-icon-fill" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.1205 13.5305C12.1005 13.5305 12.0705 13.5305 12.0505 13.5305C12.0205 13.5305 11.9805 13.5305 11.9505 13.5305C9.68047 13.4605 7.98047 11.6905 7.98047 9.51047C7.98047 7.29047 9.79047 5.48047 12.0105 5.48047C14.2305 5.48047 16.0405 7.29047 16.0405 9.51047C16.0305 11.7005 14.3205 13.4605 12.1505 13.5305C12.1305 13.5305 12.1305 13.5305 12.1205 13.5305ZM12.0005 6.97047C10.6005 6.97047 9.47047 8.11047 9.47047 9.50047C9.47047 10.8705 10.5405 11.9805 11.9005 12.0305C11.9305 12.0205 12.0305 12.0205 12.1305 12.0305C13.4705 11.9605 14.5205 10.8605 14.5305 9.50047C14.5305 8.11047 13.4005 6.97047 12.0005 6.97047Z" />
                        <path d="M12.0008 22.7503C9.31081 22.7503 6.74081 21.7503 4.75081 19.9303C4.57081 19.7703 4.49081 19.5303 4.51081 19.3003C4.64081 18.1103 5.38081 17.0003 6.61081 16.1803C9.59081 14.2003 14.4208 14.2003 17.3908 16.1803C18.6208 17.0103 19.3608 18.1103 19.4908 19.3003C19.5208 19.5403 19.4308 19.7703 19.2508 19.9303C17.2608 21.7503 14.6908 22.7503 12.0008 22.7503ZM6.08081 19.1003C7.74081 20.4903 9.83081 21.2503 12.0008 21.2503C14.1708 21.2503 16.2608 20.4903 17.9208 19.1003C17.7408 18.4903 17.2608 17.9003 16.5508 17.4203C14.0908 15.7803 9.92081 15.7803 7.44081 17.4203C6.73081 17.9003 6.26081 18.4903 6.08081 19.1003Z" />
                        <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" />
                    </svg>
                </span>
                {/* <span className="text">Профиль</span> */}
            </Link>
        </li>
    )
}

function MenuElementSmsActive() {
    return (
        <li className="list actives">
            <Link to="/sms">
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="svg-icon-fill" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.98999 22.78C9.38999 22.78 8.81999 22.48 8.42999 21.95L7.23001 20.35C7.23001 20.36 7.18 20.33 7.16 20.33H6.79001C3.37001 20.33 1.25 19.4 1.25 14.79V10.79C1.25 6.58001 3.82001 5.48001 5.98001 5.29001C6.22001 5.26001 6.50001 5.25 6.79001 5.25H13.19C16.81 5.25 18.73 7.17001 18.73 10.79V14.79C18.73 15.08 18.72 15.36 18.68 15.63C18.5 17.76 17.4 20.33 13.19 20.33H12.79L11.55 21.95C11.16 22.48 10.59 22.78 9.98999 22.78ZM6.79001 6.75C6.56001 6.75 6.34 6.76 6.13 6.78C3.81 6.98 2.75 8.25001 2.75 10.79V14.79C2.75 18.22 3.81001 18.83 6.79001 18.83H7.19C7.64 18.83 8.14999 19.08 8.42999 19.44L9.63 21.05C9.85001 21.35 10.13 21.35 10.35 21.05L11.55 19.45C11.84 19.06 12.3 18.83 12.79 18.83H13.19C15.73 18.83 17 17.76 17.19 15.48C17.22 15.24 17.23 15.02 17.23 14.79V10.79C17.23 8.00001 15.98 6.75 13.19 6.75H6.79001Z" />
                        <path d="M9.99023 14.1904C9.43023 14.1904 8.99023 13.7404 8.99023 13.1904C8.99023 12.6404 9.44023 12.1904 9.99023 12.1904C10.5402 12.1904 10.9902 12.6404 10.9902 13.1904C10.9902 13.7404 10.5502 14.1904 9.99023 14.1904Z" />
                        <path d="M13.1895 14.1904C12.6295 14.1904 12.1895 13.7404 12.1895 13.1904C12.1895 12.6404 12.6395 12.1904 13.1895 12.1904C13.7395 12.1904 14.1895 12.6404 14.1895 13.1904C14.1895 13.7404 13.7395 14.1904 13.1895 14.1904Z" />
                        <path d="M6.80078 14.1904C6.24078 14.1904 5.80078 13.7404 5.80078 13.1904C5.80078 12.6404 6.25078 12.1904 6.80078 12.1904C7.35078 12.1904 7.80078 12.6404 7.80078 13.1904C7.80078 13.7404 7.35078 14.1904 6.80078 14.1904Z" />
                        <path d="M17.9396 16.29C17.7396 16.29 17.5396 16.21 17.3996 16.06C17.2396 15.9 17.1697 15.67 17.1997 15.45C17.2297 15.24 17.2396 15.02 17.2396 14.79V10.79C17.2396 8.00001 15.9897 6.75 13.1997 6.75H6.79963C6.56963 6.75 6.34966 6.76 6.13966 6.78C5.91966 6.81 5.68964 6.72999 5.52964 6.57999C5.36964 6.41999 5.27963 6.20001 5.29963 5.98001C5.47963 3.82001 6.58963 1.25 10.7996 1.25H17.1997C20.8197 1.25 22.7396 3.17001 22.7396 6.79001V10.79C22.7396 15 20.1697 16.1 18.0097 16.29C17.9797 16.29 17.9596 16.29 17.9396 16.29ZM6.91966 5.25H13.1896C16.8096 5.25 18.7297 7.17001 18.7297 10.79V14.66C20.4297 14.24 21.2297 12.99 21.2297 10.79V6.79001C21.2297 4.00001 19.9796 2.75 17.1896 2.75H10.7897C8.58965 2.75 7.34966 3.55 6.91966 5.25Z" />
                    </svg>
                </span>
                {/* <span className="text">Чат</span> */}
            </Link>
        </li>
    )
}

function MenuElementSmsDisable() {
    return (
        <li className="list">
            <Link to="/sms">
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="svg-icon-fill" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.98999 22.78C9.38999 22.78 8.81999 22.48 8.42999 21.95L7.23001 20.35C7.23001 20.36 7.18 20.33 7.16 20.33H6.79001C3.37001 20.33 1.25 19.4 1.25 14.79V10.79C1.25 6.58001 3.82001 5.48001 5.98001 5.29001C6.22001 5.26001 6.50001 5.25 6.79001 5.25H13.19C16.81 5.25 18.73 7.17001 18.73 10.79V14.79C18.73 15.08 18.72 15.36 18.68 15.63C18.5 17.76 17.4 20.33 13.19 20.33H12.79L11.55 21.95C11.16 22.48 10.59 22.78 9.98999 22.78ZM6.79001 6.75C6.56001 6.75 6.34 6.76 6.13 6.78C3.81 6.98 2.75 8.25001 2.75 10.79V14.79C2.75 18.22 3.81001 18.83 6.79001 18.83H7.19C7.64 18.83 8.14999 19.08 8.42999 19.44L9.63 21.05C9.85001 21.35 10.13 21.35 10.35 21.05L11.55 19.45C11.84 19.06 12.3 18.83 12.79 18.83H13.19C15.73 18.83 17 17.76 17.19 15.48C17.22 15.24 17.23 15.02 17.23 14.79V10.79C17.23 8.00001 15.98 6.75 13.19 6.75H6.79001Z" />
                        <path d="M9.99023 14.1904C9.43023 14.1904 8.99023 13.7404 8.99023 13.1904C8.99023 12.6404 9.44023 12.1904 9.99023 12.1904C10.5402 12.1904 10.9902 12.6404 10.9902 13.1904C10.9902 13.7404 10.5502 14.1904 9.99023 14.1904Z" />
                        <path d="M13.1895 14.1904C12.6295 14.1904 12.1895 13.7404 12.1895 13.1904C12.1895 12.6404 12.6395 12.1904 13.1895 12.1904C13.7395 12.1904 14.1895 12.6404 14.1895 13.1904C14.1895 13.7404 13.7395 14.1904 13.1895 14.1904Z" />
                        <path d="M6.80078 14.1904C6.24078 14.1904 5.80078 13.7404 5.80078 13.1904C5.80078 12.6404 6.25078 12.1904 6.80078 12.1904C7.35078 12.1904 7.80078 12.6404 7.80078 13.1904C7.80078 13.7404 7.35078 14.1904 6.80078 14.1904Z" />
                        <path d="M17.9396 16.29C17.7396 16.29 17.5396 16.21 17.3996 16.06C17.2396 15.9 17.1697 15.67 17.1997 15.45C17.2297 15.24 17.2396 15.02 17.2396 14.79V10.79C17.2396 8.00001 15.9897 6.75 13.1997 6.75H6.79963C6.56963 6.75 6.34966 6.76 6.13966 6.78C5.91966 6.81 5.68964 6.72999 5.52964 6.57999C5.36964 6.41999 5.27963 6.20001 5.29963 5.98001C5.47963 3.82001 6.58963 1.25 10.7996 1.25H17.1997C20.8197 1.25 22.7396 3.17001 22.7396 6.79001V10.79C22.7396 15 20.1697 16.1 18.0097 16.29C17.9797 16.29 17.9596 16.29 17.9396 16.29ZM6.91966 5.25H13.1896C16.8096 5.25 18.7297 7.17001 18.7297 10.79V14.66C20.4297 14.24 21.2297 12.99 21.2297 10.79V6.79001C21.2297 4.00001 19.9796 2.75 17.1896 2.75H10.7897C8.58965 2.75 7.34966 3.55 6.91966 5.25Z" />
                    </svg>
                </span>
                {/* <span className="text">Чат</span> */}
            </Link>
        </li>
    )
}

// Музыку поменяли на группы
function MenuElementGroupActive() {
    return (
        <li className="list actives">
            <Link to="/MainGroup">
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="svg-icon-fill" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.16055 11.62C9.13055 11.62 9.11055 11.62 9.08055 11.62C9.03055 11.61 8.96055 11.61 8.90055 11.62C6.00055 11.53 3.81055 9.25 3.81055 6.44C3.81055 3.58 6.14055 1.25 9.00055 1.25C11.8605 1.25 14.1905 3.58 14.1905 6.44C14.1805 9.25 11.9805 11.53 9.19055 11.62C9.18055 11.62 9.17055 11.62 9.16055 11.62ZM9.00055 2.75C6.97055 2.75 5.31055 4.41 5.31055 6.44C5.31055 8.44 6.87055 10.05 8.86055 10.12C8.92055 10.11 9.05055 10.11 9.18055 10.12C11.1405 10.03 12.6805 8.42 12.6905 6.44C12.6905 4.41 11.0305 2.75 9.00055 2.75Z" />
                        <path d="M16.5404 11.75C16.5104 11.75 16.4804 11.75 16.4504 11.74C16.0404 11.78 15.6204 11.49 15.5804 11.08C15.5404 10.67 15.7904 10.3 16.2004 10.25C16.3204 10.24 16.4504 10.24 16.5604 10.24C18.0204 10.16 19.1604 8.96 19.1604 7.49C19.1604 5.97 17.9304 4.74 16.4104 4.74C16.0004 4.75 15.6604 4.41 15.6604 4C15.6604 3.59 16.0004 3.25 16.4104 3.25C18.7504 3.25 20.6604 5.16 20.6604 7.5C20.6604 9.8 18.8604 11.66 16.5704 11.75C16.5604 11.75 16.5504 11.75 16.5404 11.75Z" />
                        <path d="M9.16961 22.55C7.20961 22.55 5.23961 22.05 3.74961 21.05C2.35961 20.13 1.59961 18.87 1.59961 17.5C1.59961 16.13 2.35961 14.86 3.74961 13.93C6.74961 11.94 11.6096 11.94 14.5896 13.93C15.9696 14.85 16.7396 16.11 16.7396 17.48C16.7396 18.85 15.9796 20.12 14.5896 21.05C13.0896 22.05 11.1296 22.55 9.16961 22.55ZM4.57961 15.19C3.61961 15.83 3.09961 16.65 3.09961 17.51C3.09961 18.36 3.62961 19.18 4.57961 19.81C7.06961 21.48 11.2696 21.48 13.7596 19.81C14.7196 19.17 15.2396 18.35 15.2396 17.49C15.2396 16.64 14.7096 15.82 13.7596 15.19C11.2696 13.53 7.06961 13.53 4.57961 15.19Z" />
                        <path d="M18.3402 20.75C17.9902 20.75 17.6802 20.51 17.6102 20.15C17.5302 19.74 17.7902 19.35 18.1902 19.26C18.8202 19.13 19.4002 18.88 19.8502 18.53C20.4202 18.1 20.7302 17.56 20.7302 16.99C20.7302 16.42 20.4202 15.88 19.8602 15.46C19.4202 15.12 18.8702 14.88 18.2202 14.73C17.8202 14.64 17.5602 14.24 17.6502 13.83C17.7402 13.43 18.1402 13.17 18.5502 13.26C19.4102 13.45 20.1602 13.79 20.7702 14.26C21.7002 14.96 22.2302 15.95 22.2302 16.99C22.2302 18.03 21.6902 19.02 20.7602 19.73C20.1402 20.21 19.3602 20.56 18.5002 20.73C18.4402 20.75 18.3902 20.75 18.3402 20.75Z" />
                    </svg>
                </span>
                {/* <span className="text">Чат</span> */}
            </Link>
        </li>
    )
}

function MenuElementGroupDisable() {
    return (
        <li className="list">
            <Link to="/MainGroup">
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="svg-icon-fill" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.16055 11.62C9.13055 11.62 9.11055 11.62 9.08055 11.62C9.03055 11.61 8.96055 11.61 8.90055 11.62C6.00055 11.53 3.81055 9.25 3.81055 6.44C3.81055 3.58 6.14055 1.25 9.00055 1.25C11.8605 1.25 14.1905 3.58 14.1905 6.44C14.1805 9.25 11.9805 11.53 9.19055 11.62C9.18055 11.62 9.17055 11.62 9.16055 11.62ZM9.00055 2.75C6.97055 2.75 5.31055 4.41 5.31055 6.44C5.31055 8.44 6.87055 10.05 8.86055 10.12C8.92055 10.11 9.05055 10.11 9.18055 10.12C11.1405 10.03 12.6805 8.42 12.6905 6.44C12.6905 4.41 11.0305 2.75 9.00055 2.75Z" />
                        <path d="M16.5404 11.75C16.5104 11.75 16.4804 11.75 16.4504 11.74C16.0404 11.78 15.6204 11.49 15.5804 11.08C15.5404 10.67 15.7904 10.3 16.2004 10.25C16.3204 10.24 16.4504 10.24 16.5604 10.24C18.0204 10.16 19.1604 8.96 19.1604 7.49C19.1604 5.97 17.9304 4.74 16.4104 4.74C16.0004 4.75 15.6604 4.41 15.6604 4C15.6604 3.59 16.0004 3.25 16.4104 3.25C18.7504 3.25 20.6604 5.16 20.6604 7.5C20.6604 9.8 18.8604 11.66 16.5704 11.75C16.5604 11.75 16.5504 11.75 16.5404 11.75Z" />
                        <path d="M9.16961 22.55C7.20961 22.55 5.23961 22.05 3.74961 21.05C2.35961 20.13 1.59961 18.87 1.59961 17.5C1.59961 16.13 2.35961 14.86 3.74961 13.93C6.74961 11.94 11.6096 11.94 14.5896 13.93C15.9696 14.85 16.7396 16.11 16.7396 17.48C16.7396 18.85 15.9796 20.12 14.5896 21.05C13.0896 22.05 11.1296 22.55 9.16961 22.55ZM4.57961 15.19C3.61961 15.83 3.09961 16.65 3.09961 17.51C3.09961 18.36 3.62961 19.18 4.57961 19.81C7.06961 21.48 11.2696 21.48 13.7596 19.81C14.7196 19.17 15.2396 18.35 15.2396 17.49C15.2396 16.64 14.7096 15.82 13.7596 15.19C11.2696 13.53 7.06961 13.53 4.57961 15.19Z" />
                        <path d="M18.3402 20.75C17.9902 20.75 17.6802 20.51 17.6102 20.15C17.5302 19.74 17.7902 19.35 18.1902 19.26C18.8202 19.13 19.4002 18.88 19.8502 18.53C20.4202 18.1 20.7302 17.56 20.7302 16.99C20.7302 16.42 20.4202 15.88 19.8602 15.46C19.4202 15.12 18.8702 14.88 18.2202 14.73C17.8202 14.64 17.5602 14.24 17.6502 13.83C17.7402 13.43 18.1402 13.17 18.5502 13.26C19.4102 13.45 20.1602 13.79 20.7702 14.26C21.7002 14.96 22.2302 15.95 22.2302 16.99C22.2302 18.03 21.6902 19.02 20.7602 19.73C20.1402 20.21 19.3602 20.56 18.5002 20.73C18.4402 20.75 18.3902 20.75 18.3402 20.75Z" />
                    </svg>
                </span>
                {/* <span className="text">Чат</span> */}
            </Link>
        </li>
    )
}

//  музыку закоментил, когда сделаем надо разкоментить и закоментить группы

// function MenuElementMusicActive(){
//     return (
//         <div className=""></div>
//     )
// }

// function MenuElementMusicDisable(){
//     return (
//         <li className="list">
//                 <Link to="/music">
//                     <span className="icon">
//                     <svg width="34" height="38" viewBox="0 0 34 38" fill="none" className="svg-icon" xmlns="http://www.w3.org/2000/svg">
//                         <g filter="url(#filter0_d_175_3563)">
//                         <path d="M12.7501 22.6665H8.85772C7.74613 22.6665 7.19034 22.6665 6.76509 22.8813C6.38376 23.0739 6.07412 23.3835 5.88153 23.7648C5.66675 24.1901 5.66675 24.7459 5.66675 25.8575V25.8575C5.66675 27.1853 5.66675 27.8492 5.93757 28.3152C6.17976 28.7319 6.56429 29.0472 7.02042 29.2029C7.53047 29.3771 8.18149 29.2469 9.48352 28.9865L9.90299 28.9026C11.0836 28.6665 11.6738 28.5484 12.0789 28.1883C12.2361 28.0486 12.3702 27.885 12.4764 27.7035C12.7501 27.2356 12.7501 26.6336 12.7501 25.4297V11.7791C12.7501 10.2093 12.7501 9.42442 13.2034 8.87147C13.6567 8.31851 14.4263 8.16458 15.9656 7.85673L22.1323 6.6234C24.3459 6.18067 25.4527 5.95931 26.1847 6.55943C26.9167 7.15954 26.9167 8.28827 26.9167 10.5457V12.7498M26.9167 19.8332V22.5964C26.9167 23.8003 26.9167 24.4023 26.643 24.8701C26.5369 25.0516 26.4027 25.2152 26.2456 25.3549C25.8405 25.7151 25.2502 25.8331 24.0697 26.0693L23.6502 26.1532C22.3482 26.4136 21.6971 26.5438 21.1871 26.3696C20.731 26.2138 20.3464 25.8986 20.1042 25.4819C19.8334 25.0159 19.8334 24.352 19.8334 23.0241V23.0241C19.8334 21.9126 19.8334 21.3568 20.0482 20.9315C20.2408 20.5502 20.5504 20.2405 20.9318 20.048C21.357 19.8332 21.9128 19.8332 23.0244 19.8332H26.9167ZM26.9167 19.8332V12.7498M26.9167 12.7498L12.7501 15.5832"/>
//                         </g>
//                         <defs>
//                         <filter id="filter0_d_175_3563" x="-4" y="0" width="42" height="42" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
//                         <feFlood floodOpacity="0" result="BackgroundImageFix"/>
//                         <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
//                         <feOffset dy="4"/>
//                         <feGaussianBlur stdDeviation="2"/>
//                         <feComposite in2="hardAlpha" operator="out"/>
//                         <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
//                         <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_175_3563"/>
//                         <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_175_3563" result="shape"/>
//                         </filter>
//                         </defs>
//                     </svg>

//                     </span>
//                     {/* <span className="text">Музыка</span> */}
//                 </Link>
//             </li>
//     )
// }

function MenuElementOtherActive() {
    return (
        <li className="list actives">
            <Link to="/other">
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="svg-icon-fill" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 10.75H5C2.58 10.75 1.25 9.42 1.25 7V5C1.25 2.58 2.58 1.25 5 1.25H7C9.42 1.25 10.75 2.58 10.75 5V7C10.75 9.42 9.42 10.75 7 10.75ZM5 2.75C3.42 2.75 2.75 3.42 2.75 5V7C2.75 8.58 3.42 9.25 5 9.25H7C8.58 9.25 9.25 8.58 9.25 7V5C9.25 3.42 8.58 2.75 7 2.75H5Z" />
                        <path d="M19 10.75H17C14.58 10.75 13.25 9.42 13.25 7V5C13.25 2.58 14.58 1.25 17 1.25H19C21.42 1.25 22.75 2.58 22.75 5V7C22.75 9.42 21.42 10.75 19 10.75ZM17 2.75C15.42 2.75 14.75 3.42 14.75 5V7C14.75 8.58 15.42 9.25 17 9.25H19C20.58 9.25 21.25 8.58 21.25 7V5C21.25 3.42 20.58 2.75 19 2.75H17Z" />
                        <path d="M19 22.75H17C14.58 22.75 13.25 21.42 13.25 19V17C13.25 14.58 14.58 13.25 17 13.25H19C21.42 13.25 22.75 14.58 22.75 17V19C22.75 21.42 21.42 22.75 19 22.75ZM17 14.75C15.42 14.75 14.75 15.42 14.75 17V19C14.75 20.58 15.42 21.25 17 21.25H19C20.58 21.25 21.25 20.58 21.25 19V17C21.25 15.42 20.58 14.75 19 14.75H17Z" />
                        <path d="M7 22.75H5C2.58 22.75 1.25 21.42 1.25 19V17C1.25 14.58 2.58 13.25 5 13.25H7C9.42 13.25 10.75 14.58 10.75 17V19C10.75 21.42 9.42 22.75 7 22.75ZM5 14.75C3.42 14.75 2.75 15.42 2.75 17V19C2.75 20.58 3.42 21.25 5 21.25H7C8.58 21.25 9.25 20.58 9.25 19V17C9.25 15.42 8.58 14.75 7 14.75H5Z" />
                    </svg>
                </span>
                {/* <span className="text">Прочее</span> */}
            </Link>
        </li>
    )
}

function MenuElementOtherDisable() {
    return (
        <li className="list">
            <Link to="/other">
                <span className="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="svg-icon-fill" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 10.75H5C2.58 10.75 1.25 9.42 1.25 7V5C1.25 2.58 2.58 1.25 5 1.25H7C9.42 1.25 10.75 2.58 10.75 5V7C10.75 9.42 9.42 10.75 7 10.75ZM5 2.75C3.42 2.75 2.75 3.42 2.75 5V7C2.75 8.58 3.42 9.25 5 9.25H7C8.58 9.25 9.25 8.58 9.25 7V5C9.25 3.42 8.58 2.75 7 2.75H5Z" />
                        <path d="M19 10.75H17C14.58 10.75 13.25 9.42 13.25 7V5C13.25 2.58 14.58 1.25 17 1.25H19C21.42 1.25 22.75 2.58 22.75 5V7C22.75 9.42 21.42 10.75 19 10.75ZM17 2.75C15.42 2.75 14.75 3.42 14.75 5V7C14.75 8.58 15.42 9.25 17 9.25H19C20.58 9.25 21.25 8.58 21.25 7V5C21.25 3.42 20.58 2.75 19 2.75H17Z" />
                        <path d="M19 22.75H17C14.58 22.75 13.25 21.42 13.25 19V17C13.25 14.58 14.58 13.25 17 13.25H19C21.42 13.25 22.75 14.58 22.75 17V19C22.75 21.42 21.42 22.75 19 22.75ZM17 14.75C15.42 14.75 14.75 15.42 14.75 17V19C14.75 20.58 15.42 21.25 17 21.25H19C20.58 21.25 21.25 20.58 21.25 19V17C21.25 15.42 20.58 14.75 19 14.75H17Z" />
                        <path d="M7 22.75H5C2.58 22.75 1.25 21.42 1.25 19V17C1.25 14.58 2.58 13.25 5 13.25H7C9.42 13.25 10.75 14.58 10.75 17V19C10.75 21.42 9.42 22.75 7 22.75ZM5 14.75C3.42 14.75 2.75 15.42 2.75 17V19C2.75 20.58 3.42 21.25 5 21.25H7C8.58 21.25 9.25 20.58 9.25 19V17C9.25 15.42 8.58 14.75 7 14.75H5Z" />
                    </svg>
                </span>
                {/* <span className="text">Прочее</span> */}
            </Link>
        </li>
    )
}

export default function MenuReactPrivate() {

    const url = useLocation()
    //console.log(url.pathname)

    const [urlGer, setUrlGer] = useState(url.pathname)


    useEffect(() => {
        setUrlGer(url.pathname)
    }, [url.pathname])


    return (

        <div className="navigation">
            <ul>
                {urlGer === '/news' ? <MenuElentListActive /> : <MenuElentListDisable />}
                {urlGer === '/home' ? <MenuElementHomeActive /> : <MenuElementHomeDisable />}
                {urlGer === '/sms' ? <MenuElementSmsActive /> : <MenuElementSmsDisable />}
                {/* {urlGer === '/music' ? <MenuElementMusicActive /> : <MenuElementMusicDisable /> } */}
                {urlGer === '/MainGroup' ? <MenuElementGroupActive /> : <MenuElementGroupDisable />}
                {urlGer === '/other' ? <MenuElementOtherActive /> : <MenuElementOtherDisable />}
                <div className="indicator"></div>
            </ul>
        </div>

    )

}