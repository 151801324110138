import React from 'react';
import "./Modal.css";

const Modal = ({active, setActive, children, anime1, anime2}) => {
    return (
        <div className={active ? "model active" : "model"} onClick={() => setActive(false)}>
            <div className={ anime2 ? anime2 : "model__content"} onClick={e=>e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}


export default Modal;