import React from 'react'
import { Link } from 'react-router-dom'
import LiFriend from '../../../components/Friends/liFrend'
import MenuReactPrivate from '../../../components/UI/menu/MenuReactPrivate'

export default function friends({ classwhite }) {
	const object = [
		{
			id: 1,
			foto: '../img/png/19.png',
			name: 'Россия 23',
		},
		{
			id: 2,
			foto: '../img/png/18.png',
			name: 'NR Music',
		},
	]

	return (
		<div className='animation-home'>
			<div className='m_block c-i-s-b'>
				<Link to='/MainGroup'>
					<img className="exit-component-icon" src='img/icon/right-red.svg' alt="exit:icon" />
				</Link>
				<div className='t-a-c c-gray f-s-16 m-0'>
					<p>У каких групп в ЧС:</p>
				</div>
				<Link to='/other'>
					<div className='other_ava'>
						<img src='../img/png/1.png' width='100%' alt='' />
					</div>
				</Link>
			</div>
			<div className='search mt-10 pd-t-10'>
				<div className='icon-friend-all'>
					<img src='img/icon/search.svg' alt='' />
				</div>
				<input
					className='search-friend-all'
					type='search'
					placeholder='Да как его зовут-то...'
				/>
			</div>
			<div className='m_block mt-10 pd-t-0'>
			</div>
			{object.map(user => (
				<LiFriend
					foto={user.foto}
					nameProfile={user.name}
					type='delete'
					key={user.id}
				/>
			))}
			<MenuReactPrivate />
		</div>
	)
}
