import React from "react";

export default function MenuPosts() {

    return (
        <div>
            <div className="sms-block c-i-s-b">
                <div className="d-f-a-c">
                    <div className="post_ava">
                        <img src="./img/png/3.png" alt='' />
                    </div>
                    <div className="ml-10">
                        <p className="f-s-16 name">Екатерина Нилова</p>
                        <span className="mt-10 f-s-13 txt_c_online">У меня раздвоение личности...</span>
                    </div>
                </div>
            </div>
            <div className="sms-block c-i-s-b">
                <div className="d-f-a-c">
                    <div className="post_ava">
                        <img src="../img/png/2.png" alt='' />
                    </div>
                    <div className="ml-10">
                        <p className="f-s-16 name">Дмитрий Бузаев</p>
                        <span className="mt-10 f-s-13 txt_c_online">Вы: Ау</span>
                    </div>
                </div>
                <div className="reading">
                    <p className="white f-s-12"></p>
                </div>
            </div>
        </div>
    )

}