import React from "react";
import { useState } from "react";

const FreeZoneComponentPassword = (props) => {
    const [values, setValues] = useState({ showPassword: false });

    // Хранит данные о иконки глазика показа пароля
    const eye = {
        deactive: {
            name: 'eye-off-outline',
            id: 'clouse-eye'
        },
        active: {
            name: 'eye-outline',
            id: 'open-eye'
        }
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    /* const handlePasswordChange = (event) => {
        setValues({ password: event.target.value });
    }; */


    return (

        <div className="">
            <input
                name='password'
                type={values.showPassword ? "text" : "password"}
                placeholder="Пароль"
                autoComplete="off"
                {...props}
            />
            <div className="eye_pass" >
                <ion-icon onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    name={values.showPassword ? eye.deactive.white : eye.active.name}
                ></ion-icon>
            </div>
        </div>

    )

};

export default FreeZoneComponentPassword;
