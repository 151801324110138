import React from "react";
import { Link } from "react-router-dom";
import NewsPost from "../../components/News/NewsPost";

export default function PostMenu() {

    return (
        <div>
            <div className="m_block mt-10">
                <Link to="/MainGroup">
                    <div className="c-gray f-s-16 d-f-a-c">
                        <p className="whiteblock-border-1">Группы - новости</p>
                    </div>
                </Link>
                <div className="over-auto mt-10">
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/logo/logo.png" />
                        </div>
                        <p className="white-s-12 t-a-c mt-10">Marlo</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/15.png" />
                        </div>
                        <p className="white-s-12 t-a-c mt-10">Photos</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/16.png" />
                        </div>
                        <p className="white-s-12 t-a-c mt-10">Food</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/17.png" />
                        </div>
                        <p className="white-s-12 t-a-c mt-10">Forest</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/18.png" />
                        </div>
                        <p className="white-s-12 t-a-c mt-10">B&W</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/19.png" />
                        </div>
                        <p className="white-s-12 t-a-c mt-10">Night</p>
                    </div>
                </div>
            </div>
            <div className="block_post_photo mt-10">
                <NewsPost />
            </div>
        </div>
    )

}