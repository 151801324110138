import React from 'react';
import "./newModal.css";

const NewModal = ({ active, setModal, children, anime2 }) => {
    return (
        <div className={active ? "background-model newmodel active" : "newmodel"} onClick={() => setModal(false)}>
            <div className={anime2 ? anime2 : "newmodel__content"} onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}


export default NewModal;