import React, { useState, useEffect } from "react";
import Modal from '../../components/Modal/Modal';
import LoginService from "../../API/LoginService";
import { Link } from "react-router-dom";
import MenuReactPrivate from "../../components/UI/menu/MenuReactPrivate";
import PrivateZoneExit from "../exit";

/* function ExitFromAccount() {
    localStorage.clear()
} */


export default function PrivateZoneSmsPage() {

    const [modalLogIn, setModalLogIn] = useState(false)
    async function checkToken() {
        try {
            await LoginService.checkToken(localStorage.getItem("tokenUser"), localStorage.getItem("userID"))
                .then()
                .catch(function (error) {
                    if (error.response.data.response === 401) {
                        setModalLogIn(true);
                        localStorage.clear();
                        window.location.reload()
                    }
                })
                .then(function () {

                })
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        checkToken()
        setInterval(() => checkToken(), 12000);
    }, []);


    return (
        <div className="animation-home">
            {/* Шапка */}
            <div className="m_block c-i-s-b b-b-12">
                <Link to="/home">
                    <div className="other_ava">
                        <img src="../img/png/1.png" width="100%" alt="" />
                    </div>
                </Link>
                <div className="c-gray f-s-16">
                    <p>Demian Derc</p>
                </div>
                <Link to="/MainSettings">
                    <div className="icon">
                        <img src="img/icon/setting-2.svg" alt='' className="setting-icon" />
                    </div>
                </Link>

            </div>
            {/*  */}

            {/* Блок - "Фото" */}
            <div className="m_block mt-15">
                <Link to="/photo">
                    <div className="c-i-s-b">
                        <div className="c-gray f-s-14 d-f-a-c block-border-3">
                            <p className="white f-w-500">Фото: 122</p>
                        </div>
                    </div>
                </Link>
                <div className="over-auto mt-10 ">
                    <div className="photo-other mr-12">
                        <img src="../img/png/MyPhoto-1.png" alt="" />
                    </div>
                    <div className="photo-other mr-12">
                        <img src="../img/png/MyPhoto-2.png" alt="" />
                    </div>
                    <div className="photo-other mr-12">
                        <img src="../img/png/MyPhoto-3.png" alt="" />
                    </div>
                    <div className="photo-other mr-12">
                        <img src="../img/png/MyPhoto-4.png" alt="" />
                    </div>
                    <div className="photo-other">
                        <img src="../img/png/MyPhoto-4.png" alt="" />
                    </div>
                </div>
            </div>
            {/*  */}

            {/* Блок - "Друзья" */}
            <Link to="../main_friends">
                <div className="m_block mt-15">
                    <Link to="../main_friends">
                        <div className="c-i-s-b">
                            <div className="c-gray f-s-14 d-f-a-c block-border-3">
                                <p className="white f-w-500">Друзья: 25</p>
                            </div>
                        </div>
                    </Link>
                    <div className="over-auto mt-10">
                        <div className="mt-10 w-50 mr-10">
                            <div className="ava-friends">
                                <img src="../img/png/10.png" alt="" />
                            </div>
                            <div className="online-friends"></div>
                            <p className="profile f-s-12 t-a-c mt-5">Иван</p>
                        </div>
                        <div className="mt-10 w-50 mr-10">
                            <div className="ava-friends">
                                <img src="../img/png/11.png" alt="" />
                            </div>
                            {/* <div className="online-friends"></div> */}
                            <p className="profile f-s-12 t-a-c mt-5">Андрей</p>
                        </div>
                        <div className="mt-10 w-50 mr-10">
                            <div className="ava-friends">
                                <img src="../img/png/12.png" alt="" />
                            </div>
                            {/* <div className="online-friends"></div> */}
                            <p className="profile f-s-12 t-a-c mt-5">Анастасия</p>
                        </div>
                        <div className="mt-10 w-50 mr-10">
                            <div className="ava-friends">
                                <img src="../img/png/13.png" alt="" />
                            </div>
                            <div className="online-friends"></div>
                            <p className="profile f-s-12 t-a-c mt-5">Ольга</p>
                        </div>
                        <div className="mt-10 w-50 ">
                            <div className="ava-friends">
                                <img src="../img/png/14.png" alt="" />
                            </div>
                            {/* <div className="online-friends"></div> */}
                            <p className="profile f-s-12 t-a-c mt-10">Илья</p>
                        </div>

                    </div>
                </div>
            </Link>
            {/*  */}

            {/* Блок - "группы" */}
            <div className="m_block mt-15">
                <Link to="/MainGroup">
                    <div className="c-i-s-b">
                        <div className="c-gray f-s-14 d-f-a-c block-border-3">
                            <p className="white f-w-500">Группы: 49</p>
                        </div>
                    </div>
                </Link>
                <div className="over-auto mt-10">
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/15.png" alt="" />
                        </div>
                        <p className="profile f-s-12 t-a-c mt-5">Photos</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/16.png" alt="" />
                        </div>
                        <p className="profile f-s-12 t-a-c mt-5">Food</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/17.png" alt="" />
                        </div>
                        <p className="profile f-s-12 t-a-c mt-5">Forest</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/18.png" alt="" />
                        </div>
                        <p className="profile f-s-12 t-a-c mt-5">B&W</p>
                    </div>
                    <div className="mt-10 w-50 ">
                        <div className="ava-friends">
                            <img src="../img/png/19.png" alt="" />
                        </div>
                        <p className="profile f-s-12 t-a-c mt-5">Night</p>
                    </div>

                </div>
            </div>
            {/*  */}
            <Modal active={modalLogIn} setActive={setModalLogIn} on>
                <div className="no-back_block">
                    <div className="mt-20 mb-10 btn-2-red t-a-c c-i" onClick={e => setModalLogIn(false)}>
                        <p className="ml-10 white-s-16">Сессия устарела</p>
                    </div>
                </div>
            </Modal>
            {/* меню */}
            <MenuReactPrivate />
        </div>
    )

}
