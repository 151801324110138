import React, { useState, useEffect } from "react";
import Modal from '../../components/Modal/Modal';
import LoginService from "../../API/LoginService";
import MenuReactPrivate from "../../components/UI/menu/MenuReactPrivate";

export default function PrivateZoneSettingPage() {


    const [modalLogIn, setModalLogIn] = useState(false)
    async function checkToken() {
        try {
            await LoginService.checkToken(localStorage.getItem("tokenUser"), localStorage.getItem("userID"))
                .then()
                .catch(function (error) {
                    if (error.response.data.response === 401) {
                        setModalLogIn(true);
                        localStorage.clear();
                        window.location.reload()
                    }
                })
                .then(function () {

                })
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        checkToken()
        setInterval(() => checkToken(), 12000);
    }, []);

    return (
        <div className="">
            <div className="animation-setting">
                <div className="m_block c-i-s-b">
                    <div className="c-gray f-s-16 c-i">
                        <p>Настройки</p>
                    </div>
                </div>
            </div>
            <Modal active={modalLogIn} setActive={setModalLogIn} on>
                <div className="no-back_block">
                    <div className="mt-20 mb-10 btn-2-red t-a-c c-i" onClick={e => setModalLogIn(false)}>
                        <p className="ml-10 white-s-16">Сессия устарела</p>
                    </div>
                </div>
            </Modal>

            {/* меню */}
            <MenuReactPrivate />
        </div>
    )

}
