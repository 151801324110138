import React from 'react'
import { Link, resolvePath, Navigate, useNavigate } from 'react-router-dom'
import MenuReactPrivate from '../../../components/UI/menu/MenuReactPrivate'

function ExitFromAccount() {
	localStorage.clear()
}

export default function Private_zone_online() {
	return (
		<div className='animation-home'>
			<div className='m_block c-i-s-b'>
				<Link to='/main_friends'>
					<img className="exit-component-icon" src='img/icon/right-red.svg' alt="exit:icon" />
				</Link>
				<div className='t-a-c c-gray f-s-16 m-0'>
					<p>Онлайн: 2</p>
				</div>
				<Link to='/other'>
					<div className='other_ava'>
						<img src='../img/png/1.png' width='100%' />
					</div>
				</Link>
			</div>
			<div className='search mt-10 pd-t-10'>
				<div className='icon-friend-all'>
					<img src='img/icon/search.svg' alt='' />
				</div>
				<input
					className='search-friend-all'
					type='search'
					placeholder='Да как его зовут-то...'
				/>
			</div>
			<div className='mt-10'>
				<div className='friend-list m_block'>
					<div className='friend-block c-i-s-b'>
						<div className='d-f-a-c'>
							<Link to='/home'>
								<div className='post_ava'>
									<img src='../img/png/people-friend/2.png' />
								</div>
							</Link>
							<div className='ml-10'>
								<p className='f-s-16 name'>Семён Боров</p>
							</div>
						</div>
						<div className='add-friend'>
							<svg
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M4 12C4 7.58172 7.58172 4 12 4V4C16.4183 4 20 7.58172 20 12V17.0909C20 17.9375 20 18.3608 19.8739 18.6989C19.6712 19.2425 19.2425 19.6712 18.6989 19.8739C18.3608 20 17.9375 20 17.0909 20H12C7.58172 20 4 16.4183 4 12V12Z'
									stroke='white'
								/>
								<path
									d='M9 11L15 11'
									stroke='white'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
								<path
									d='M12 15H15'
									stroke='white'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						</div>
					</div>
				</div>
				<div className='friend-list m_block'>
					<div className='friend-block c-i-s-b'>
						<div className='d-f-a-c'>
							<Link to='/home'>
								<div className='post_ava'>
									<img src='../img/png/people-friend/3.png' />
								</div>
							</Link>
							<div className='ml-10'>
								<p className='f-s-16 name'>Ольга Иванова</p>
							</div>
						</div>
						<div className='add-friend'>
							<svg
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M4 12C4 7.58172 7.58172 4 12 4V4C16.4183 4 20 7.58172 20 12V17.0909C20 17.9375 20 18.3608 19.8739 18.6989C19.6712 19.2425 19.2425 19.6712 18.6989 19.8739C18.3608 20 17.9375 20 17.0909 20H12C7.58172 20 4 16.4183 4 12V12Z'
									stroke='white'
								/>
								<path
									d='M9 11L15 11'
									stroke='white'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
								<path
									d='M12 15H15'
									stroke='white'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>
			{/* меню */}
			<MenuReactPrivate />
		</div>
	)
}
