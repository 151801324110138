import React from 'react'
import { Link } from 'react-router-dom'
import MenuReactPrivate from '../../../components/UI/menu/MenuReactPrivate'

export default function Private_zone_MainGroup() {
	return (
		<div className='animation-home'>
			<div className='m_block c-i-s-b '>
				<Link to='/other'>
					<img className="exit-component-icon" src='img/icon/right-red.svg' alt="exit:icon" />
				</Link>
				<div className='t-a-c c-gray f-s-16 m-0'>
					<p>Группы</p>
				</div>
				<div className='search-i-friend'>
					<img src='img/icon/search-2.svg' alt='' />
				</div>
			</div>

			{/* Блок - "Подписки" */}
			<div className='m_block mt-10'>
				<Link to='/Subscribe'>
					<div className='c-i-s-b'>
						<div className='c-gray f-s-16 d-f-a-c'>
							<p className='name'>Подписки: 23</p>
						</div>
						<img className="exit-component-icon" src='../img/icon/right_red.svg' alt='' />
					</div>
				</Link>
				<div className='over-auto mt-10'>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/2.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Иван</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/1.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Андрей</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/3.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Анастасия</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/4.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Ольга</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/5.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Илья</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/6.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Илья</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/7.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Илья</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/8.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Илья</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/9.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Илья</p>
					</div>
				</div>
			</div>
			{/*  */}

			{/* Блок - "Мои группы" */}
			<div className='m_block mt-10'>
				<Link to='/GroupList'>
					<div className='c-i-s-b'>
						<div className='c-gray f-s-16 d-f-a-c'>
							<p className='name'>Мои группы: 11</p>
						</div>
						<img className="exit-component-icon" src='../img/icon/right_red.svg' alt='' />
					</div>
				</Link>
				<div className='over-auto mt-10'>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/10.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Иван</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/11.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Андрей</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/11.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Анастасия</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/12.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Ольга</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/13.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Илья</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/7.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Илья</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/8.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Илья</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/group/9.png' alt='' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Илья</p>
					</div>
				</div>
			</div>
			{/*  */}

			{/* Блок - "Онлайн" */}
			<div className='m_block mt-10'>
				<Link to='/GroupBlackList'>
					<div className='c-i-s-b'>
						<div className='c-gray f-s-16 d-f-a-c'>
							<p className='name'>У каких групп в ЧС: 2</p>
						</div>
						<img className="exit-component-icon" src='../img/icon/right_red.svg' alt='' />
					</div>
				</Link>
				<div className='over-auto mt-10'>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/19.png' alt='img' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>Россия 23</p>
					</div>
					<div className='mt-10 w-50 mr-10'>
						<div className='ava-friends'>
							<img src='../img/png/18.png' alt='img' />
						</div>
						<p className='white-s-12 t-a-c mt-10'>NR Music</p>
					</div>
				</div>
			</div>
			{/*  */}

			{/* Блок - "Какие группы у меня в ЧС" */}
			<div className='m_block mt-10'>
				<Link to='/GroupWhoBlackList'>
					<div className='c-i-s-b'>
						<div className='white-s-16 d-f-a-c'>
							<p className='name'>Какие группы у меня в ЧС: 0</p>
						</div>
						<img className="exit-component-icon" src='../img/icon/right_red.svg' alt='' />
					</div>
				</Link>
				<div className=' mt-20 t-a-c'>
					<p className='name'>Таких пока нет</p>
				</div>
			</div>
			{/*  */}

			{/* меню */}
			<MenuReactPrivate />
		</div>
	)
}
