import React from 'react'
import "../Modal/Modal.css";

const ModalChat = ({ active, setActive, children }) => {
	return (
		<div className={active ? 'chat active' : 'chat'}>
			<div className='chats' onClick={e => e.stopPropagation()}>
				<header className=''>
					<div class='d-f-a-c pos-ab mt-30' onClick={() => setActive(false)}>
						<ion-icon
							name='chevron-back-outline'
							role='img'
							class='md hydrated'
							aria-label='chevron back outline'
						></ion-icon>
					</div>
					<div className='d-f-j-c'>
						<div className='mt-20'>
							<div className='d-j-c'>
								<div className='ava-friends'>
									<img src='../img/png/1.png' alt='' />
								</div>
								<div className='online-friends-chat'></div>
							</div>
							<div className='user-group'>
								{' '}
								{/* onClick={() => setModalActive(true)} */}
								<div className='d-f-a-c'>
									<p className='white-s-16 t-a-c mt-10'>Екатерина Нилова</p>{' '}
									<img src='img/icon/check.svg' className='ml-10 w-20px' />{' '}
									<img src='img/icon/options.svg' className='ml-10 mt-10' />
								</div>
								<p className='txt_c_online f-s-12'>
									Была в сети сегодня в 20:01
								</p>
							</div>
							{/* <ModalOptions active={modalActive} setActive={setModalActive}></ModalOptions> */}
						</div>
					</div>
				</header>
				<main className='pt-10'>
					<p className='f-s-12 txt_c_online t-a-c mt-10'>Сегодня</p>
					<div className='message'>
						<div className='im_block'>
							<div className='im'>
								<p>Да, я ему и говорю об этом! Только всё равно он хочет на автомобиле через Тулу</p>
								<span className='date-time-message'>10:20</span>
							</div>
						</div>
						<div className='you_block'>
							<div className='you f-r'>
								<p>
									Окей) Поехали тогда через кольцевую после обеда 😋
								</p>
								<span className='date-time-message'>10:23</span>
							</div>
						</div>
						<div className='im_block'>
							<div className='im'>
								<p>Договорились. Мне за тобой заехать?</p>
								<span className='date-time-message'>10:24</span>
							</div>
						</div>
						<div className='you_block'>
							<div className='you f-r'>
								<p>
									Да, было бы классно ❤️
								</p>
								<span className='date-time-message'>10:40</span>
							</div>
						</div>
						<div className='im_block'>
							<div className='im-photo'>
								<img src="../img/png/photo-2.png" alt="photo" />
								<span className='date-time-message'>10:45</span>
							</div>
						</div>
						<div className='im_block'>
							<div className='im'>
								<p>Да, я ему и говорю об этом! Только всё равно он хочет на автомобиле через Тулу</p>
								<span className='date-time-message'>10:20</span>
							</div>
						</div>
						<div className='you_block'>
							<div className='you f-r'>
								<p>
									Окей) Поехали тогда через кольцевую после обеда 😋
								</p>
								<span className='date-time-message'>10:23</span>
							</div>
						</div>
						<div className='im_block'>
							<div className='im'>
								<p>Договорились. Мне за тобой заехать?</p>
								<span className='date-time-message'>10:24</span>
							</div>
						</div>
						<div className='you_block'>
							<div className='you f-r'>
								<p>
									Да, было бы классно ❤️
								</p>
								<span className='date-time-message'>10:40</span>
							</div>
						</div>
						<div className='im_block'>
							<div className='im-photo'>
								<img src="../img/png/photo-2.png" alt="photo" />
								<span className='date-time-message'>10:45</span>
							</div>
						</div>
					</div>
					<footer className='footer-chat'>
						<div className='chat-typing'>
							<div className='w-10'>
								<img src='img/icon/add_chat.svg' alt='add' />
							</div>
							<div className=''>
								<input
									type='text'
									className='chat-message'
									placeholder='Что напишешь?'
								/>
							</div>
							<div className='w-10'>
								<img src='img/icon/micro.svg' alt='add' />
							</div>
						</div>
					</footer>
				</main>
			</div>
		</div>
	)
}

export default ModalChat