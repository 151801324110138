import React, { useState } from 'react'
import { Link, resolvePath, Navigate, useNavigate } from 'react-router-dom'

export default function PrivateZoneMainSettings() {
	return (
		<div className='animation'>
			<div className='m_block c-i-s-b'>
				<Link to='/MainSettings'>
					<div className="block_in_svg_2">
						<img className="exit-component-icon " src='img/icon/right-red.svg' alt="exit:icon" />
					</div>
				</Link>
				<div className='t-a-c c-gray f-s-16 m-0'>
					<p>Оформление</p>
				</div>
			</div>
			<div className='m_block mt-20'>
				<p className="white f-s-16 ">Смена темы:</p>
				<div className="c-i">
					<select name="day" className="inp-txt-2 f-s-16 c-gray mt-10">
						<option value="Темная">Темная</option>
						{/* <option value="Светлая">Светлая</option> */}
					</select>
				</div>
				
			</div>
			{/* <div className='m_block mt-20'>
				<p className="white f-s-16 ">Показывать телефон:</p>
				<div className="c-i">
					<select name="day" className="inp-txt f-s-16 c-gray mt-10">
						<option value="Темная">Показать</option>
						<option value="Светлая">Скрыть</option>
					</select>
				</div>
				<p className="white f-s-16 mt-20">Показывать почту:</p>
				<div className="c-i">
					<select name="day" className="inp-txt f-s-16 c-gray mt-10">
						<option value="Темная">Показать</option>
						<option value="Светлая">Скрыть</option>
					</select>
				</div>
			</div> */}
			<div className='btn__exit-group  mt-20'>
				<button className="btn-main-accen btn-2-red c-i f-s-14" >
					<img src="img/icon/saving.svg" alt="" />
					<p className='white ml-10'>Сохранить</p>
				</button>
			</div>
		</div>
	)
}