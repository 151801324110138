import React, { useState } from "react";
import "./Modal.css";

const ModalComment = ({ active, setActive }) => {
    const [likedActive, setLikedActive] = useState(false)
    const [likePost, setLikePost] = useState(0)
    /*
    const jsonComment = {
        0: {
            user: "User Name",
            date: "Сегодня в 00:00",
            text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo, aspernatur?",
            urlAva: "/img/png/1.png"
        },
        1: {
            user: "User Name",
            date: "Сегодня в 00:00",
            text: "Ok",
            urlAva: "/img/png/1.png"
        }
    }*/
    function likedPost() {
        likedActive ? setLikedActive(false) : setLikedActive(true)

        likedActive ? setLikePost(likePost - 1) : setLikePost(likePost + 1)

        //console.log(likedActive)
    }

    return (
        <div className={active ? "modelComment active " : "modelComment"} onClick={() => setActive(false)}>
            <div className="modelComment_block" onClick={e => e.stopPropagation()}>
                <div className='block '>
                    <header className='c-i mt-10'>
                        <img src="img/icon/close3.svg" onClick={() => setActive(false)} alt='' />

                    </header>
                    <div className="t-a-c mt-10">
                        <p className='txt_c_online'>4 Комментария</p>
                    </div>

                    {/* сам коммент */}
                    <div className=''>
                        <div className="mt-20 d-j-c block_2">
                            <div className="post_ava w-20">
                                <img src="../img/png/1.png" alt='AvaComment' />
                            </div>
                            <div className='ml-10 w-80'>
                                <div className='d-f-a-c'>
                                    <p className='f-s-14 name'>Николай Петров</p>
                                </div>
                                <div className='mt-10'>
                                    <p className='f-s-12 block txt_c_online'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo, aspernatur?</p>
                                </div>
                            </div>

                        </div>
                        <div className="mt-10 c-i-s-b">
                            <p className='f-s-11 txt_c_online'>15 минут назад</p>
                            <div className="d-j-c ">
                                <div className='mr-10 d-f-a-c border-like'>
                                    <img src="img/icon/answer.svg" />
                                    <p className='ml-5 white f-s-16'>2</p>
                                </div>
                                <div className={likedActive ? 'd-f-a-c likes-post-act  border-like ' : 'd-f-a-c likes-post border-like'} onClick={() => likedPost()}>
                                    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                                        <path d='M3.70893 11.5901L9.50278 17.0328C9.64793 17.1691 9.7205 17.2373 9.79942 17.2719C9.9273 17.3279 10.0728 17.3279 10.2007 17.2719C10.2796 17.2373 10.3522 17.1691 10.4973 17.0328L16.2911 11.5901C17.9213 10.0587 18.1192 7.53875 16.7482 5.77164L16.4904 5.43937C14.8503 3.3254 11.5581 3.67993 10.4056 6.09463C10.2428 6.43572 9.75728 6.43572 9.59448 6.09463C8.44202 3.67993 5.14981 3.3254 3.50966 5.43937L3.25186 5.77164C1.88083 7.53875 2.07879 10.0587 3.70893 11.5901Z' />
                                    </svg>
                                    <p className='white ml-5 f-s-16'>{likePost}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  */}

                    {/* далее удалить при создании системы комментов */}
                    {/* сам коммент с прикрепленной картинкой*/}
                    <div className=''>
                        <div className="mt-20 d-j-c block_2">
                            <div className="post_ava w-20">
                                <img src="../img/png/5.png" alt='AvaComment' />
                            </div>
                            <div className='ml-10 w-80'>
                                <div className='d-f-a-c'>
                                    <p className='f-s-14 white'>Александр Минаев </p>
                                </div>
                                <div className='mt-10'>
                                    <p className='f-s-12 block txt_c_online'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo, aspernatur?</p>
                                    <img src="img/png/2.png" className="ImageComment mt-20" alt="ImageComment" />
                                </div>
                            </div>

                        </div>
                        <div className="mt-10 c-i-s-b">
                            <p className='f-s-11 txt_c_online'>16 минут назад</p>
                            <div className="d-j-c">
                                <div className='mr-10 d-f-a-c border-like'>
                                    <img src="img/icon/answer.svg" />
                                    <p className='ml-5 white f-s-16'>1</p>
                                </div>
                                <div className={likedActive ? 'd-f-a-c likes-post-act  border-like ' : 'd-f-a-c likes-post border-like'} onClick={() => likedPost()}>
                                    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                                        <path d='M3.70893 11.5901L9.50278 17.0328C9.64793 17.1691 9.7205 17.2373 9.79942 17.2719C9.9273 17.3279 10.0728 17.3279 10.2007 17.2719C10.2796 17.2373 10.3522 17.1691 10.4973 17.0328L16.2911 11.5901C17.9213 10.0587 18.1192 7.53875 16.7482 5.77164L16.4904 5.43937C14.8503 3.3254 11.5581 3.67993 10.4056 6.09463C10.2428 6.43572 9.75728 6.43572 9.59448 6.09463C8.44202 3.67993 5.14981 3.3254 3.50966 5.43937L3.25186 5.77164C1.88083 7.53875 2.07879 10.0587 3.70893 11.5901Z' />
                                    </svg>
                                    <p className='white ml-5 f-s-16'>{likePost}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* коммент  когда нажимаешь на кнопку "ответить"*/}
                    <div className='ml-60'>
                        <div className="mt-20 d-j-c block_2">
                            <div className="post_ava w-20">
                                <img src="../img/png/1.png" alt='AvaComment' />
                            </div>
                            <div className='ml-10 w-80'>
                                <div className='d-f-a-c'>
                                    <p className='f-s-14 name'>Николай Петров</p>
                                </div>
                                <div className='mt-10'>
                                    <p className='f-s-12 block txt_c_online'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo, aspernatur?</p>
                                </div>
                            </div>

                        </div>
                        <div className="mt-10 c-i-s-b">
                            <p className='f-s-11 txt_c_online'>5 минут назад</p>
                            <div className="d-j-c ">
                                <div className='mr-10 d-f-a-c border-like'>
                                    <img src="img/icon/answer.svg" />
                                    <p className='ml-5 white-s-12'>Ответить</p>
                                </div>
                                <div className={likedActive ? 'd-f-a-c likes-post-act  border-like ' : 'd-f-a-c likes-post border-like'} onClick={() => likedPost()}>
                                    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                                        <path d='M3.70893 11.5901L9.50278 17.0328C9.64793 17.1691 9.7205 17.2373 9.79942 17.2719C9.9273 17.3279 10.0728 17.3279 10.2007 17.2719C10.2796 17.2373 10.3522 17.1691 10.4973 17.0328L16.2911 11.5901C17.9213 10.0587 18.1192 7.53875 16.7482 5.77164L16.4904 5.43937C14.8503 3.3254 11.5581 3.67993 10.4056 6.09463C10.2428 6.43572 9.75728 6.43572 9.59448 6.09463C8.44202 3.67993 5.14981 3.3254 3.50966 5.43937L3.25186 5.77164C1.88083 7.53875 2.07879 10.0587 3.70893 11.5901Z' />
                                    </svg>
                                    <p className='white ml-5 f-s-16'>{likePost}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  */}
                    {/* сам коммент */}
                    <div className=''>
                        <div className="mt-20 d-j-c block_2">
                            <div className="post_ava w-20">
                                <img src="../img/png/3.png" alt='AvaComment' />
                            </div>
                            <div className='ml-10 w-80'>
                                <div className='d-f-a-c'>
                                    <p className='f-s-14 name'>Владислав Крошин</p>
                                </div>
                                <div className='mt-10'>
                                    <p className='f-s-12 block txt_c_online'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo, aspernatur?</p>
                                </div>
                            </div>

                        </div>
                        <div className="mt-10 c-i-s-b">
                            <p className='f-s-11 txt_c_online'>15 минут назад</p>
                            <div className="d-j-c ">
                                <div className='mr-10 d-f-a-c border-like'>
                                    <img src="img/icon/answer.svg" />
                                    <p className='ml-5 white-s-12'>Ответить</p>
                                </div>
                                <div className={likedActive ? 'd-f-a-c likes-post-act  border-like ' : 'd-f-a-c likes-post border-like'} onClick={() => likedPost()}>
                                    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                                        <path d='M3.70893 11.5901L9.50278 17.0328C9.64793 17.1691 9.7205 17.2373 9.79942 17.2719C9.9273 17.3279 10.0728 17.3279 10.2007 17.2719C10.2796 17.2373 10.3522 17.1691 10.4973 17.0328L16.2911 11.5901C17.9213 10.0587 18.1192 7.53875 16.7482 5.77164L16.4904 5.43937C14.8503 3.3254 11.5581 3.67993 10.4056 6.09463C10.2428 6.43572 9.75728 6.43572 9.59448 6.09463C8.44202 3.67993 5.14981 3.3254 3.50966 5.43937L3.25186 5.77164C1.88083 7.53875 2.07879 10.0587 3.70893 11.5901Z' />
                                    </svg>
                                    <p className='white ml-5 f-s-16'>{likePost}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  */}

                    {/* Текст набора комментария */}
                    <div className='block_2 mt-20 mb-40 d-f-a-c'>
                        <div className='d-f-j-c mr-10'>
                            <img src="../img/icon/add.svg" alt='' />
                        </div>
                        <input placeholder='Ну и что ты написать хочешь?' className='inp-comment' />
                        <div className='d-f-j-c ml-10 mr-10'>
                            <img src="../img/icon/send.svg" alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ModalComment;