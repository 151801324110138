import React from 'react';

export default function Button_login({ children, ...props }) {

    return (
        <button {...props} className="btn mt-30">
            <p className="w-p f-s-16">{children}</p>
        </button>
    )

}