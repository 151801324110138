import React from 'react'
import { Link } from 'react-router-dom'
import MenuReactPrivate from '../../../components/UI/menu/MenuReactPrivate'



export default function PrivateZoneMainFriends() {
	return (
		<div className='animation-home'>
			<div className='m_block c-i-s-b'>
				<Link to='/main_friends'>
					<img className="exit-component-icon" src='img/icon/right-red.svg' alt="exit:icon" />
				</Link>
				<div className='t-a-c c-gray f-s-16 m-0'>
					<p>У кого я в чс: 2</p>
				</div>
				<Link to='/other'>
					<div className='other_ava'>
						<img src='../img/png/1.png' width='100%' alt='' />
					</div>
				</Link>
			</div>
			<div className='search mt-10 pd-t-10'>
				<div className='icon-friend-all'>
					<img src='img/icon/search.svg' alt='' />
				</div>
				<input
					className='search-friend-all'
					type='search'
					placeholder='Да как его зовут-то....'
				/>
			</div>
			<div className='mt-10'>
				<div className='friend-list m_block'>
					<div className='friend-block c-i-s-b'>
						<div className='d-f-a-c'>
							<Link to='/home'>
								<div className='post_ava'>
									<img src='../img/png/people-friend/2.png' alt='' />
								</div>
							</Link>
							<div className='ml-10'>
								<p className='f-s-16 name'>Семён Боров</p>
							</div>
						</div>
					</div>
				</div>
				<div className='friend-list m_block'>
					<div className='friend-block c-i-s-b'>
						<div className='d-f-a-c'>
							<Link to='/home'>
								<div className='post_ava'>
									<img src='../img/png/people-friend/3.png' alt='' />
								</div>
							</Link>
							<div className='ml-10'>
								<p className='f-s-16 name'>Ольга Иванова</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* меню */}
			<MenuReactPrivate />
		</div>
	)
}
