import React from "react";

export default function MenuPosts() {


    return (
        <div>
            <div className="sms-block-group">
                <div className="sms-block c-i-s-b">
                    <div className="d-f-a-c">
                        <div className="post_ava">
                            <img src="../img/png/17.png" alt='' />
                        </div>
                        <div className="ml-10">
                            <p className="f-s-16 name">Design</p>
                            <span className="mt-10 f-s-13 txt_c_online">Артём: Че?</span>
                        </div>
                    </div>
                    <div className="reading">
                        <p className="white f-s-12">194</p>
                    </div>
                </div>
            </div>
        </div>
    )

}