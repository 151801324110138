import React, { useState, useEffect } from 'react'
import Input from 'react-phone-number-input/input'
import { Link, resolvePath, Navigate, useNavigate } from 'react-router-dom';
import FreeZoneComponetInputPassword from '../../../components/Free_zone/UI/input/input_password';
import InputFreeZone from '../../../components/UI/input/InputFreeZone';
import useInput from '../../../hooks/useInput';

export default function PrivateZoneSecuritySettings() {
	const [password, setPassword] = useState({ password1: '', password2: '' });
	const [statusChech, setStatusChech] = useState(false);
	const [password2status, setPassword2Status] = useState(false);

	useEffect(() => {
		if (password.password2 != "") {
			if (statusChech == true) {
				password.password1 == password.password2 ? setPassword2Status(false) : setPassword2Status(true)
			}
			statusChech == false && setStatusChech(true)
		}
	}, [password.password2, password.password1]);

	return (
    <div className="">
        <div className='m_block c-i-s-b'>
            <Link to='/MainSettings'>
				<div className="block_in_svg_2">
					<img className="exit-component-icon " src='img/icon/right-red.svg' alt="exit:icon" />
				</div>
			</Link>
			<div className='t-a-c c-gray f-s-16 m-0'>
				<p>Безопасность</p>
			</div>
		</div>
		<div className="block-2">
            <div className="m_block mt-20">
                <form onChange={e => (e.stopPropagation())}>
                    <p className="white f-s-14 mt-10">Cтарый пароль:</p>
                    <FreeZoneComponetInputPassword
                        placeholder="Старый пароль:"
                        value={password.password1}
                        onChange={e => setPassword({ ...password, password1: e.target.value })}
                        redPassword={false}
                        className="inp-txt-2 f-s-16 mt-10 c-gray"
                    />
                     <p className="white f-s-14 mt-10">Новый пароль:</p>
                    <FreeZoneComponetInputPassword placeholder="Новый пароль:"
                        value={password.password2}
                        onChange={e => setPassword({ ...password, password2: e.target.value })}
                        redPassword={password2status}
                        className="inp-txt-2 f-s-16 mt-10 c-gray"
                    />
                     <p className="white f-s-14 mt-10">Повтор нового пароля:</p>
                    <FreeZoneComponetInputPassword placeholder="Введите ещё раз"
                        value={password.password2}
                        onChange={e => setPassword({ ...password, password2: e.target.value })}
                        redPassword={password2status}
                        className="inp-txt-2 f-s-16 mt-10 c-gray"
                    />
                </form>
            </div>
        </div>
        <div className='btn__exit-group  mt-20'>
			<button className="btn-main-accen btn-2-red c-i f-s-14" >
                <img src="img/icon/saving.svg" alt="" />
                <p className='white ml-10'>Сохранить</p>
            </button>
		</div>
    </div>
    )
}