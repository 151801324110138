import React, { useState } from 'react'
import { Link, resolvePath, Navigate, useNavigate } from 'react-router-dom'
import MenuReactPrivate from '../../../components/UI/menu/MenuReactPrivate'
import MenuPost from '../../../components/group/menuPosts';
import MenuMusic from '../../../components/group/menuMusic';
import MenuBookmarks from '../../../components/group/menuBookmarks';
import MenuPhoto from '../../../components/group/menuPhoto';
import MenuVideo from '../../../components/group/menuVideo';

export default function PrivateZoneMainFriends() {

	const [menuEditor, setMenuEditor] = useState('Новости');
	const menuElements = [
		['Новости', './img/icon/news-posts.svg'],
		['Фото', './img/icon/news-photo.svg'],
		['Музыка', './img/icon/news-music.svg'],
		['Видео', './img/icon/news-video.svg'],
		['Закладки', './img/icon/news-save.svg'],
	]

	return (
		<div className='animation-home'>
			{/* Основная информация о пользователе */}
			<div className='cover-group'>
				<img src='../img/png/group/cover-1.png' width='100%' alt='' />
			</div>
			<Link to="/other">
				<div class='d-f-a-c pos-ab mt-30 exit-group'>
					<ion-icon
						name='chevron-back-outline'
						role='img'
						class='md hydrated red'
						aria-label='chevron back outline'
					></ion-icon>
				</div>
			</Link>
			<div className='m_block'>
				<div className='group_ava'>
					<img src='../img/png/group/group-ava-1.png' width='100%' alt='' />
				</div>
				<div className='txt'>
					<p className='whited-f-a-c f-s-18'>
						<span>Россия 23</span>
						<svg className='ml-10' width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 9L4.23309 11.4248C4.66178 11.7463 5.26772 11.6728 5.60705 11.2581L14 1" stroke="#D20C34" stroke-linecap="round" />
						</svg>
					</p>
				</div>
				<div className='group-wrapper'>
					<div className="group-info c-gray">
						<p>Россия 23 - Лучший канал.</p>
					</div>
					<div className="group-add">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M1.92157 1.92157C0.75 3.09315 0.75 4.97876 0.75 8.75V15.25C0.75 19.0212 0.75 20.9069 1.92157 22.0784C3.09315 23.25 4.97876 23.25 8.75 23.25H15.25C19.0212 23.25 20.9069 23.25 22.0784 22.0784C23.25 20.9069 23.25 19.0212 23.25 15.25V8.75C23.25 4.97876 23.25 3.09315 22.0784 1.92157C20.9069 0.75 19.0212 0.75 15.25 0.75H8.75C4.97876 0.75 3.09315 0.75 1.92157 1.92157ZM11 5.75V11H5.75V13H11L11 18.25H13L13 13H18.25V11H13V5.75H11Z" fill="#D20C34" />
						</svg>
					</div>
				</div>
				<div className="group-additional-info mt-10">
					<div className="group-number-subscribers c-gray">230 000 Подписчиков</div>
					<div className="group-link-site mt-5 ml-3"><a href="https://russia23.com">russia23.com</a></div>
				</div>
			</div>
			<div className='mt-10 no-back_block c-i-s-b'>
				{menuElements.map(menuElement => (
					<img key={menuElement[0]}
						src={menuElement[1]}
						className={menuElement[0] == menuEditor ? "filterd icon-group" : "icon-group"}
						onClick={() => setMenuEditor(menuElement[0])}
						alt={menuElement[0]}
					/>
				))}
			</div>
			{menuEditor == "Фото" && <MenuPhoto />}
			{menuEditor == "Музыка" && <MenuMusic />}
			{menuEditor == "Видео" && <MenuVideo />}
			{menuEditor == "Закладки" && <MenuBookmarks />}
			{menuEditor == "Новости" && <MenuPost /> /*^-^* <- Котик */}
		</div >
	)
}