import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from './App';
import { Provider } from 'react-redux';
import { store } from '../src/store';


Sentry.init({
  dsn: "https://1576a0d2eb1c4c5a8ea501d4258864c0@o1068239.ingest.sentry.io/4504111707717632",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
