import React from 'react';
import LoginService from '../API/LoginService.js';
import { useNavigate } from 'react-router-dom';

export default function PrivateZoneExit () {
	const navigate = useNavigate();

	const exit = async () => {
		const token = localStorage.getItem('tokenUser');
		
		try {
			const response = await LoginService.deleteToken(token);

			if (response.status === 200) {
				localStorage.clear();
			}
			navigate('/');
			window.location.reload();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<button 
			style={{ background: 'none', border: 'none' }}
			className="c-gray f-s-16"
			onClick={exit}
		>
			<p className="name">Выйти</p>
		</button >
	)
}
