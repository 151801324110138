import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    firstName: '',
    lastName: '',
    DayBirsday: 0,
    mountBirsdat: 0,
    yearsBirsday: new Date().getFullYear(),
    sex: 0,
}

export const registerOneSlice = createSlice({
    name: "registerOne",
    initialState,
    reducers: {
        setFirstName: (state, action) => {
            state.firstwhite = action.payload
        },
        setLastName: (state, action) => {
            state.lastwhite = action.payload
        },
        setDayBirsday: (state, action) => {
            state.DayBirsday = action.payload
        },
        setMountBirsday: (state, action) => {
            state.mountBirsdat = action.payload
        },
        setYearsBirsday: (state, action) => {
            state.yearsBirsday = action.payload
        },
        setSex: (state, action) => {
            state.sex = action.payload
        },
    }
})

export const { setFirstName, setLastName, setDayBirsday,
    setMountBirsday, setYearsBirsday, setSex } = registerOneSlice.actions
export default registerOneSlice.reducer
