import React, { useState } from 'react'
import { Link } from "react-router-dom";
import ModalPhotos from '../../components/Modal/ModalPhoto'

import MenuReactPrivate from '../../components/UI/menu/MenuReactPrivate'

export default function PrivateZonePhoto() {
    const photo = [
        {
            url: 'img/png/photo-1.png',
        },
        {
            url: 'img/png/photo-2.png',
        },
        {
            url: 'img/png/photo-3.png',
        },
        {
            url: 'img/png/photo-4.png',
        },
        {
            url: 'img/png/photo-5.png',
        },
        {
            url: 'img/png/photo-6.png',
        },
    ]

    const [openPhoto, setOpenPhoto] = useState(false)
    const [urlPhoto, setUrlPhoto] = useState('')

    const openPhotoFunc = e => {
        setUrlPhoto(e)
        setOpenPhoto(true)
        //console.log(e)
    }

    return (
        <div className='photo-component'>
            <div className="m_block mt-10">
                <Link to="/group">
                    <div className="c-gray f-s-16 d-f-a-c">
                        <p className="whiteblock-border-1">Группы - фото</p>
                    </div>
                </Link>
                <div className="over-auto mt-10">
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/logo/logo.png" />
                        </div>
                        <p className="white-s-12 t-a-c mt-10">Marlo</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/15.png" />
                        </div>
                        <p className="white-s-12 t-a-c mt-10">Photos</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/16.png" />
                        </div>
                        <p className="white-s-12 t-a-c mt-10">Food</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/17.png" />
                        </div>
                        <p className="white-s-12 t-a-c mt-10">Forest</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/18.png" />
                        </div>
                        <p className="white-s-12 t-a-c mt-10">B&W</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/19.png" />
                        </div>
                        <p className="white-s-12 t-a-c mt-10">Night</p>
                    </div>
                </div>
            </div>
            <section className='portfolio-section'>
                {photo.map(photo => (
                    <div
                        className='portfolio-item'
                        onClick={e => openPhotoFunc(photo.url)}
                        style={{ backgroundImage: 'url(' + photo.url + ')' }}
                    ></div>
                ))}
            </section>
            <section className='portfolio-section'>
                {photo.map(photo => (
                    <div
                        className='portfolio-item'
                        onClick={e => openPhotoFunc(photo.url)}
                        style={{ backgroundImage: 'url(' + photo.url + ')' }}
                    ></div>
                ))}
            </section>
            <ModalPhotos
                active={openPhoto}
                setActive={e => setOpenPhoto(false)}
                photoURL={urlPhoto}
            />
            <MenuReactPrivate />
        </div>
    )
}
