import React, { useState } from "react";
import "./Modal.css";

const ModalAddPost = ({ active, setActive }) => {
    const [likedActive, setLikedActive] = useState(false)
	const [likePost, setLikePost] = useState(0)
    /*
    const jsonComment = {
        0: {
            user: "User Name",
            date: "Сегодня в 00:00",
            text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo, aspernatur?",
            urlAva: "/img/png/1.png"
        },
        1: {
            user: "User Name",
            date: "Сегодня в 00:00",
            text: "Ok",
            urlAva: "/img/png/1.png"
        }
    }*/
    function likedPost() {
		likedActive ? setLikedActive(false) : setLikedActive(true)

		likedActive ? setLikePost(likePost - 1) : setLikePost(likePost + 1)

		//console.log(likedActive)
	}

    return (
        <div className={active ? "modelComment active " : "modelComment"} onClick={() => setActive(false)}>
            <div className="modelComment_block" onClick={e => e.stopPropagation()}>
                <div className='block '>
                    <header className='c-i mt-10'>
                        <img src="img/icon/close3.svg" onClick={() => setActive(false)} alt=''/>
                    </header>
                  {/* сюда вписываешь весь контент */}
                   {/* удалить  */}
                    <textarea placeholder="Что у вас нового?" className="newpost placeholder mt-15 "></textarea>
                    <button className= "mt-20 btn__post" type="submit">
                            <span className="w-p f-s-14">Опубликовать</span>
                    </button>
                    <div className="mt-15 d-f-w">
						<a href="" className="block-border-5 d-f-a-c f-s-14 profile">
							<span className="">Отложить запись</span>
                            <img src="img/icon/chevron-down.svg" className="ml-10" alt='' />
						</a>
						<a href="" className="block-border-5 d-f-a-c f-s-14 profile">
							<span className="ml-5">Тема зписи</span>
                            <img src="img/icon/chevron-down.svg" className="ml-10" alt='' />
						</a>
                    </div>
                    <hr className="hr-3 mt-15" />
                    <div className="mt-15 d-j-s mb-15">
						<a href="" className="block-border-4 d-f-a-c f-s-14 profile ">
                            <img src="img/icon/gallery.svg" className="mr-10" alt='' />
							<span className="">Фото</span>
						</a>
						<a href="" className="block-border-4 d-f-a-c f-s-14 profile ">
                            <img src="img/icon/document-text.svg" className="mr-10" alt='' />
							<span className="ml-5">Файл</span> 
						</a>
                        <a href="" className="block-border-4 d-f-a-c f-s-14 profile">
                            <img src="img/icon/task-square.svg" className="mr-10" alt='' />
							<span className="ml-5">Опрос</span> 
						</a>
                    </div>
                   {/*  */}
                   
                </div>
            </div>
        </div>
    )
}


export default ModalAddPost;