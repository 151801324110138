import React from "react";

export default function menuVideo() {
    return (
        <div className="no-back_block c-gray">
            <div className="video__group">
                {/* video-item */}
                <div className="video__item">
                    <div className="video__item-img">
                        <img className="video-preview" src="../img/png/video-preview/video-img.png" alt="preview-video" />
                        <div className="video-play">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.3446 9.42234L7.01246 1.25627C4.02041 -0.239753 0.5 1.93598 0.5 5.2812V20.7189C0.5 24.0641 4.02041 26.2398 7.01246 24.7438L23.3446 16.5778C26.2928 15.1037 26.2928 10.8964 23.3446 9.42234Z" fill="white" />
                            </svg>
                        </div>
                    </div>
                    <div className="video__wrapper">
                        <div className="video__description-group">
                            <div className="video__description-channel">
                                <div className="video__description-title">
                                    <img src="./img/png/group/group-ava-1.png" alt="" />
                                    <p className="f-s-16 whitegroup-name">
                                        <span>Россия 23</span>
                                        <svg className='ml-10' width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 9L4.23309 11.4248C4.66178 11.7463 5.26772 11.6728 5.60705 11.2581L14 1" stroke="#D20C34" stroke-linecap="round" />
                                        </svg>
                                    </p>
                                </div>
                            </div>
                            <div className="video__description-name">Чем опасен океан?</div>
                        </div>
                        <div className="video__detail-group">
                            <div className="video__watch">104 389 просмотров</div>
                            <div className="video__date">5 июня в 22:03</div>
                        </div>
                    </div>
                </div>
                {/* video-item */}
                <div className="video__item">
                    <div className="video__item-img">
                        <img className="video-preview" src="../img/png/video-preview/video-img.png" alt="preview-video" />
                        <div className="video-play">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.3446 9.42234L7.01246 1.25627C4.02041 -0.239753 0.5 1.93598 0.5 5.2812V20.7189C0.5 24.0641 4.02041 26.2398 7.01246 24.7438L23.3446 16.5778C26.2928 15.1037 26.2928 10.8964 23.3446 9.42234Z" fill="white" />
                            </svg>
                        </div>
                    </div>
                    <div className="video__wrapper">
                        <div className="video__description-group">
                            <div className="video__description-channel">
                                <div className="video__description-title">
                                    <img src="./img/png/group/group-ava-1.png" alt="" />
                                    <p className="f-s-16 whitegroup-name">
                                        <span>Россия 23</span>
                                        <svg className='ml-10' width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 9L4.23309 11.4248C4.66178 11.7463 5.26772 11.6728 5.60705 11.2581L14 1" stroke="#D20C34" stroke-linecap="round" />
                                        </svg>
                                    </p>
                                </div>
                            </div>
                            <div className="video__description-name">Чем опасен океан?</div>
                        </div>
                        <div className="video__detail-group">
                            <div className="video__watch">104 389 просмотров</div>
                            <div className="video__date">5 июня в 22:03</div>
                        </div>
                    </div>
                </div>
                {/* video-item */}
                <div className="video__item">
                    <div className="video__item-img">
                        <img className="video-preview" src="../img/png/video-preview/video-img.png" alt="preview-video" />
                        <div className="video-play">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.3446 9.42234L7.01246 1.25627C4.02041 -0.239753 0.5 1.93598 0.5 5.2812V20.7189C0.5 24.0641 4.02041 26.2398 7.01246 24.7438L23.3446 16.5778C26.2928 15.1037 26.2928 10.8964 23.3446 9.42234Z" fill="white" />
                            </svg>
                        </div>
                    </div>
                    <div className="video__wrapper">
                        <div className="video__description-group">
                            <div className="video__description-channel">
                                <div className="video__description-title">
                                    <img src="./img/png/group/group-ava-1.png" alt="" />
                                    <p className="f-s-16 whitegroup-name">
                                        <span>Россия 23</span>
                                        <svg className='ml-10' width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 9L4.23309 11.4248C4.66178 11.7463 5.26772 11.6728 5.60705 11.2581L14 1" stroke="#D20C34" stroke-linecap="round" />
                                        </svg>
                                    </p>
                                </div>
                            </div>
                            <div className="video__description-name">Чем опасен океан?</div>
                        </div>
                        <div className="video__detail-group">
                            <div className="video__watch">104 389 просмотров</div>
                            <div className="video__date">5 июня в 22:03</div>
                        </div>
                    </div>
                </div>
                {/* video-item */}
                <div className="video__item">
                    <div className="video__item-img">
                        <img className="video-preview" src="../img/png/video-preview/video-img.png" alt="preview-video" />
                        <div className="video-play">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.3446 9.42234L7.01246 1.25627C4.02041 -0.239753 0.5 1.93598 0.5 5.2812V20.7189C0.5 24.0641 4.02041 26.2398 7.01246 24.7438L23.3446 16.5778C26.2928 15.1037 26.2928 10.8964 23.3446 9.42234Z" fill="white" />
                            </svg>
                        </div>
                    </div>
                    <div className="video__wrapper">
                        <div className="video__description-group">
                            <div className="video__description-channel">
                                <div className="video__description-title">
                                    <img src="./img/png/group/group-ava-1.png" alt="" />
                                    <p className="f-s-16 whitegroup-name">
                                        <span>Россия 23</span>
                                        <svg className='ml-10' width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 9L4.23309 11.4248C4.66178 11.7463 5.26772 11.6728 5.60705 11.2581L14 1" stroke="#D20C34" stroke-linecap="round" />
                                        </svg>
                                    </p>
                                </div>
                            </div>
                            <div className="video__description-name">Чем опасен океан?</div>
                        </div>
                        <div className="video__detail-group">
                            <div className="video__watch">104 389 просмотров</div>
                            <div className="video__date">5 июня в 22:03</div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}