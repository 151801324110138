import React from "react";

export default function menuMusic() {
    return (
        <div>
            <div className="no-back_block c-gray">
                <div className="music__info-wrapper">
                    <div className="music__info-cover">
                        <img src="../img/png/music/music-main-cover.png" alt="cover" />
                    </div>
                    <div className="music__info">
                        <p className='music__info-author whited-f-a-c f-s-16 red'>
                            <span>Россия 23</span>
                        </p>
                        <div className="music__info-track">
                            <p className="music__track-number">40 песен</p>
                            <p className="music__track-minute">85 минут</p>
                        </div>
                        <div className="music__track-year">2022 год</div>
                        <div className="music__name-lider">
                            <span>#топ-3</span>
                            <div className="music__name-items">
                                <div className="music__name-track">Solo</div>
                                <div className="music__name-track">Кино</div>
                                <div className="music__name-track">Образы</div>
                            </div>
                        </div>
                        <btn className="music__name-btn">Слушать</btn>
                    </div>
                </div>
            </div>
            <div className="list__item-group">
                <div className="list__item">
                    <div className="c-i-s-b">
                        <div className="music__track">
                            <div className="music__track-cover">
                                <img src="../img/png/music/music-cover-1.png" alt="cover" />
                            </div>
                            <div className="music__track-group">
                                <div className="music__track-title">Помню</div>
                                <div className="music__track-subtitle red">Mealon</div>
                            </div>
                        </div>
                        <div className="music__track-info">
                            <span className="music__time-track">2:12</span>
                            <a href="#">
                                <img className="music__track-add mt-5" src="../img/icon/plus.svg" alt="add-track" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="list__item">
                    <div className="c-i-s-b">
                        <div className="music__track">
                            <div className="music__track-cover">
                                <img src="../img/png/music/music-cover-2.png" alt="cover" />
                            </div>
                            <div className="music__track-group">
                                <div className="music__track-title">Тает снег</div>
                                <div className="music__track-subtitle red">Ramil'</div>
                            </div>
                        </div>
                        <div className="music__track-info">
                            <span className="music__time-track">3:10</span>
                            <a href="#">
                                <img className="music__track-add mt-5" src="../img/icon/plus.svg" alt="add-track" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="list__item">
                    <div className="c-i-s-b">
                        <div className="music__track">
                            <div className="music__track-cover">
                                <img src="../img/png/music/music-cover-5.png" alt="cover" />
                            </div>
                            <div className="music__track-group">
                                <div className="music__track-title">25</div>
                                <div className="music__track-subtitle red">Markul</div>
                            </div>
                        </div>
                        <div className="music__track-info">
                            <span className="music__time-track">2:45</span>
                            <a href="#">
                                <img className="music__track-add mt-5" src="../img/icon/plus.svg" alt="add-track" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="list__item">
                    <div className="music__track">
                        <div className="music__track-cover">
                            <img src="../img/png/music/music-cover-3.png" alt="cover" />
                        </div>
                        <div className="music__track-group">
                            <div className="music__track-title">Bentley</div>
                            <div className="music__track-subtitle red">WHITE GALLOWS</div>
                        </div>
                    </div>
                    <div className="music__track-info">
                        <span className="music__time-track">4:21</span>
                        <a href="#">
                            <img className="music__track-add mt-5" src="../img/icon/plus.svg" alt="add-track" />
                        </a>
                    </div>
                </div>
                <div className="list__item">
                    <div className="c-i-s-b">
                        <div className="music__track">
                            <div className="music__track-cover">
                                <img src="../img/png/music/music-cover-4.png" alt="cover" />
                            </div>
                            <div className="music__track-group">
                                <div className="music__track-title">Золото</div>
                                <div className="music__track-subtitle red">ARCHI</div>
                            </div>
                        </div>
                        <div className="music__track-info">
                            <span className="music__time-track">3:29</span>
                            <a href="#">
                                <img className="music__track-add mt-5" src="../img/icon/plus.svg" alt="add-track" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="list__item">
                    <div className="c-i-s-b">
                        <div className="music__track">
                            <div className="music__track-cover">
                                <img src="../img/png/music/music-cover-2.png" alt="cover" />
                            </div>
                            <div className="music__track-group">
                                <div className="music__track-title">По пути к мечте</div>
                                <div className="music__track-subtitle red">SELYAVIE</div>
                            </div>
                        </div>
                        <div className="music__track-info">
                            <span className="music__time-track">2:02</span>
                            <a href="#">
                                <img className="music__track-add mt-5" src="../img/icon/plus.svg" alt="add-track" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="list__item">
                    <div className="c-i-s-b">
                        <div className="music__track">
                            <div className="music__track-cover">
                                <img src="../img/png/music/music-cover-3.png" alt="cover" />
                            </div>
                            <div className="music__track-group">
                                <div className="music__track-title">Мечта</div>
                                <div className="music__track-subtitle red">ELMAN</div>
                            </div>
                        </div>
                        <div className="music__track-info">
                            <span className="music__time-track">3:10</span>
                            <a href="#">
                                <img className="music__track-add mt-5" src="../img/icon/plus.svg" alt="add-track" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}