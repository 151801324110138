import React from 'react';
import { Link } from 'react-router-dom'
import Button_login from '../components/UI/button/button_login';
import '../pages/error.css';
export default function ErrorPage() {

    return (
        <div>
            <div className='m_block c-i-s-b'>
                <Link to='/'>
                    <img className="exit-component-icon" src='img/icon/right-red.svg' alt="exit:icon" />
                </Link>
                <div className='white-s-14 m-0'>
                    <p className="c-gray f-s-16">Что-то пошло не так</p>
                </div>
            </div>
            <div className="error">
                <main className='error__block'>
                    <div className="error__svg">
                        <img src="img/other/error.svg" alt="" />
                    </div>
                    <Link to='/'>
                        <Button_login className="mt-40" >На главную</Button_login>
                    </Link>
                </main>
            </div>
        </div>
    )

}
