import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LoginService from "../../API/LoginService";
import authSlice from "./authSlice";

const initialState = {
    login: '',
    password: null,
    token: null,
    errorLogin: false,
    loaderLogin: false,
    userAgent: null,
    fingerPrint: null,
}

export const getToken = createAsyncThunk(
    'login/getToken',
    async(mass, {rejectWithValue, dispatch, }) => {
        //const response = await axios.post(URL)
        //console.log(mass?.login, mass?.password)
        const response = await LoginService.getTokenNew(mass?.login, mass?.password)
        //console.log(response)
        console.log(response.data?.session_key?.session_user)
        response.data?.response === 200 && dispatch(setToken(response.data?.session_key?.session_user))
        localStorage.setItem('tokenUser', response.data?.session_key?.session_user);
        dispatch(authSlice.setToken(response.data?.session_key?.session_user))
    }
)


const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setLogin: (state, action) => {
            state.login = action.payload
        },
        setPassword: (state, action) => {
            state.password = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setFingerPrint: (state, action) => {
            state.fingerPrint = action.payload
        }
    }, extraReducers: {
        [getToken.fulfilled]: (builder) => console.log("1"),
        [getToken.pending]: () => (console.log("Ожидание")),
        [getToken.rejected]: () => (console.log("Ошибка")),
    }
});

export const {setLogin, setPassword, setToken, setFingerPrint} = loginSlice.actions
export default loginSlice.reducer
