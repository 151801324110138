import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalChat from './ModalChat';

export default function MenuPosts() {

    const [chatActive, setChatActive] = useState(false);
    return (
        <div>
            <div className="sms-block c-i-s-b" onClick={() => setChatActive(true)}>
                <div className="d-f-a-c" >
                    <Link to="/home">
                        <div className="post_ava">
                            <img src="../img/png/13.png" alt="" />
                        </div>
                    </Link>
                    <div className="ml-10">
                        <p className="f-s-16 name">Екатерина Нилова</p>
                        <span className="mt-10 f-s-13 txt_c_online">У меня раздвоение личности...</span>
                    </div>
                </div>
                <div className="reading">
                    <p className="white f-s-12">2</p>
                </div>
            </div>
            <ModalChat active={chatActive} setActive={setChatActive}></ModalChat>
            <div className="sms-block c-i-s-b" onClick={() => setChatActive(true)}>
                <div className="d-f-a-c">
                    <div className="post_ava">
                        <img src="../img/png/17.png" alt='' />
                    </div>
                    <div className="ml-10">
                        <p className="f-s-16 name">Design</p>
                        <span className="mt-10 f-s-13 txt_c_online">Артём: Да, окей)</span>
                    </div>
                </div>
                <div className="reading">
                    <p className="white f-s-12">194</p>
                </div>
            </div>
            <div className="sms-block c-i-s-b">
                <div className="d-f-a-c">
                    <div className="post_ava">
                        <img src="../img/png/5.png" alt='' />
                    </div>
                    <div className="ml-10">
                        <p className="f-s-16 name">Алексей Гарнов</p>
                        <span className="mt-10 f-s-13 txt_c_online">Вы: Давай вечером встретимся?</span>
                    </div>
                </div>

            </div>
            <div className="sms-block c-i-s-b">
                <div className="d-f-a-c">
                    <div className="post_ava">
                        <img src="../img/png/12.png" alt='' />
                    </div>
                    <div className="ml-10">
                        <p className="f-s-16 name">Милана Сорокина</p>
                        <span className="mt-10 f-s-13 txt_c_online">Вы: [Голосовое сообщение]</span>
                    </div>
                </div>
            </div>
            <div className="sms-block c-i-s-b">
                <div className="d-f-a-c">
                    <div className="post_ava">
                        <img src="../img/png/11.png" alt='' />
                    </div>
                    <div className="ml-10">
                        <p className="f-s-16 name">Дмитрий Бузаев</p>
                        <span className="mt-10 f-s-13 txt_c_online">Дмитрий: В 15:00 у ТЦ "Океан"</span>
                    </div>
                </div>
                <div className="reading">
                    <p className="white f-s-12">3</p>
                </div>
            </div>
            <div className="sms-block c-i-s-b">
                <div className="d-f-a-c">
                    <div className="post_ava">
                        <img src="../img/png/12.png" alt='' />
                    </div>
                    <div className="ml-10">
                        <p className="f-s-16 name">Милана Сорокина</p>
                        <span className="mt-10 f-s-13 txt_c_online">Вы: [Голосовое сообщение]</span>
                    </div>
                </div>
            </div>
            <div className="sms-block c-i-s-b">
                <div className="d-f-a-c">
                    <div className="post_ava">
                        <img src="../img/png/11.png" alt='' />
                    </div>
                    <div className="ml-10">
                        <p className="f-s-16 name">Дмитрий Бузаев</p>
                        <span className="mt-10 f-s-13 txt_c_online">Дмитрий: В 15:00 у ТЦ "Океан"</span>
                    </div>
                </div>
                <div className="reading">
                    <p className="white f-s-12">3</p>
                </div>
            </div>
            <div className="sms-block c-i-s-b">
                <div className="d-f-a-c">
                    <div className="post_ava">
                        <img src="../img/png/12.png" alt='' />
                    </div>
                    <div className="ml-10">
                        <p className="f-s-16 name">Милана Сорокина</p>
                        <span className="mt-10 f-s-13 txt_c_online">Вы: [Голосовое сообщение]</span>
                    </div>
                </div>
            </div>
            <div className="sms-block c-i-s-b">
                <div className="d-f-a-c">
                    <div className="post_ava">
                        <img src="../img/png/11.png" alt='' />
                    </div>
                    <div className="ml-10">
                        <p className="f-s-16 name">Дмитрий Бузаев</p>
                        <span className="mt-10 f-s-13 txt_c_online">Дмитрий: В 15:00 у ТЦ "Океан"</span>
                    </div>
                </div>
                <div className="reading">
                    <p className="white f-s-12">3</p>
                </div>
            </div>
        </div>
    )

}