import React, { useState, useEffect } from "react";
import Modal from '../../../components/Modal/Modal';
import { Link } from "react-router-dom";
import MenuReactPrivate from "../../../components/UI/menu/MenuReactPrivate";

import LoginService from "../../../API/LoginService";
function ExitFromAccount() {
    localStorage.clear()
}


export default function PrivateZoneMainFriends() {

    const [modalLogIn, setModalLogIn] = useState(false)
    async function checkToken() {

        try {
            await LoginService.checkToken(localStorage.getItem("tokenUser"), localStorage.getItem("userID"))
                .then(function (response) {
                    //console.log(response.data.response)
                    if (response.data.response == 404) {
                        setModalLogIn(true); localStorage.clear(); window.location.reload()

                    }
                })
                .catch(function (error) {
                    //console.log(error.message)
                })
                .then(function () {

                })
        } catch (e) {
            //console.log(e)
        }


    }

    useEffect(() => {
        checkToken()
        setInterval(() => checkToken(), 12000);
    }, []);


    return (
        <div className="animation-home">
            {/* Шапка */}
            <div className="m_block c-i-s-b ">
                <Link to="/other">
                    <img className="exit-component-icon" src='img/icon/right-red.svg' alt="exit:icon" />
                </Link>

                <div className="t-a-c c-gray f-s-16">
                    <p>Друзья</p>
                </div>

                <div className=" search-i-friend" onClick={e => ExitFromAccount()}>
                    {/* <Link to="/exit">
                            <p className="name"></p>
                        </Link> */}
                    <img src='img/icon/search-2.svg' alt='' />
                </div>
            </div>
            {/*  */}



            {/* Блок - "Друзья" */}
            <div className="m_block mt-10">
                <Link to="/friends">
                    <div className="c-i-s-b">
                        <div className="c-gray f-s-16 d-f-a-c">
                            <p className="name">Друзья: 23</p>
                        </div>
                        <img className="exit-component-icon" src="../img/icon/right_red.svg" alt='' />
                    </div>
                </Link>
                <div className="over-auto mt-10">
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/10.png" alt='' />
                        </div>
                        <div className="online-friends"></div>
                        <p className="white-s-12 t-a-c mt-10">Иван</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/11.png" alt='' />
                        </div>
                        {/* <div className="online-friends"></div> */}
                        <p className="white-s-12 t-a-c mt-10">Андрей</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/12.png" alt='' />
                        </div>
                        {/* <div className="online-friends"></div> */}
                        <p className="white-s-12 t-a-c mt-10">Анастасия</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/13.png" alt='' />
                        </div>
                        <div className="online-friends"></div>
                        <p className="white-s-12 t-a-c mt-10">Ольга</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/14.png" alt='' />
                        </div>
                        {/* <div className="online-friends"></div> */}
                        <p className="white-s-12 t-a-c mt-10">Илья</p>
                    </div>

                </div>
            </div>
            {/*  */}

            {/* Блок - "подписчики" */}
            <div className="m_block mt-10">
                <Link to="/SubscribeFriends">
                    <div className="c-i-s-b">
                        <div className="c-gray f-s-16 d-f-a-c">
                            <p className="name">Подписчики: 11</p>
                        </div>
                        <img className="exit-component-icon" src="../img/icon/right_red.svg" alt='' />
                    </div>
                </Link>
                <div className="over-auto mt-10">
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/10.png" alt='' />
                        </div>
                        <div className="online-friends"></div>
                        <p className="white-s-12 t-a-c mt-10">Иван</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/11.png" alt='' />
                        </div>
                        {/* <div className="online-friends"></div> */}
                        <p className="white-s-12 t-a-c mt-10">Андрей</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/12.png" alt='' />
                        </div>
                        {/* <div className="online-friends"></div> */}
                        <p className="white-s-12 t-a-c mt-10">Анастасия</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/13.png" alt='' />
                        </div>
                        <div className="online-friends"></div>
                        <p className="white-s-12 t-a-c mt-10">Ольга</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/14.png" alt='' />
                        </div>
                        {/* <div className="online-friends"></div> */}
                        <p className="white-s-12 t-a-c mt-10">Илья</p>
                    </div>

                </div>
            </div>
            {/*  */}

            {/* Блок - "Онлайн" */}
            <div className="m_block mt-10">
                <Link to="/online">
                    <div className="c-i-s-b">
                        <div className="c-gray f-s-16 d-f-a-c">
                            <p className="name">Онлайн: 2</p>
                        </div>
                        <img className="exit-component-icon" src="../img/icon/right_red.svg" alt='' />
                    </div>
                </Link>
                <div className="over-auto mt-10">
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/4.png" alt='' />
                        </div>
                        <div className="online-friends"></div>
                        <p className="white-s-12 t-a-c mt-10">Иван</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/5.png" alt='' />
                        </div>
                        <div className="online-friends"></div>
                        <p className="white-s-12 t-a-c mt-10">Андрей</p>
                    </div>


                </div>
            </div>
            {/*  */}

            {/* Блок - "У кого я в чс" */}
            <div className="m_block mt-10">
                <Link to="/BlackList">
                    <div className="c-i-s-b">
                        <div className="c-gray f-s-16 d-f-a-c">
                            <p className="name">У кого я в чс: 2</p>
                        </div>
                        <img className="exit-component-icon" src="../img/icon/right_red.svg" alt='' />
                    </div>
                </Link>
                <div className="over-auto mt-10">
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/14.png" alt='' />
                        </div>
                        <div className="online-friends"></div>
                        <p className="white-s-12 t-a-c mt-10">Иван</p>
                    </div>
                    <div className="mt-10 w-50 mr-10">
                        <div className="ava-friends">
                            <img src="../img/png/15.png" alt='' />
                        </div>
                        <div className="online-friends"></div>
                        <p className="white-s-12 t-a-c mt-10">Андрей</p>
                    </div>
                </div>
            </div>

            <Modal active={modalLogIn} setActive={setModalLogIn} on>
                <div className="no-back_block">
                    <div className="mt-20 mb-10 btn-2-red t-a-c c-i" onClick={e => setModalLogIn(false)}>
                        <p className="ml-10 white-s-16">Сессия устарела</p>
                    </div>
                </div>
            </Modal>
            {/*  */}

            {/* Блок - "Кто у меня в чс" */}
            <div className="m_block mt-10">
                <Link to="/WhoBlackList">
                    <div className="c-i-s-b">
                        <div className="c-gray f-s-16 d-f-a-c">
                            <p className="ml-10 name">Кто у меня в чс: 0</p>
                        </div>
                        <img className="exit-component-icon" src="../img/icon/right_red.svg" alt='' />
                    </div>
                </Link>
                <div className=" mt-20 t-a-c">
                    <p className="name">Таких пока нет</p>
                </div>
            </div>
            {/*  */}



            {/* меню */}
            <MenuReactPrivate />
        </div>
    )

}