import React, { useState, useEffect } from 'react'
import Input from 'react-phone-number-input/input'
import { Link, resolvePath, Navigate, useNavigate } from 'react-router-dom';
import FreeZoneComponetInputPassword from '../../../components/Free_zone/UI/input/input_password';
import InputFreeZone from '../../../components/UI/input/InputFreeZone';
import useInput from '../../../hooks/useInput';

export default function PrivateZoneDefaultSettings() {

	let [dateFromServer, setDateFromServer] = useState({
		name: '',
		lastName: '',
		dayBirstay: 1,
		mountBirstay: 0,
		yearsBirstay: new Date().getFullYear(),
		sex: 0
	})

	const [phone, setPhone] = useState();

	let date_cycle = [];
	let date_start = 1; let date_end = 31;
	while (date_start <= date_end) {
		date_cycle.push(date_start);
		date_start++;
	}

	let sex = ['Женский', 'Мужской']

	let mount = ['Январь', 'Февраль', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];

	let yearc_cycle = [];
	let yearc_start = 1990; let yearc_end = new Date().getFullYear();
	while (yearc_start <= yearc_end) {
		yearc_cycle.push(yearc_end);
		yearc_end--;
	}

	const nameUser = useInput('')
	const sernameUser = useInput('')

	const email = useInput('')

	const [password, setPassword] = useState({ password1: '', password2: '' });
	const [statusChech, setStatusChech] = useState(false);
	const [password2status, setPassword2Status] = useState(false);

	useEffect(() => {
		if (password.password2 != "") {
			if (statusChech == true) {
				password.password1 == password.password2 ? setPassword2Status(false) : setPassword2Status(true)
			}
			statusChech == false && setStatusChech(true)
		}
	}, [password.password2, password.password1]);

	return (
		<div className="animation">
			<div className='m_block c-i-s-b'>
				<Link to='/MainSettings'>
					<div className="block_in_svg_2">
						<img className="exit-component-icon " src='img/icon/right-red.svg' alt="exit:icon" />
					</div>
				</Link>
				<div className='t-a-c c-gray f-s-16 m-0'>
					<p>Аккаунт</p>
				</div>
			</div>
			<div className="block-2">
				<div className="m_block mt-20" onChange={e => (e.stopPropagation())}>
					<form onChange={e => (e.stopPropagation())}>
						<p className="white f-s-14 mt-10">Имя:</p>
						<InputFreeZone {...nameUser} className="inp-txt-2 f-s-16 c-gray mt-10">Имя:</InputFreeZone>

						<p className="white f-s-14 mt-10">Фамилия:</p>
						<InputFreeZone {...sernameUser} className="inp-txt-2 f-s-16 c-gray mt-10" >Фамилия:</InputFreeZone>

						<p className="white f-s-14 mt-10">Дата рождения:</p>
						<div className="c-i">
							<select
								name="day" className="inp-db f-s-16 c-gray mt-10" onChange={e => setDateFromServer({ ...dateFromServer, dayBirstay: e.target.value })}>
								{
									date_cycle.map((item, idx) => (
										<option key={idx} value={idx}>{item}</option>
									))
								}
							</select>
							<select name="month" className="inp-db f-s-16 c-gray mt-10"
								onChange={e => setDateFromServer({ ...dateFromServer, mountBirstay: e.target.value })}
							>
								{
									mount.map((item, idx) => (
										<option key={idx} value={idx}>{item}</option>
									))
								}
							</select>
							<select name="year" className="inp-db f-s-16 c-gray mt-10" onChange={e => setDateFromServer({ ...dateFromServer, yearsBirstay: e.target.value })}>
								{
									yearc_cycle.map(yearc => (
										<option key={yearc} value={yearc}>{yearc}</option>
									))
								}
							</select>
						</div>
						<p className="white f-s-14 mt-10">Пол:</p>
						<select name="sex" id="" className="inp-txt-2 f-s-16 c-gray mt-10" onChange={e => setDateFromServer({ ...dateFromServer, sex: e.target.value })}>
							{sex.map((sex, idx) => (
								<option key={idx} value={idx}>{sex}</option>
							))}
						</select>
					</form>
				</div>
				<div className="m_block mt-20">
					<form onChange={e => (e.stopPropagation())}>
						<p className="white f-s-14 mt-10">Телефон:</p>
						<Input
							className="inp-txt-2 f-s-16 c-gray mt-10 tel" name="telephone" type="text" placeholder="+7 (ХХХ) ХХХ-ХХ-ХХ"
							onChange={e => setPhone(e)}
							value={phone} />
						<p className="white f-s-14 mt-10">Email:</p>
						<input name="email" className="inp-txt-2 f-s-16 c-gray mt-10 email"
							type="email" placeholder="Email:"
							{...email}
						/>
					</form>
				</div>
				
			</div>
			<div className='btn__exit-group  mt-20'>
				<button className="btn-main-accen btn-2-red c-i f-s-14" >
					<img src="img/icon/saving.svg" alt="" />
					<p className='white ml-10'>Сохранить</p>
				</button>
			</div>
		</div>
	)
}
