import {configureStore} from "@reduxjs/toolkit";
import authSlice from "./slice/authSlice";
import loginSlice from "./slice/loginSlice";
//import userReducer from './slice/userSlice';
import registerOneSlice from "./slice/registerOneSlice";
import uiSlice from "./slice/uiSlice";


export const store = configureStore({
    reducer: {
        //user: userReducer
        registerOne: registerOneSlice,
        login: loginSlice,
        auth: authSlice,
		ui: uiSlice,
    }
})
