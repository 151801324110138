import React, { useState, useEffect } from "react";
import Modal from '../../components/Modal/Modal';
import CodeMenu from "../../components/News/CodeMenu";
import ImagesMenu from "../../components/News/ImgesMenu";
import MusicMenu from "../../components/News/MusicMenu";
import PostMenu from "../../components/News/PostMenu";
import SaveMenu from "../../components/News/SaveMenu";
import ShopsMenu from "../../components/News/ShopsMenu";
import VideoMenu from "../../components/News/VideoMenu";
import MenuReactPrivate from "../../components/UI/menu/MenuReactPrivate";
import LoginService from "../../API/LoginService";

export default function PrivateZoneNewsPage() {
    const [menuEditor, setMenuEditor] = useState("Посты");
    const menuElements = [
        ['Посты', '../img/icon/news-posts.svg'],
        ['Изображения', '../img/icon/news-photo.svg'],
        // ['Музыка', '../img/icon/news-music.svg'],
        // ['Видео', '../img/icon/news-video.svg'],
        // ['Покупки', '../img/icon/news-shop.svg'],
        // ['Код', '../img/icon/news-code.svg'],
        ['Закладки', '../img/icon/news-save.svg']
    ]; // Отображение элемента навигации на странице новостей

    const [modalLogIn, setModalLogIn] = useState(false)
    async function checkToken() {
        try {
            await LoginService.checkToken(localStorage.getItem("tokenUser"), localStorage.getItem("userID"))
                .then()
                .catch(function (error) {
                    if (error.response.data.response === 401) {
                        setModalLogIn(true);
                        localStorage.clear();
                        window.location.reload()
                    }
                })
                .then(function () {

                })
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        checkToken()
        setInterval(() => checkToken(), 12000);
    }, []);

    return (
        <div className="news">
            <div className="animation-home">
                {/* Шапка */}
                <div className="m_block_3 c-i-s-b ">
                    <div className="d-f-a-c">
                        <img src="../img/logo/Marlo.svg" width='100px' alt="Логотип" />
                    </div>
                    {/* <div className="block_in_svg p-5-5-0-5">
                        <img src="../img/icon/notification.svg" alt="Уведомления" />
                    </div> */}
                </div>
                <div className="t-a-c ">
                    <div className="mt-20 c-i-s-b mb-20 pd-lr-15">
                        {menuElements.map(menuElement => (
                            <div className={menuElement[0] == menuEditor ? "block_3 activess" : "block_3 no-activess "}>
                                <img key={menuElement[0]}
                                    src={menuElement[1]}
                                    onClick={() => setMenuEditor(menuElement[0])}
                                    alt={menuElement[0]}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                {/* меню выбора */}
                {menuEditor == "Посты" && <PostMenu />}
                {menuEditor == "Изображения" && <ImagesMenu />}
                {/* {menuEditor == "Музыка" && <MusicMenu />}
                {menuEditor == "Видео" && <VideoMenu />}
                {menuEditor == "Покупки" && <ShopsMenu />}
                {menuEditor == "Код" && <CodeMenu />} */}
                {menuEditor == "Закладки" && <SaveMenu />}
            </div>

            {/* Технические моменты */}
            <Modal active={modalLogIn} setActive={setModalLogIn} on>
                <div className="no-back_block">
                    <div className="mt-20 mb-10 btn-2-red t-a-c c-i" onClick={e => setModalLogIn(false)}>
                        <p className="ml-10 white-s-16">Сессия устарела</p>
                    </div>
                </div>
            </Modal>

            {/* меню */}
            <MenuReactPrivate />
        </div>
    )

}
