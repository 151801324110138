import React, { useState } from 'react'
import ModalPhotos from '../../components/Modal/ModalPhoto'

import MenuReactPrivate from '../../components/UI/menu/MenuReactPrivate'
import { Link, useHistory, useLocation } from 'react-router-dom'

export default function PrivateZonePhoto() {
    const photo = [
        {
            url: 'img/png/photo-1.png',
        },
        {
            url: 'img/png/photo-2.png',
        },
        {
            url: 'img/png/photo-3.png',
        },
        {
            url: 'img/png/photo-4.png',
        },
        {
            url: 'img/png/photo-5.png',
        },
        {
            url: 'img/png/photo-6.png',
        },
    ]

    const [openPhoto, setOpenPhoto] = useState(false)
    const [urlPhoto, setUrlPhoto] = useState('')

    const openPhotoFunc = e => {
        setUrlPhoto(e)
        setOpenPhoto(true)
        //console.log(e)
    }

    return (
        <div className='photo-component'>
            <section className='portfolio-section'>
                {photo.map(photo => (
                    <div
                        className='portfolio-item'
                        onClick={e => openPhotoFunc(photo.url)}
                        style={{ backgroundImage: 'url(' + photo.url + ')' }}
                    ></div>
                ))}
            </section>
            <ModalPhotos
                active={openPhoto}
                setActive={e => setOpenPhoto(false)}
                photoURL={urlPhoto}
            />
            <MenuReactPrivate />
        </div>
    )
}
