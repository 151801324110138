import axios from "axios";
/*
Класс, который отвечает за Восстановление пароля
*/

export default class restorePasswordService {


    // Получение нового токена восстановления пароля
    static async getTokenNew (email){

        const response = await axios.post(`https://dev.api.marlomba.ru/api/restorePass/`, {
            email
        })

        return response

    }

    // Подтверждение кода с электронной почты
    static async setEmailCode (token, code){

        const response = await axios.post(`https://dev.api.marlomba.ru/api/restorePassCode/`, {
            token,
            code: parseInt(code),
        })

        return response

    }

    static async setPassword (token, password){

        const response = await axios.post(`https://dev.api.marlomba.ru/api/restorePassNew/`, {
            token,
            password
        })

        return response

    }


}
