import React from 'react';
import "./Modal.css";

const ModalCopy = ({active, setActive, children}) => {
        return (
            <div id='achives' className={active ? "modelOptions active" : "modelOptions"} onClick={() => setActive(false)}>
                <div className="block_options" onClick={e=>e.stopPropagation()}>
                    <div className="no-back_block c-i">  
                        {children}
                    </div>
                </div>
            </div>
        )
}

export default ModalCopy;