import * as yup from 'yup';


const loginSchema = yup.object().shape({
	login: yup.string()
	   .required('email or phone is required')
	   .test('email or phone', 'email or phone is invalid', (value) => {
		  return validateEmail(value) || validatePhone(parseInt(value ?? '0'));
	   }),
	password: yup.string().required(),
 });

 export const emailSchema = yup.object().shape({
	email: yup.string().email().required(),
 });
 
 const validateEmail = (email) => yup.string().email().isValidSync(email);
 
 const validatePhone = (phone) => {
	return yup.number()
		.integer()
		.positive()
		.test((phone) => {
		 	return (phone && phone.toString().length === 11) ? true : false;
	   })
	   .isValidSync(phone);
 };

 export default loginSchema;