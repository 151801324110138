import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from '../../components/Modal/Modal';
import ModalNotification from '../../components/Modal/ModaNotification';
import ModalPhoto from '../../components/Modal/ModalPhoto';
import MenuMusic from "../../components/Home/MenuMusic";
import MenuGroup from "../../components/Home/MenuGroup";
import MenuLike from "../../components/Home/MenuLike";
import MenuReactPrivate from "../../components/UI/menu/MenuReactPrivate";
import ProfileServise from "../../API/ProfileServise";
import LoginService from "../../API/LoginService";
import { dayPrint } from "../../func/secrity/profile/day";
import useReques from "../../hooks/useReques";
import ImagesMenu from "../../components/Home/MenuImages";
import PostMenu from "../../components/Home/MenuPosts";
import SaveMenu from "../../components/Home/MenuLike";
import ModalAddPost from "../../components/Modal/ModalAddPost";
import Error from "../../components/Modal/Errors";
import axios from "axios";


const ImgPost = (scr, alt = "") => {
	console.log(scr)
	return (
		<img src={scr.scr} alt={alt} />
	)
}

export default function PrivateZoneHomePage() {
	const [modalActive, setModalActive] = useState(false);
	const [statusActive, setStatusActive] = useState(false);
	const [photoActive, setPhotoActive] = useState(false);
	const [modalAddPost, setAddPost] = useState(false);
	const [DelPhoto, setDelPhoto] = useState(false);
	const [modalNotifActive, setNotifActive] = useState(false);
	const [menuEditor, setMenuEditor] = useState("Посты");
	const menuElements = [
		['Посты', '../img/icon/news-posts.svg'],
		['Изображения', '../img/icon/news-photo.svg'],
		['Закладки', '../img/icon/news-save.svg']
	];

	const [userPageStatus, setUserPageStatus] = useState('Статус отсутствует');
	const [userStatusInModal, setUserStatusInModal] = useState(userPageStatus);

	const [modalLogIn, setModalLogIn] = useState(false)

	const [userProfile, setUserProfile] = useState([])

	const [userPost, setUserPost] = useState([])
	const [postText, setPostText] = useState(''); // Текст поста пользователя
	const [activeButtonPost, setActiveButtonPost] = useState(false) // Отображение активной кнопки добавление постов
	const photo = [
		{
			url: 'img/png/photo-1.png',
		},
		{
			url: 'img/png/photo-2.png',
		},
		{
			url: 'img/png/photo-3.png',
		},
		{
			url: 'img/png/photo-4.png',
		},
		{
			url: 'img/png/photo-5.png',
		},
		{
			url: 'img/png/photo-6.png',
		},
	]

	const [openPhoto, setOpenPhoto] = useState(false)
	const [urlPhoto, setUrlPhoto] = useState('')

	const openPhotoFunc = e => {
		setUrlPhoto(e)
		setOpenPhoto(true)
		//console.log(e)
	}

	const [errors, setErrors] = useState(false)
	const [ErrorInfo, setErrorInfo] = useState("Произошла ошибка")



	async function setStatusInUser() {


		const response = await ProfileServise.setStatus(localStorage.getItem("tokenUser"), userStatusInModal)
        .catch(err => {
            setErrorInfo("Не удалось обновить статус")
            return setErrors(true);
        })

		setUserPageStatus(userStatusInModal)
		setStatusActive(false)

	}

	async function checkToken() {
		try {
			await LoginService.checkToken(localStorage.getItem("tokenUser"), localStorage.getItem("userID"))
				.then()
				.catch(function (error) {
					if (error.response.data.response === 401) {
						setModalLogIn(true);
						localStorage.clear();
						window.location.reload()
					}
				})
				.then(function () {

				})
		} catch (e) {
			console.log(e)
		}
	}
	useEffect(() => {
		checkToken()
		setInterval(() => checkToken(), 12000);
	}, []);


	const [dayBirstay, setDayBirstay] = useState("")

	function getFuncDay(response) {
		setDayBirstay(dayPrint(response))
	}

	useEffect(() => { console.log(userProfile) }, [userProfile])

	useEffect(() => {
		setUserPageStatus(userProfile.status)
        getFuncDay(userProfile.birdh)
	}, [userProfile]);




	const [fetchingUserInfo, loading, error] = useReques(async (token, userID) => {
		const response = await ProfileServise.getProfile(token, userID)
		setUserProfile(response.data)
	})

	useEffect(() => {
		fetchingUserInfo(localStorage.getItem('tokenUser'), localStorage.getItem('userID'))
        isLoafetchingCount()
	}, []);


	// Посты пользователя

	const [fetchingPost, isLoadingPost, errorPost] = useReques(async (idUser, idUserToken, session_key, limit, offset) => {
		const response = await ProfileServise.getPostProfile(idUser, idUserToken, session_key, limit, offset)
		//console.log(response)
		setUserPost(response.data)
		//return response.data
	})

	useEffect(() => {
		// Загрузка постов пользователя, при загрузки страницы
		//fetchingPost()
		/*setUserPost(...userPost, {
				text: "test"
		})*/
	}, [])

	useEffect(() => {
		console.log(userPost)
	}, [userPost])


// Логика связанная с добавление постов и самими поставми на странице
    const [image, setImage] = useState();
    const [imageURL, setImageURL] = useState();
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
        setImageURL(fileReader.result);
    };
    const handleOnChange = (event) => {
        event.preventDefault();
        if (event.target.files && event.target.files.length) {
        const file = event.target.files[0];
        setImage(file);
        fileReader.readAsDataURL(file);
        }
    };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files.length) {
      setImage(event.dataTransfer.files[0]);
      fileReader.readAsDataURL(event.dataTransfer.files[0]);
    }
  };

  const handleDragEmpty = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  // Тестовые посты
  useEffect(() => {
    setUserPost([{
        id: 1,
        text: "Текст поста",
    }, {
        id: 2,
        text: "Текст поста",
		img: "./img/icon/add-post.svg"
    }])
  }, []);



  const addPost = () => {
    //console.log("Добавление поста")
    if (activeButtonPost){
        console.log("Добавление поста")

        if (imageURL){
            setUserPost([{

                text: postText,
                img: imageURL
            }, ...userPost])

            setPostText('')
            setImage('')
            setImageURL('')
        }   else {
            setUserPost([{

                text: postText
            }, ...userPost])
            setPostText('')
            setImage('')
            setImageURL('')
        }

    }
  }

    useEffect(() => {
        // Отслеживание изменение клавиши
        if (postText.length > 0 || image ){
            setActiveButtonPost(true)
        }   else {
            setActiveButtonPost(false)
        }
    }, [postText, image]);

    useEffect(() => {
        console.log(image)
        console.log(imageURL)
    }, [image, imageURL])

    const delPhotoFun = (id_post) => {
        console.log("Я функция удаления фото")
        console.log(id_post)
        //userPost.filter(obj => person.name != 'John');
        let userPostMass = JSON.parse(JSON.stringify(userPost));
        console.log(userPostMass)
        setUserPost([userPostMass.shift()])
    }

    const [imageAvatar, setImageAvatar] = useState(null)

    const createAva = (e) => {

        let type_files = e.target.files[0].type

        if (!type_files.match("image/*")) {
            setErrorInfo("Нельзя загрузить такой тип файлов")
            return setErrors(true);
        }

        setImageAvatar(e.target.files[0]);
        sendServerIndexImage();
    }
    const sendServerIndexImage = () => {
        const formData = new FormData();
        formData.append("avatar", imageAvatar);
        formData.append("token", localStorage.getItem('tokenUser'));

        axios.post('https://dev.api.marlomba.ru/api/user/setting/setIndexPhotoController', formData)
        .then(res => {
            console.log(res)
            if (res.status === 200) {
                setErrorInfo("Фотография загружена")
                return setErrors(true);
            }
        })
        .catch((e) => {
            console.log(e);
            setErrorInfo("Не удалось загрузить фотографию на сервер")
            return setErrors(true);
        })
    }

    // Получение информации о количестве друзей и подписчиков пользователя
    const [countFrinds, setCountFrinds] = useState({
        countFriends: 0,
        countSubscriber: 0
    })

    const [isLoafetchingCount, dingCount, errorCount] = useReques(async () => {
		const response = await ProfileServise.getCountFriend(localStorage.getItem('tokenUser'), localStorage.getItem('userID'))
		//console.log(response)
		//setUserPost(response.data)
		//return response.data

        setCountFrinds({
            ...countFrinds,
            countFriends: response.data.countFriends,
            countSubscriber: response.data.countSubscriber,
        })
	})

// Конец логике постов и добавление постов

	return (
		<div className="aniim">
			<div className="animation-home">
				{/* Шапка */}
				<div className="m_block b-b-12 c-i-s-b mb-16">
					<Link to="/MainSettings">
						<div className="icon">
							<img src="img/icon/setting-2.svg" alt='' className="setting-icon" />
						</div>
					</Link>

					<div className="white f-w-500 f-s-16">
						<p>ID {localStorage.getItem('userID')}</p>
					</div>
					<div className="add-not">
						<div className="icon-add" onClick={() => setAddPost(true)}>
							<img src="img/icon/add-home.svg" alt='' className="setting-icon icon-red" />
						</div>

						{/* <div className="icon-notification" onClick={() => setNotifActive(true)}>
								<img src="img/icon/notification.svg" alt='' className="setting-icon not" />
							</div> */}
					</div>

					<ModalAddPost active={modalAddPost} setActive={setAddPost}></ModalAddPost>

					<ModalNotification active={modalNotifActive} setActive={setNotifActive}></ModalNotification>
				</div>
				{/*  */}

				{/* Основная информация о пользователе */}
				<div className="m_block">
					{
						userProfile ? (
							<div className="back_photo_ava mt-10">
								<img src={"https://dev.api.marlomba.ru/static/img/" + userProfile.backAvatar}
									width="100%" alt='' />
							</div>
						) : (
							<div className="back_photo_ava mt-10">
								<img src="../img/png/1.png" width="100%" alt='' />
							</div>
						)
					}
					{
						userProfile ? (
							<div className="ava_profil">
								<img src={"https://dev.api.marlomba.ru/static/img/" + userProfile.avatar}
									width="100%" alt='' />
							</div>
						) : (
							<div className="ava_profil">
								<img src="../img/png/1.png" width="100%" alt='' />
							</div>
						)
					}
					<div className="online" onClick={() => setModalActive(true)}></div>

					<div className="txt">

						<p className="name d-f-a-c f-s-20">{localStorage.getItem('userName')} {localStorage.getItem('userLastname')}
                        {
                            // Вывод галочки верефикации
                            userProfile.verifed == 1 && (
                                <img src="img/icon/check.svg" className="ml-10" alt='' />
                            )
                        }
                        {
                            userProfile.alfa_tester && (
                                <img src="img/icon/magic-star.svg" className="ml-10" alt='' />
                            )
                        }
						</p>
						<div className="status mt-5 d-f-a-c">
							<span className="c-gray f-s-16">{statusActive}</span>
							<p className="c-gray f-s-14" onClick={() => setStatusActive(true)}>{userPageStatus}</p>
							<img src="img/icon/edit-2.svg" className="ml-7" onClick={() => setStatusActive(true)} alt='' />
						</div>
					</div>
					<div className="c-i-s-b mt-30">
						<a href="https://m.marlomba.ru" className="d-f-a-c">
							<img src="img/icon/link.svg" alt="link" />
							<p className="ml-7 profile f-s-14 white">marlomba.ru</p>
						</a>

						<p className="f-s-14 profile white">{dayBirstay}</p>
					</div>
					<div className="mt-15 d-f-w">
						<a href="" className="block-border-2 d-f-a-c f-s-14 white">
							<p className="">{countFrinds.countFriends}</p>
							<span className="ml-5">Друзей</span>
						</a>
						<a href="" className="block-border-2 d-f-a-c f-s-14 white">
							<p className=" ">{countFrinds.countSubscriber}</p>
							<span className="ml-5">Подписчика</span>
						</a>
						<Link to='/photo'>
							<a href="" className="block-border-3 d-f-a-c f-s-14 white">
								<p className="">Фото:</p>
								<span className="ml-5">23</span>
								<img src="img/icon/arrow-right.svg" className="ml-5" alt='' />
							</a>
						</Link>
						{/* Фотки */}
						<div className="over-auto">
							<div className="photo mr-12" onClick={() => setPhotoActive(true)}>
								<img src="../img/png/1.png" alt='' />
							</div>
							<div className="photo mr-12">
								<img src="../img/png/2.png" alt='' />
							</div>
							<div className="photo mr-12">
								<img src="../img/png/3.png" alt='' />
							</div>
							<div className="photo mr-12">
								<img src="../img/png/4.png" alt='' />
							</div>
							<div className="photo">
								<img src="../img/png/5.png" alt='' />
							</div>
						</div>
						{/*  */}
					</div>
				</div>
				<div className="t-a-c ">
					<div className="mt-20 c-i-s-b mb-20 m_block">
						{menuElements.map(menuElement => (
							<div className={menuElement[0] == menuEditor ? "block_3 activess" : "block_3 no-activess "}>
								<img key={menuElement[0]}
									src={menuElement[1]}
									onClick={() => setMenuEditor(menuElement[0])}
									alt={menuElement[0]}
								/>
							</div>
						))}
					</div>
				</div>
				{menuEditor == "Посты" && <PostMenu />}
				{menuEditor == "Изображения" && <ImagesMenu />}
				{/* {menuEditor == "Музыка" && <MusicMenu />}
                {menuEditor == "Видео" && <VideoMenu />}
                {menuEditor == "Покупки" && <ShopsMenu />}
                {menuEditor == "Код" && <CodeMenu />} */}
				{menuEditor == "Закладки" && <SaveMenu />}
				{/*  */}


				{/* Добавить запись
				<div className="m_block mt-10">
					<div className="input-add-group">
						<div className="input-add-wrap">
							<img className="adding-post-icon" src={ !activeButtonPost ? "./img/icon/add-post.svg" : "./img/icon/add-post__active.svg"}
                            alt="" onClick={e => addPost()} />
							<input className="add-post" placeholder="Добавить запись" type="text"
                            value={postText} onChange={e => setPostText(e.target.value)} />
							<div className="input-add-content">
								<img src="./img/icon/music-post.svg" alt="music:icon" />
								<img src="./img/icon/video-post.svg" alt="video:icon" />
								<label htmlFor="file-loader-button" style={{
									display: "flex"
								}}
								>
									<img src="./img/icon/image-post.svg" alt="photo:icon" />
								</label>
                                <input id="file-loader-button" type="file" onChange={e => handleOnChange(e)}
								style={{
									display: "none"
								}} />

							</div>
						</div>
					</div>
				</div> */}

				{/*  */}

				{/* Выборка показа контента */}
				{/* Сам пост */}

				{/* {menuEditor === "Посты" &&
					userPost.map(post => (
						<MenuPostsFunctionComponent
							key={post.id}
							activeType="Что то сделла в 21:12" UserAva="../img/png/1.png"
							userID="1" userName="Иван Иванов"
                            delPost={delPhotoFun}
                            idPost={post.id}
						>
							{post.text}
							{post.img ?
								<ImgPost scr={post.img} /> :
								"Фото нет"	}
						</MenuPostsFunctionComponent>
					)
					)
				} */}
				{menuEditor === "Музыка" && <MenuMusic />}
				{menuEditor === "Группы" && <MenuGroup />}
				{menuEditor === "Лайкнутые" && <MenuLike />}


			</div>
			{/*  */}

			{/* Модальное окна */}
			<Modal active={modalActive} setActive={setModalActive}>
				<div className="no-back_block">
					<div className="t-a-c" onClick={() => setModalActive(false)}>
						<img src="img/icon/close3.svg" alt="" />
					</div>
					<div className="mt-30 mb-10 btn-2 t-a-c c-i" onClick={() => setPhotoActive(true)}>
						<img src="../img/icon/photo_little.svg" alt='' />
						<p className="ml-10 white-s-16">Открыть фотку</p>
					</div>
					<label htmlFor="files" className="ml-10 white-s-16 mt-10 mb-10 btn-2 t-a-c c-i">
						<img src="../img/icon/update.svg" alt='' />
						<p className="ml-10 white-s-16">Обновить аву</p>
						<input type="file" id="files" hidden onChange={createAva} />
					</label>
					<div className=" mt-10 mb-10 btn-2-red t-a-c c-i" onClick={() => setDelPhoto(true)}>
						<img src="../img/icon/delete.svg" alt='' />
						<p className="ml-10 white f-s-16">Удалить аву</p>
					</div>
				</div>
			</Modal>
			<Modal active={statusActive} setActive={setStatusActive} value={userPageStatus} on>
				<div className="no-back_block">
					<div className="t-a-c" onClick={() => setStatusActive(false)}>
						<img src="img/icon/close3.svg" alt="" />
					</div>
					<input type="text" placeholder={userStatusInModal} className=" mt-30 inp-txt f-s-16 c-gray" onChange={e => setUserStatusInModal(e.target.value)} />
					<div className="mt-20 mb-10 btn-2-red t-a-c c-i" onClick={e => setStatusInUser(e)}>
						<img src="../img/icon/update.svg" alt='' />
						<p className="ml-10 white-s-16">Обновить статус</p>
					</div>
				</div>
			</Modal>


			<Modal active={modalLogIn} setActive={setModalLogIn} on>
				<div className="no-back_block">
					<div className="mt-20 mb-10 btn-2-red t-a-c c-i" onClick={e => setModalLogIn(false)}>
						<p className="ml-10 white-s-16">Сессия устарела</p>
					</div>
				</div>
			</Modal>

			<Error active={errors} setActive={setErrors}>
				{ErrorInfo}
			</Error>


			<ModalPhoto active={photoActive} setActive={setPhotoActive} className="ModalPhoto_main"></ModalPhoto>

			<Modal active={DelPhoto} setActive={setDelPhoto}>
				<div className="no-back_block">
					<div className="t-a-c" onClick={() => setDelPhoto(false)}>
						<img src="img/icon/close3.svg" alt="" />
					</div>
					<p className="mt-30 t-a-c white-s-16">Ты реально хочешь удалить эту балдежную аву? <br /> Может подумаешь?</p>
					<div className="c-i-s-b mt-30 mb-10">
						<div className="btn-2 t-a-c c-i">
							<img src="../img/icon/otmena.svg" alt='Отмена' />
							<p className="ml-10 white-s-16" onClick={() => setDelPhoto(false)}>Лан, оставлю</p>
						</div>
						<div className="btn-2-red t-a-c c-i">
							<img src="../img/icon/delete.svg" alt='Удалить' />
							<p className="ml-10 white-s-16">Удалить</p>
						</div>
					</div>
				</div>
			</Modal>
			{/*  */}

			{/* меню */}
			<MenuReactPrivate />
		</div>
	)

}
