import React, { useState, useEffect } from "react";
import Modal from '../../components/Modal/Modal';
import LoginService from "../../API/LoginService";
import { Link } from "react-router-dom";
import ChatAll from "../../components/Chat/ChatAll";
import ChatLS from "../../components/Chat/ChatLS";
import ChatGroup from "../../components/Chat/ChatGroup";
import MenuReactPrivate from '../../components/UI/menu/MenuReactPrivate'

export default function PrivateZoneSmsPage() {

    const [menuEditor, setMenuEditor] = useState("Всё");
    const menuElements = ['Всё', 'ЛС', 'Группы', 'Дизайн'];

    const [modalLogIn, setModalLogIn] = useState(false);

    async function checkToken() {
        try {
            await LoginService.checkToken(localStorage.getItem("tokenUser"), localStorage.getItem("userID"))
                .then()
                .catch(function (error) {
                    if (error.response.data.response === 401) {
                        setModalLogIn(true);
                        localStorage.clear();
                        window.location.reload()
                    }
                })
                .then(function () {

                })
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        checkToken()
        /* setInterval(() => checkToken(), 12000); */
    }, []);

    return (
        <div className="c-background">
            {/* Шапка */}
            <div className="no-back_block c-i-s-b ">
                <Link to="/home">
                    <div className="other_ava">
                        <img src="../img/png/1.png" width="100%" alt='' />
                    </div>
                </Link>
                <div className="c-gray f-s-16">
                    <p>Сообщения</p>
                </div>
                <div className="search-i-friend">
                    <img src="img/icon/search-2.svg" alt='' />
                </div>
            </div>
            {/*  */}

            <hr className="hr" />

            {/* Контент */}
            {/* Функционал показа контента */}
            <div className="sort-main-group no-back_block">
                {menuElements.map(menuElement => (
                    <a key={menuElement}
                        className={menuElement == menuEditor ? "sort-item-active txt_c_online ml-10 mr-10 f-s-16" :
                            "ml-10 mr-10 txt_c_online f-s-16 sort-item"}
                        onClick={() => setMenuEditor(menuElement)}>{menuElement}</a>
                ))}
            </div>
            <hr className="hr" />
            {/*  */}

            {/* Выборка показа контента */}
            {/* Сам пост */}
            <div className="chats-all">
                {menuEditor === "Всё" && <ChatAll />}
                {menuEditor === "ЛС" && <ChatLS />}
                {menuEditor === "Группы" && <ChatGroup />}
                {menuEditor === "Дизайн" && <ChatGroup />}
            </div>
            {/*  */}


            {/* меню */}
            <MenuReactPrivate />

            <Modal active={modalLogIn} setActive={setModalLogIn} on>
                <div className="no-back_block">
                    <div className="mt-20 mb-10 btn-2-red t-a-c c-i" onClick={e => setModalLogIn(false)}>
                        <p className="ml-10 white-s-16">Сессия устарела</p>
                    </div>
                </div>
            </Modal>
        </div>
    )

}
