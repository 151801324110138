import API_URL from '../config/main';
import axios from "axios";
/*
Класс, который отвечает за регистрацию
*/

const headers = {
    'Content-type' : 'application/x-www-form-urlencoded',
}


export default class StaticService {

    // Получение статической страницы

    static async staticDockument (document_uuid, lang){

        return await axios.get(`https://dev.api.marlomba.ru/api/document/` +
                    document_uuid + '/' + lang);

    }




}
