import React from 'react';
import "./Modal.css";

const ModalShare = ({ active, setActive }) => {
    return (
        <div className={active ? "modelComment active " : "modelComment"} onClick={() => setActive(false)}>
            <div className="modelComment_block" onClick={e => e.stopPropagation()}>
                <div className='block '>
                    <header className='c-i '>
                        <img src="img/icon/down.svg" onClick={() => setActive(false)} alt="" />
                    </header>
                    <div className=''>
                        <div className="d-f-a-c block_2-hover">
                            <div className="post_ava w-20">
                                <img src="../img/png/1.png" alt="" />
                            </div>
                            <div className='ml-10 w-80'>
                                <div className='d-f-a-c'>
                                    <p className='f-s-14 white'>User white</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-f-a-c block_2-hover">
                            <div className="post_ava w-20">
                                <img src="../img/png/1.png" alt="" />
                            </div>
                            <div className='ml-10 w-80'>
                                <div className='d-f-a-c'>
                                    <p className='f-s-14 white'>User Name</p>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    )
}


export default ModalShare;