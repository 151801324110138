import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Modal from '../../components/Modal/Modal';
import ModalNotification from '../../components/Modal/ModaNotification';
import ModalPhoto from '../../components/Modal/ModalPhoto';
import MenuPosts from "../../components/Home/MenuPosts";
import MenuMusic from "../../components/Home/MenuMusic";
import MenuGroup from "../../components/Home/MenuGroup";
import MenuLike from "../../components/Home/MenuLike";
import ModalAddFriend from "../../components/Modal/ModalAddFriend";
import ModalDelFriend from "../../components/Modal/ModalDelFriend";
import ModalCopy from "../../components/Modal/ModalCopy";
import ModalAnalytics from "../../components/Modal/ModalAnalytics";

import MenuReactPrivate from "../../components/UI/menu/MenuReactPrivate";


import LoginService from "../../API/LoginService";
import useReques from "../../hooks/useReques";
import ProfileServise from "../../API/ProfileServise";
//import useTimerModal from "../../hooks/useTimerModal";

export default function UserProfile() {
    let navigate = useNavigate();
    let { userID } = useParams();

    const [photoActive, setPhotoActive] = useState(false);
    const [addFriend, setAddFriend] = useState(false);
    const [delFriend, setDelFriend] = useState(false);
    const [copy, setCopy] = useState(false);
    const [analytics, setAnalytics] = useState(false);
    const [modalNotifActive, setNotifActive] = useState(false);
    const [menuEditor, setMenuEditor] = useState("Посты");
    const menuElements = ['Посты', 'Музыка', 'Группы', 'Лайкнутые'];


    const [userPageInfo, setUserPageInfo] = useState({})

    const [modalLogIn, setModalLogIn] = useState(false)

    async function checkToken() {
        try {
            await LoginService.checkToken(localStorage.getItem("tokenUser"), localStorage.getItem("userID"))
                .then()
                .catch(function (error) {
                    if (error.response.data.response === 401) {
                        setModalLogIn(true);
                        localStorage.clear();
                        window.location.reload()
                    }
                })
                .then(function () {

                })
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        checkToken()
        setInterval(() => checkToken(), 12000);
    }, []);


    const timerIconsAddFriend = () => {
        setAddFriend(true)
        setInterval(() => setAddFriend(false), 2500);
    }

    const timerIconsCopyLink = () => {
        setCopy(true)
        setInterval(() => setCopy(false), 3500);
    }

    const timerIconsUnFriend = () => {
        setDelFriend(true)
        setInterval(() => setDelFriend(false), 2500);
    }

    useEffect(() => {
        if (localStorage.getItem('userID') === userID) {
            navigate("/home");
        }
    }, []);

    const [fetchingProfile, modalLoading, errorLogin] = useReques(async (session_key, id) => {
        const response = await ProfileServise.getProfile(session_key, id)
        //console.log(response)
        setUserPageInfo(response.data)
    })

    useEffect(() => {
        //console.log(localStorage.getItem("tokenUser"))
        fetchingProfile(localStorage.getItem("tokenUser"), userID)
        isLoafetchingCount()
    }, []);

    //const modalAnalitiscs = useTimerModal()

    // Получение информации о количестве друзей и подписчиков пользователя
    const [countFrinds, setCountFrinds] = useState({
        countFriends: 0,
        countSubscriber: 0
    })

    const [isLoafetchingCount, dingCount, errorCount] = useReques(async () => {
		const response = await ProfileServise.getCountFriend(localStorage.getItem('tokenUser'), userID)
		//console.log(response)
		//setUserPost(response.data)
		//return response.data

        setCountFrinds({
            ...countFrinds,
            countFriends: response.data.countFriends,
            countSubscriber: response.data.countSubscriber,
        })
	})




    return (
        <div className="aniim">
            <div className="animation-home">
                <div className="m_block c-i-s-b">
                    <Link to="/MainSettings">
                        <div className="icon">
                            <img src="img/icon/setting.svg" />
                        </div>
                    </Link>
                    <div className="c-gray f-s-16">
                        <p>ID {userID}</p>
                    </div>
                    <div className="icon" onClick={() => setNotifActive(true)}>
                        <img src="img/icon/notification.svg" />
                    </div>

                    <ModalNotification active={modalNotifActive} setActive={setNotifActive}></ModalNotification>
                </div>
                {/*  */}

                {/* Основная информация о пользователе */}
                <div className="m_block">

                    {
                        userPageInfo ? (
                            <div className="back_photo_ava mt-10">
                                <img src={"https://dev.api.marlomba.ru/static/img/"+userPageInfo.backAvatar}
                                width="100%" alt='' />
                            </div>
                        ) : (
                            <div className="back_photo_ava mt-10">
                                <img src="../img/png/1.png" width="100%" alt='' />
                            </div>
                        )
                    }

                    {
                        userPageInfo ? (
                            <div className="ava_profil">
                                <img src={"https://dev.api.marlomba.ru/static/img/"+userPageInfo.avatar}
                                width="100%" alt='' />
                            </div>
                        ) : (
                            <div className="ava_profil">
                                <img src="../img/png/1.png" width="100%" alt='' />
                            </div>
                        )
                    }
                    <div className="online" ></div>

                    <div className="txt">

                        <p className="name d-f-a-c f-s-20">{userPageInfo.name + " " + userPageInfo.lastname}
                        {
                            // Вывод галочки верефикации
                            userPageInfo.verifed == 1 && (
                                <img src="img/icon/check.svg" className="ml-10" alt='' />
                            )
                        }
                        {
                            // Вывод значка тестира
                            userPageInfo.alfa_tester && (
                                <img src="img/icon/magic-star.svg" className="ml-10" alt='' />
                            )
                        }
                        </p>
                        <span className="txt_c_online f-s-16">
                            {
                            userPageInfo.status ?  userPageInfo.status : ""
                            }</span>

                    </div>
                    <div className="status mt-10 d-f-a-c">
                        <p className="txt_c_online f-s-16">{userPageInfo.statusPage}</p>
                    </div>
                    <div className="block_friend_and_db mt-10 c-i-s-b">
                        <div className="d-f-a-c">
                            <a href="" className="d-f-a-c f-s-16">
                                <p className="red">{countFrinds.countFriends}</p>
                                <span className="ml-5 txt_c_online">друга</span>
                            </a>
                            <a href="" className=" ml-20 d-f-a-c f-s-16">
                                <p className="red">{countFrinds.countSubscriber}</p>
                                <span className="ml-5 txt_c_online ">подписчика</span>
                            </a>
                        </div>
                        <p className="txt_c_online f-s-13">

                        </p>
                    </div>
                    <div className="mt-10 d-f-a-c">
                        <a href="https://m.marlomba.ru" className="d-f-a-c">
                            <img src="img/icon/link.svg" alt="" />
                            <p className="ml-10 red">marlomba.ru</p>
                        </a>
                    </div>
                </div>
                {/*  */}

                {/* Меню, которое будет показываться, когда будешь заходить на другой профиль */}
                <div className="mt-10 no-back_block c-i-s-b ">
                    <Link to="/sms">
                        <img src="img/icon/chat-link.svg" alt='' />
                    </Link>
                    <div onClick={() => timerIconsAddFriend()}>
                        <img src="img/icon/add.svg" alt='' />
                    </div>
                    <ModalAddFriend active={addFriend} setActive={setAddFriend}></ModalAddFriend>

                    <div>
                        <img src="img/icon/analitics.svg" alt='' />
                    </div>
                    <ModalAnalytics active={analytics} setActive={setAnalytics}></ModalAnalytics>

                    <div onClick={() => timerIconsCopyLink()}>
                        <img src="img/icon/copy.svg" alt='' />
                    </div>
                    <ModalCopy active={copy} setActive={setCopy}></ModalCopy>

                    <div onClick={() => timerIconsUnFriend()}>
                        <img src="img/icon/del_friend.svg" alt='' />
                    </div>
                    <ModalDelFriend active={delFriend} setActive={setDelFriend}></ModalDelFriend>

                </div>
                {/*  */}

                {/* Фотки */}
                <div className="m_block mt-10">
                    <a href="photo.html" className="c-i-s-b">
                        <p className="white-s-14">Фото: 23</p>
                        <img src="img/icon/left.svg" alt='' />
                    </a>
                    <div className="over-auto mt-20">
                        <div className="photo mr-10" onClick={() => setPhotoActive(true)}>
                            <img src="../img/png/1.png" alt='' />
                        </div>
                        <div className="photo mr-10">
                            <img src="../img/png/2.png" alt='' />
                        </div>
                        <div className="photo mr-10">
                            <img src="../img/png/3.png" alt='' />
                        </div>
                        <div className="photo mr-10">
                            <img src="../img/png/4.png" alt='' />
                        </div>
                        <div className="photo mr-10">
                            <img src="../img/png/5.png" alt='' />
                        </div>
                    </div>
                    {/*  */}

                    {/* Функционал показа контента */}
                    <div className="m_block mt-10 c-i f-s-16">
                        {menuElements.map(menuElement => (
                            <a key={menuElement}
                                className={menuElement == menuEditor ? "ml-10 mr-10 red" : "ml-10 mr-10 txt_c_online"}
                                onClick={() => setMenuEditor(menuElement)}>{menuElement}</a>
                        ))}
                    </div>
                    {/*  */}

                    {/* Выборка показа контента */}
                    {/* Сам пост */}

                    {menuEditor === "Посты" && <MenuPosts />}
                    {menuEditor === "Музыка" && <MenuMusic />}
                    {menuEditor === "Группы" && <MenuGroup />}
                    {menuEditor === "Лайкнутые" && <MenuLike />}

                </div>
                {/*  */}

                {/* Модальное окна */}
                <ModalPhoto active={photoActive} setActive={setPhotoActive} className="ModalPhoto_main"></ModalPhoto>

                <Modal active={modalLogIn} setActive={setModalLogIn} on>
                    <div className="no-back_block">
                        <div className="mt-20 mb-10 btn-2-red t-a-c c-i" onClick={e => setModalLogIn(false)}>
                            <p className="ml-10 white-s-16">Сессия устарела</p>
                        </div>
                    </div>
                </Modal>
                {/*  */}
                {/*  */}
                <Modal active={errorLogin} setActive={e => { window.history.go(-1); return false; }} on>
                    <div className="no-back_block">
                        <div className="mt-20 mb-10 btn-2-red t-a-c c-i" onClick={e => setModalLogIn(false)}>
                            <p className="ml-10 white-s-16">Профиль не найден</p>
                        </div>
                    </div>
                </Modal>

                {/* меню */}
                <MenuReactPrivate />
            </div>
        </div>
    )

}
