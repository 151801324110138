import React from 'react';
import "./Modal.css";

const ModalDelFriend = ({ active, setActive }) => {
    return (
        <div id='achives' className={active ? "modelOptions active" : "modelOptions"} onClick={() => setActive(false)}>
            <div className="block_options" onClick={e => e.stopPropagation()}>
                <div className="no-back_block c-i">
                    <p className='t-a-c ml-10 f-s-16 white-w-500'>Друг удален</p>
                </div>
            </div>
        </div>
    )
}

export default ModalDelFriend;