import React, { useState } from "react";
import ModalPhoto from '../Modal/ModalPhoto';
import ModalOptionsPost from '../Modal/ModalOptionsPost';
import ModalComment from '../Modal/ModalComment';
import ModalShare from '../Modal/ModalShare'

export default function SaveMenu() {

    const [photoActive, setPhotoActive] = useState(false);
    const [commentActive, setCommentActive] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [shareActive, setShareActive] = useState(false)

    const [likedActive, setLikedActive] = useState(false)
    const [likePost, setLikePost] = useState(0)
    const [saveBookmark, satSaveBookmarks] = useState(false)

    function likedPost() {
        likedActive ? setLikedActive(false) : setLikedActive(true)

        likedActive ? setLikePost(likePost - 1) : setLikePost(likePost + 1)

        //console.log(likedActive)
    }

    function fSaveBookmarks() {
        saveBookmark ? satSaveBookmarks(false) : satSaveBookmarks(true)

        //console.log(saveBookmark)
    }
    return (
        <div>

            <div className="c-i-s-b header-news">
                <div className="d-f-a-c">
                    <div className="post_ava">
                        <img src="../img/logo/logo.png" />
                    </div>
                    <div className="ml-12 txt2">
                        <p className="white d-f-a-c f-s-14">{localStorage.getItem('userName')} {localStorage.getItem('userLastname')}
                            <img src="img/icon/magic-star.svg" className="ml-7" alt='' />
                        </p>
                        <span className="mt-10 f-s-12 c-gray">Обновил фото сегодня в 12:10</span>
                    </div>
                </div>
                <div className="options" onClick={() => setModalActive(true)}>
                    <img src="img/icon/options.svg" />
                </div>
                <ModalOptionsPost active={modalActive} setActive={setModalActive}></ModalOptionsPost>
            </div>
            <div className="post_photo ">
                <img src="../img/logo/logo.png" onClick={() => setPhotoActive(true)} />
            </div>
            <div className="post-content">
                <h2 className="white f-w-500 f-s-16">20 дней до главного события!</h2>
                <p className="white f-s-14 mt-10 l-h-145">Именно столько дней остается до главного события нашей команды – Marlo. Это тот день, к которому мы так долго шли, делая наше приложение более удобным и инновационным именно для тебя.</p>
            </div>
            <div className="c-i-s-b block-action">
                <div className='d-f-a-c'>
                    <div className={likedActive ? 'd-f-a-c likes-post-act  border-like ' : 'd-f-a-c likes-post border-like'} onClick={() => likedPost()}>
                        <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M3.70893 11.5901L9.50278 17.0328C9.64793 17.1691 9.7205 17.2373 9.79942 17.2719C9.9273 17.3279 10.0728 17.3279 10.2007 17.2719C10.2796 17.2373 10.3522 17.1691 10.4973 17.0328L16.2911 11.5901C17.9213 10.0587 18.1192 7.53875 16.7482 5.77164L16.4904 5.43937C14.8503 3.3254 11.5581 3.67993 10.4056 6.09463C10.2428 6.43572 9.75728 6.43572 9.59448 6.09463C8.44202 3.67993 5.14981 3.3254 3.50966 5.43937L3.25186 5.77164C1.88083 7.53875 2.07879 10.0587 3.70893 11.5901Z' />
                        </svg>
                        <p className='white ml-5 f-s-16'>{likePost}</p>
                    </div>
                    <div className='ml-10 d-f-a-c border-like' onClick={() => setCommentActive(true)}>
                        <img src="img/icon/comment.svg" />
                        <p className='ml-5 white f-s-16'>140</p>
                    </div>
                    <div className='ml-10 d-f-a-c  border-like' onClick={() => setShareActive(true)}>
                        <img src='img/icon/share.svg' />
                        <p className='ml-5 white f-s-16'>12</p>
                    </div>
                </div>
                <div className='d-f-a-c'>
                    {/* <div className='ml-10 d-f-a-c border-like'>
                        <img src="img/icon/eyes-post.svg"/>
                        <p className='ml-5 white f-s-16'>233</p>
                    </div> */}
                    <div className='ml-10 d-f-a-c'>
                        <div className={
                            saveBookmark
                                ? 'ml-10 d-f-a-c save-post-act'
                                : 'ml-10 d-f-a-c save-post'
                        }
                            onClick={() => fSaveBookmarks()}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 11.4004H9.5C9.09 11.4004 8.75 11.0604 8.75 10.6504C8.75 10.2404 9.09 9.90039 9.5 9.90039H14.5C14.91 9.90039 15.25 10.2404 15.25 10.6504C15.25 11.0604 14.91 11.4004 14.5 11.4004Z" />
                                <path d="M12 13.96C11.59 13.96 11.25 13.62 11.25 13.21V8.20996C11.25 7.79996 11.59 7.45996 12 7.45996C12.41 7.45996 12.75 7.79996 12.75 8.20996V13.21C12.75 13.62 12.41 13.96 12 13.96Z" />
                                <path d="M19.0703 22.75C18.5603 22.75 18.0003 22.6 17.4603 22.29L12.5803 19.58C12.2903 19.42 11.7203 19.42 11.4303 19.58L6.55031 22.29C5.56031 22.84 4.55031 22.9 3.78031 22.44C3.01031 21.99 2.57031 21.08 2.57031 19.95V5.86C2.57031 3.32 4.64031 1.25 7.18031 1.25H16.8303C19.3703 1.25 21.4403 3.32 21.4403 5.86V19.95C21.4403 21.08 21.0003 21.99 20.2303 22.44C19.8803 22.65 19.4803 22.75 19.0703 22.75ZM12.0003 17.96C12.4703 17.96 12.9303 18.06 13.3003 18.27L18.1803 20.98C18.6903 21.27 19.1603 21.33 19.4603 21.15C19.7603 20.97 19.9303 20.54 19.9303 19.95V5.86C19.9303 4.15 18.5303 2.75 16.8203 2.75H7.18031C5.47031 2.75 4.07031 4.15 4.07031 5.86V19.95C4.07031 20.54 4.24031 20.98 4.54031 21.15C4.84031 21.32 5.31031 21.27 5.82031 20.98L10.7003 18.27C11.0703 18.06 11.5303 17.96 12.0003 17.96Z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>


            {/* Модальное окно */}
            <ModalComment active={commentActive} setActive={setCommentActive}></ModalComment>
            {/* <ModalPhoto active={photoActive} setActive={setPhotoActive}></ModalPhoto> */}
            <ModalShare active={shareActive} setActive={setShareActive}></ModalShare>
            {/*  */}
        </div>
    )

}