import API_URL from '../config/main';
import axios from "axios";
/*
Класс, который отвечает за регистрацию
*/

const URL_API = "https://dev.api.marlomba.ru/"
// localhost:3001/api/register/1
export default class RegisterServise {


    // Получение нового токена восстановления регистрации
    static async getTokenNew (name, lastname, date_birsday, sex){

        let newDate = new Date(date_birsday);

        const dasdas = parseInt(newDate.getUTCMonth())+1

        const response = await axios.post(`https://dev.api.marlomba.ru/api/register/1`, {
            name,
            lastname,
            birdh:  newDate.getFullYear() + "-" + dasdas + "-" + newDate.getDate(),
            sex: '' + sex,
        })

        return response

    }

    static async setPhone (tocken, phone){

        const response = await axios.post(`https://dev.api.marlomba.ru/api/register/2`, {
            tel:phone,
            code_reg:tocken
        })

        return response

    }

    static async setEmail (code_reg, email){

        const response = await axios.post(`https://dev.api.marlomba.ru/api/register/3`, {
            email,
            code_reg
        })

        return response

    }

    static async setCodeEmail(code_reg, code_email){

        const response = await axios.get(`https://dev.api.marlomba.ru/api/register/EmailCodeGet/`+code_reg+`/`+code_email)

        return response

    }

    static async sendPassword(code_reg, password, FINGERPRINT){

        const response = await axios.post(`https://dev.api.marlomba.ru/api/register/4`, {
            password,
            code_reg,
            FINGERPRINT,
        })

        return response

    }


}