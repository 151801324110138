import React, { useEffect, useState } from 'react';
import "./Modal.css";

/*
// nameModal = принимает значение, название модального окна, которое видит пользователь
*/
const ModalNotification = ({ active, setActive, nameModle }) => {

    return (
        <div className={active ? "optionsPost active" : "optionsPost"} onClick={() => setActive(false)}>
            <div className='notification' onClick={e => e.stopPropagation()}>
                <div className="t-a-c">
                    <div className="white-s-16">
                        <p>Уведомления</p>
                    </div>
                </div>
                <div>
                    <p className='white-s-14 mt-30'>От юзеров:</p>
                    <div className='mt-10 block_2-hover d-f-a-c'>
                        <div className='d-f-a-c ava-friends'>
                            <img src="img/png/2.png" alt="" />

                        </div>
                        <div className='likes_notif'>
                            <img src="img/icon/likes_notif.svg" alt="" />
                        </div>
                        <div className='ml-15'>
                            <p className='white-s-16 '>Test User</p>
                            <span className='txt_c_online f-s-14'>Лайкнул твою аву</span>
                        </div>
                    </div>
                    <div className='mt-10 block_2-hover d-f-a-c'>
                        <div className='d-f-a-c ava-friends'>
                            <img src="img/png/2.png" alt="" />
                        </div>
                        <div className='likes_notif'>
                            <img src="img/icon/chat_notif.svg" alt="" />
                        </div>
                        <div className='ml-15'>
                            <p className='white-s-16 '>Test User</p>
                            <span className='txt_c_online f-s-14'>Оставил новый коммент</span>
                        </div>
                    </div>
                    <div className='mt-10 block_2-hover d-f-a-c'>
                        <div className='d-f-a-c ava-friends'>
                            <img src="img/png/2.png" alt="" />

                        </div>
                        <div className='likes_notif'>
                            <img src="img/icon/add_notif.svg" alt="" />
                        </div>
                        <div className='ml-15'>
                            <p className='white-s-16 '>Test User</p>
                            <span className='txt_c_online f-s-14'>Ого, кто-то хочет быть твоим другом</span>
                        </div>
                    </div>
                    {/*  */}
                    <p className='white-s-14 mt-30'>От Групп:</p>
                    <div className='mt-10 block_2-hover d-f-a-c'>
                        <div className='d-f-a-c ava-friends'>
                            <img src="img/png/5.png" alt="" />

                        </div>
                        <div className='likes_notif'>
                            <img src="img/icon/replace_notif.svg" alt="" />
                        </div>
                        <div className='ml-15'>
                            <p className='white-s-16 '>Овсянка, Сэр!</p>
                            <span className='txt_c_online f-s-16'>Ответили на ваш коммент</span>
                        </div>
                    </div>

                </div>
                <div className='t-a-c mt-30'>
                    <img src="img/icon/down.svg" className='rotate' onClick={() => setActive(false)} />
                </div>
            </div>

        </div>
    )
}


export default ModalNotification;