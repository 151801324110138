import API_URL from '../config/main';
import axios from "axios";
/*
Класс, который отвечает за вход
*/

const headers = {
    'Content-type': 'application/x-www-form-urlencoded',
}

export default class ProfileServise {

    static async setStatus(token, status) {
        // Изменение статуса пользователя

        const response = await axios.post(`https://dev.api.marlomba.ru/api/user/changeStatus`, {
            token,
            status,
        })
        return response
    }

    // Получение информации по профилю человека
    static async getProfile(tokenUser, id_user) {
        const response = await axios.get('https://dev.api.marlomba.ru/api/user/'+tokenUser+'/'+id_user)
        return response
    }

    // Получение количества друзей и подписчиков пользователя
    static async getCountFriend(token, userID) {
        const response = await axios.post(`https://dev.api.marlomba.ru/api/user/subscriber`, {
            token,
            userID: parseInt(userID),
        })
        return response
    }

    static async getPostProfile(idUser, idUserToken, session_key, limit, offset) {
        const response = await axios.post('https://dev.api.marlomba.ru/api/user/user_post.php', JSON.stringify({
            id: "1",
            id_user: "200",
            session_key: "v0pqstu4ajtrohkbv",
            limit: "2",
            offset: "2",
            type: "show"
        }), {
            headers: headers
        })
        return response
    }




}
