import React, { useState } from 'react';
import "./Modal.css";
import ModalEditText from '../../components/Modal/ModalEditText';
import ModalArchive from '../../components/Modal/ModalArchive';
import ModalDelPhoto from '../../components/Modal/Modal';
const ModalOptionsPost = ({ active, setActive, delPost, idPost }) => {
    const [EditActive2, setEditActive2] = useState(false);
    const [archiveActive2, setArchiveActive2] = useState(false);
    const [DelPhoto, setDelPhoto] = useState(false);

    const delPhoto = () => {
        setArchiveActive2(false)
        setEditActive2(false)
        setActive(false)
        setDelPhoto(false)
        console.log("Удаление")
        console.log(delPost(idPost))
    }
    return (
        <div className={active ? "optionsPost active" : "optionsPost"} onClick={() => setActive(false)}>
            <div className="block_options" onClick={e => e.stopPropagation()}>
                <div className="no-back_block">
                    <div className="mt-10 mb-10 btn-2 t-a-c c-i" onClick={() => setEditActive2(true)}>
                        <img src="../img/icon/edit-options.svg" alt="" />
                        <p className="ml-10 white-s-16">Редактировать</p>
                    </div>
                    <div className="mt-10 mb-10 btn-2 t-a-c c-i" onClick={() => setArchiveActive2(true)} >
                        <img src="../img/icon/archive.svg" alt="" />
                        <p className="ml-10 white-s-16">Архивировать</p>
                    </div>
                    <div className=" mt-10 mb-10 btn-2-red t-a-c c-i" onClick={() => setDelPhoto(true)}>
                        <img src="../img/icon/delete.svg" alt="" />
                        <p className="ml-10 white f-s-16">Удалить</p>
                    </div>
                </div>
                <ModalEditText active={EditActive2} setActive={setEditActive2}></ModalEditText>
                <ModalArchive active={archiveActive2} setActive={setArchiveActive2}></ModalArchive>
                <ModalDelPhoto active={DelPhoto} setActive={setDelPhoto}>
                    <div className="no-back_block">
                        <p className="mt-10 t-a-c white-s-16">Ты реально хочешь удалить эту балдежную фотку? <br /> Может подумаешь?</p>
                        <div className="mt-30 mb-10 btn-2-red t-a-c c-i" onClick={e => delPhoto(e)}>
                            <img src="../img/icon/delete.svg" alt="" />
                            <p className="ml-10 white-s-16">Удалить</p>
                        </div>
                        <div className="mt-20 mb-10 btn-2 t-a-c c-i">
                            <p className="ml-10 white-s-16" onClick={() => setDelPhoto(false)}>Лан, оставлю</p>
                        </div>
                    </div>
                </ModalDelPhoto>
            </div>
        </div>
    )


}


export default ModalOptionsPost;
