import React, { useState, useEffect, useRef } from "react";
import ButtonSocial from "../../components/Free_zone/UI/buttonSocial";
import ComandaList from "../../components/Free_zone/UI/comandaList";
import NewModal from "../../components/Modal/newModal";
import FreeZoneComponentPassword from "../../components/Free_zone/UI/input/input_password";
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { useFormik } from 'formik';
import loginSchema from '../../yup/index.js';
import LoginService from '../../API/LoginService'
import FreeZoneComponentInput from "../../components/Free_zone/UI/input/input";
import Error from "../../components/Modal/Errors";
import { Link } from "react-router-dom";
import restorePasswordService from "../../API/restorePasswordService";
import RegisterServise from "../../API/RegisterService";
import { SmartCaptcha } from '@yandex/smart-captcha';
import { useDispatch } from 'react-redux';
import { setModal, resetModal } from "../../store/slice/uiSlice";

import { emailSchema } from "../../yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const NewIndex = () => {
    /* изменение состояния UI */
    const dispatch = useDispatch();

    const handleModal = (type) => {
        dispatch(setModal({ showModal: true, typeModal: type }));
    };

    const [tokeny, setTokeny] = useState("");

    // Модалка за авторизацию
    const [modalAuth, setModalAuth] = useState(false);

    const [modelBeta, setModelBeta] = useState(false);



    // Модалка за регистрацию
    const [modalRegister, setModalRegister] = useState({
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
    });

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {

        if (!localStorage.getItem("userBeta")) {
            localStorage.setItem("userBeta", getRandomInt(1, 2));
        }

    }, [])


    const handleAlfaRegister = () => {


        localStorage.getItem("userBeta") == 1 ? handleModal('ALFA') : setModelBeta(true)


    }

    const navigate = useNavigate();
    const input = useRef();

    const formikAlfa = useFormik({
        initialValues: {
            email: '',
            checkbox: false,
            tokeny,
        },
        validationSchema: emailSchema,
        onSubmit: async ({ email, tokeny }) => {
            try {
                const response = await axios.post('https://dev.api.marlomba.ru/api/alfa_test', { email, tokeny });

                if (response.status) {
                    setErrorRestoreComment("Письмо отправлено на почту")
                    setErrorRestore(true);
                    formikAlfa.resetForm();
                }
            } catch (error) {
                console.error(error);
                setErrorRestoreComment("Почта уже используется")
                return setErrorRestore(true);
            }
        },
    });


    const handleNavigate = () => {
        navigate('legal/privacy_policy');
        window.location.reload();
    }


    // БЛОК ВОССТАНОВЛЕНИЯ ПАРОЛЯ

    // Модалка за восстанление пароля
    const [modalRestorePassword, setModalRestorePassword] = useState({
        step1: false,
        step2: false,
        step3: false,
    });

    const openModalRestorePassword = () => {

        if (localStorage.getItem("restoreStep") === null) {

            return setModalRestorePassword({ ...modalRestorePassword, step1: true })

        }

        if (localStorage.getItem("restoreStep") == 1) {

            return setModalRestorePassword({ ...modalRestorePassword, step2: true, step1: false })

        }

        if (localStorage.getItem("restoreStep") == 2) {

            return setModalRestorePassword({ ...modalRestorePassword, step2: false, step3: true })

        }

    }

    const closeModalRestorePassword = () => {

        for (var key in modalRestorePassword) {

            if (modalRestorePassword[key] === true) {

                let dada = { ...modalRestorePassword }

                dada[key] = false;
                setModalRestorePassword({ ...dada })

            }

        }

    }

    const [restoreEmail, setRestoreEmail] = useState("");

    const [errorEmail, setErrorEmail] = useState(false);

    const restoreStepOneSend = () => {
        restorePasswordService.getTokenNew(restoreEmail)
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem("restoreStep", 1);
                    localStorage.setItem("tokenRestore", res.data.token);
                    closeModalRestorePassword();
                    openModalRestorePassword();
                }
            })
            .catch(err => {
                if (err.response) {
                    setErrorEmail(true)
                } else if (err.request) {
                    setErrorEmail(true)
                } else {
                    setErrorEmail(true)
                }
            })
    }

    const [codeEmail, setCodeEmail] = useState(null);
    const [errorCodeEmail, setErrorCodeEmail] = useState(false)

    const restoreStepTwoSend = () => {
        restorePasswordService.setEmailCode(localStorage.getItem("tokenRestore"), codeEmail)
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem("restoreStep", 2);
                    closeModalRestorePassword();
                    openModalRestorePassword();
                }
            })
            .catch(err => {
                if (err.response) {
                    setErrorCodeEmail(true)
                } else if (err.request) {
                    setErrorCodeEmail(true)
                } else {
                    setErrorCodeEmail(true)
                }
            })
    }

    const [passwordRestoreOne, setPasswordRestoreOne] = useState(null);
    const [passwordRestoreTwo, setPasswordRestoreTwo] = useState(null);
    const [errorRestore, setErrorRestore] = useState(false);
    const [errorRestoreComment, setErrorRestoreComment] = useState('Произошла ошибка');

    const restoreStepTreeSend = () => {

        if (passwordRestoreOne != passwordRestoreTwo) {
            setErrorRestoreComment("Пароли разные")
            return setErrorRestore(true);
        }

        if (!passwordRestoreOne) {
            setErrorRestoreComment("Пароль пустой")
            return setErrorRestore(true);
        }

        restorePasswordService.setPassword(localStorage.getItem("tokenRestore"), passwordRestoreTwo)
            .then(res => {
                if (res.status === 200) {
                    setErrorRestoreComment("Пароль сброшен")
                    setErrorRestore(true);
                    localStorage.clear();
                    closeModalRestorePassword();
                }
            })
            .catch(err => {
                if (err.response) {
                    setErrorRestoreComment("Ошибка. Повторите сброс пароля ещё раз")
                    setErrorRestore(true);
                } else if (err.request) {
                    setErrorRestoreComment("Ошибка. Повторите сброс пароля ещё раз")
                    setErrorRestore(true);
                } else {
                    setErrorRestoreComment("Ошибка. Повторите сброс пароля ещё раз")
                    setErrorRestore(true);
                }
            })

    }

    // КОНЕЦ - БЛОКА ВОССТАНОВЛЕНИЯ ПАРОЛЯ


    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        validationSchema: loginSchema,
        onSubmit: async ({ login, password }) => {
            try {
                console.log(formik)
                const mes = await LoginService.getTokenNew(login, password, fingerPrint);
                localStorage.setItem('tokenUser', mes.data.session_key);
                localStorage.setItem('userName', mes.data.name);
                localStorage.setItem('userID', mes.data.id);
                localStorage.setItem('userLastname', mes.data.lastname);
                localStorage.getItem('tokenUser') ?
                    window.location.reload()
                    : console.log("Приглашаем к нам. Вакансии опубликуем позже")
            } catch (error) {
                setError(true);
            }

            formik.resetForm();
        }
    });

    useEffect(() => {
        const fpPromise = FingerprintJS.load();

        fpPromise
            .then((fp) => fp.get())
            .then((result) => {
                const visitorID = result.visitorId;
                setFingerPrint(visitorID);
            });
    }, []);

    const [fingerPrint, setFingerPrint] = useState("");
    const [isError, setError] = useState(false);


    const [registerInfo, setRegisterInfo] = useState({
        name: "",
        lastName: "",
        dateBirsday: new Date(),
        sex: 1,
        check: false,
    })



    const openRegiserModal = () => {
        if (localStorage.getItem("regStep") === null) {
            return setModalRegister({ ...modalRegister, step1: true })
        }

        if (localStorage.getItem("regStep") == 1) {
            return setModalRegister({ ...modalRegister, step2: true, step1: false })
        }
        if (localStorage.getItem("regStep") == 2) {
            return setModalRegister({ ...modalRegister, step3: true, step2: false })
        }
        if (localStorage.getItem("regStep") == 3) {
            return setModalRegister({ ...modalRegister, step4: true, step3: false })
        }
        if (localStorage.getItem("regStep") == 4) {
            return setModalRegister({ ...modalRegister, step5: true, step4: false })
        }

    }

    const closeRegisterModal = () => {
        for (var key in modalRegister) {

            if (modalRegister[key] === true) {

                let dada = { ...modalRegister }

                dada[key] = false;
                setModalRegister({ ...dada })

            }

        }
    }



    function calculateAge(start, end) {
        return new Date(end).getYear() - new Date(start).getYear();
    }

    const sendOneStepRegister = () => {
        if (calculateAge(registerInfo.dateBirsday, new Date()) <= 14) {
            setErrorRestoreComment("Регистрация доступна с 14 лет")
            return setErrorRestore(true);
        }

        if (registerInfo.check === false) {
            setErrorRestoreComment("Без юридических формальностей не получиться")
            return setErrorRestore(true);
        }

        if (!registerInfo.name) {
            setErrorRestoreComment("Имя не указано")
            return setErrorRestore(true);
        }

        if (!registerInfo.lastName) {
            setErrorRestoreComment("Фамилия не указана")
            return setErrorRestore(true);
        }

        RegisterServise.getTokenNew(registerInfo.name, registerInfo.lastName, registerInfo.dateBirsday, registerInfo.sex)
            .then(res => {
                if (res.data.response === 200) {
                    localStorage.setItem('regStep', 1)
                    localStorage.setItem('regToken', res.data.code_reg)
                    closeRegisterModal()
                    openRegiserModal()
                }
            })
            .catch(err => {
                console.log(err)
                if (err.response) {
                    setErrorRestoreComment("Ошибка регистрации")
                    setErrorRestore(true);
                } else if (err.request) {
                    setErrorRestoreComment("Ошибка регистрации")
                    setErrorRestore(true);
                } else {
                    setErrorRestoreComment("Ошибка регистрации")
                    setErrorRestore(true);
                }
            })
    }


    const [phoneRegister, setPhoneRegister] = useState('')

    const sendTwoStepRegister = () => {
        if (!phoneRegister) {
            setErrorRestoreComment("Номер телефона пустой")
            return setErrorRestore(true);
        }

        RegisterServise.setPhone(localStorage.getItem("regToken"), phoneRegister)
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem('regStep', 2)
                    closeRegisterModal()
                    openRegiserModal()
                }
            })
            .catch(err => {
                if (err.response) {
                    setErrorRestoreComment("Ошибка. Повторите ещё раз")
                    setErrorRestore(true);
                } else if (err.request) {
                    setErrorRestoreComment("Ошибка. Повторите ещё раз")
                    setErrorRestore(true);
                } else {
                    setErrorRestoreComment("Ошибка. Повторите ещё раз")
                    setErrorRestore(true);
                }
            })


    }

    const [emailRegister, setEmailRegister] = useState('')
    const sendTreeStepRegister = () => {
        if (!emailRegister) {
            setErrorRestoreComment("Почта пустая")
            return setErrorRestore(true);
        }

        RegisterServise.setEmail(localStorage.getItem("regToken"), emailRegister)
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem('regStep', 3)
                    closeRegisterModal()
                    openRegiserModal()
                }
            })
            .catch(err => {
                if (err.response) {
                    setErrorRestoreComment("Ошибка. Почта занята")
                    setErrorRestore(true);
                } else if (err.request) {
                    setErrorRestoreComment("Ошибка. Почта занята")
                    setErrorRestore(true);
                } else {
                    setErrorRestoreComment("Ошибка. Почта занята")
                    setErrorRestore(true);
                }
            })
    }

    const sendThowStepRegister = () => {
        if (!codeEmail) {
            setErrorRestoreComment("Код подтверждения пустой")
            return setErrorRestore(true);
        }

        RegisterServise.setCodeEmail(localStorage.getItem("regToken"), codeEmail)
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem('regStep', 4)
                    closeRegisterModal()
                    openRegiserModal()
                }
            })
            .catch(err => {
                if (err.response) {
                    setErrorRestoreComment("Ошибка. Неверный код")
                    setErrorRestore(true);
                } else if (err.request) {
                    setErrorRestoreComment("Ошибка. Неверный код")
                    setErrorRestore(true);
                } else {
                    setErrorRestoreComment("Ошибка. Неверный код")
                    setErrorRestore(true);
                }
            })
    }

    const sendFiveStepRegister = () => {
        if (passwordRestoreOne != passwordRestoreTwo) {
            setErrorRestoreComment("Пароли разные")
            return setErrorRestore(true);
        }

        if (!passwordRestoreOne) {
            setErrorRestoreComment("Пароль пустой")
            return setErrorRestore(true);
        }

        RegisterServise.sendPassword(localStorage.getItem("regToken"), passwordRestoreTwo, fingerPrint)
            .then(res => {
                if (res.status === 200) {
                    localStorage.clear();
                    closeModalRestorePassword();
                    localStorage.setItem('tokenUser', res.data.session_key)
                    localStorage.setItem('userID', res.data.id)
                    document.location.href = '/';
                }
            })
            .catch(err => {
                if (err.response) {
                    setErrorRestoreComment("Ошибка. Повторите регистрацию позднее")
                    setErrorRestore(true);
                } else if (err.request) {
                    setErrorRestoreComment("Ошибка. Повторите регистрацию позднее")
                    setErrorRestore(true);
                } else {
                    setErrorRestoreComment("Ошибка. Повторите регистрацию позднее")
                    setErrorRestore(true);
                }
            })
    }

    const [buttonCheckAlfa, buttonCheckAlfaSet] = useState(false);

    useEffect(() => {
        //console.log(buttonCheckAlfa)
    }, [buttonCheckAlfa]);

    useEffect(() => {



        if (formikAlfa.values.checkbox && formikAlfa.isValid && tokeny){
            buttonCheckAlfaSet(true)
        }   else {
            buttonCheckAlfaSet(false)
        }

    }, [formikAlfa.values.checkbox, formikAlfa.isValid, tokeny]);


    useEffect(() => {
        formikAlfa.values.tokeny = tokeny;
    }, [tokeny]);


    return (
        <div className="landing">
            <div className="landing-main">
                <div className="landing-title white f-s-26">Marlo — первый мультиэпп <br /> с действительно нужными сервисами</div>
                <div className="landing-subtitle white f-s-16 mt-16">Добро пожаловать на закрытое <br /> альфа-тестирование Marlo</div>

                <div className="landing-button mt-30">
                    <button className="landing-links_auth disabled" disabled>Набор заявок закрыт</button>
                </div>

                {/* <div className="landing-button mt-30" onClick={(e) => { setModalAuth(true) }}>
                    <button className="landing-links_auth">Войти</button>
                </div>
                <div className="landing-button mt-16" onClick={(e) => {
                    openRegiserModal(true)
                }}>
                    <button className="landing-links_reg">Зарегистрироваться</button>
                </div>

                <NewModal active={modalAuth} setModal={(e) => { setModalAuth(!modalAuth) }}>
                    <form className="blockAuth" onSubmit={formik.handleSubmit}>
                        <div className="c-i-s-b">
                            <p className="w-p f-s-20 f-w-500">Вход</p>
                            <img src="img/icon/close.svg" alt="Закрыть" onClick={(e) => { setModalAuth(!modalAuth) }}></img>
                        </div>
                        <p className="f-s-12 w-p mt-16">Ваш логин</p>

                        <FreeZoneComponentInput
                            value={formik.values.login}
                            onChange={formik.handleChange}
                            className={formik.errors.login ? "f-s-14 c-gray mt-8 inp-red " : "inp-txt f-s-14 c-gray mt-8"}
                        />
                        <p className="f-s-12 w-p mt-8">Пароль</p>
                        <FreeZoneComponentPassword
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            className={formik.errors.password ? "f-s-14 c-gray mt-8 inp-red" : "inp-txt f-s-14 c-gray mt-10"}
                        />
                        <div className="mt-10" onClick={
                            (e) => {
                                setModalAuth(!true)
                                openModalRestorePassword();
                            }
                        }>
                            <p className="w-p f-s-14 t-d-u mt-10 f-w-500">Забыли пароль?</p>
                        </div>
                        <button
                            className={!formik.isValid ? "mt-20 btn__disable btn" : "btn mt-20"}
                            type="submit"
                        >
                            <span className="w-p f-s-14 f-w-500">Войти</span>
                        </button>

                        <div className="landing-links_reg border mt-16" onClick={(e) => {
                            openRegiserModal(true);
                            setModalAuth(false)
                        }}>
                            <p className="f-s-14 w-p f-w-500">Зарегистрироваться</p>
                        </div>
                    </form>
                </NewModal> */}

                <NewModal active={modalRegister.step1} setModal={(e) => {
                    closeRegisterModal()
                }}>
                    <div className="blockAuth">
                        <div className="c-i-s-b">
                            <p className="w-p f-s-20 f-w-500">Регистрация</p>
                            <img src="img/icon/close.svg" alt="Закрыть" onClick={(e) => {
                                closeRegisterModal()
                            }}></img>
                        </div>
                        <p className="f-s-12 w-p mt-16">Имя</p>
                        <input name="name" type="text"
                            className="inp-txt f-s-14 c-gray mt-10"
                            placeholder="Введите имя"
                            value={registerInfo.name}
                            onChange={((e) => {
                                setRegisterInfo({
                                    ...registerInfo,
                                    name: e.target.value
                                })
                            })}
                        />
                        <p className="f-s-12 w-p mt-10">Фамилия</p>
                        <input name="name" type="text"
                            className="inp-txt f-s-14 c-gray mt-10"
                            placeholder="Введите фамилию"
                            value={registerInfo.lastName}
                            onChange={((e) => {
                                setRegisterInfo({
                                    ...registerInfo,
                                    lastName: e.target.value
                                })
                            })}
                        />

                        <p className="f-s-12 w-p mt-10">Дата рождения</p>
                        <input name="name" type="date"
                            className="inp-txt f-s-14 c-gray mt-10"
                            onChange={(e) => {
                                setRegisterInfo({
                                    ...registerInfo,
                                    dateBirsday: e.target.value
                                })
                            }}
                        />
                        <p className="f-s-12 w-p mt-10">Укажитие ваш пол</p>
                        <select name="" id=""
                            className="inp-txt f-s-14 c-gray mt-10"
                            onChange={
                                (e) => {
                                    setRegisterInfo({
                                        ...registerInfo,
                                        sex: parseInt(e.target.value)
                                    })
                                }
                            }>
                            <option value="1">Мужской</option>
                            <option value="0">Женский</option>
                            <div className="eye_pass" >
                                <ion-icon
                                ></ion-icon>
                            </div>
                        </select>
                        <div className="landing-links_auth mt-20"
                            onClick={(e) => {
                                sendOneStepRegister();
                            }} >
                            <p className="f-s-16 w-p f-w-500">Продолжить</p>
                        </div>
                        <div className="mt-20 t-a-c" onClick={
                            (e) => {
                                closeRegisterModal();
                                setModalAuth(true)
                            }
                        }>
                            <p className="w-p f-s-14 t-d-u mt-10 f-w-500">Уже зарегистрированы?</p>
                        </div>
                        <div className="c-i mt-10">
                            <div className="checkbox-group">
                                <div className="checkbox-policy f-s-14 white">Продолжая, Вы принимаете условия
                                    <Link to="/legal/privacy_policy">
                                        <span className="white"> политики&nbsp;конфиденциальности</span>
                                    </Link>
                                </div>
                                <label class="checkbox">
                                    <input type="checkbox" class="form-check-label"
                                        value={registerInfo.check}
                                        onChange={
                                            (e) => {
                                                setRegisterInfo({
                                                    ...registerInfo,
                                                    check: e.target.checked
                                                })
                                            }
                                        }
                                    />
                                    <svg viewBox="0 0 21 18">
                                        <symbol id="tick-path" viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.22003 7.26C5.72003 7.76 7.57 9.7 8.67 11.45C12.2 6.05 15.65 3.5 19.19 1.69" fill="none" stroke-width="2.25" strokeLinecap="round" stroke-linejoin="round" />
                                        </symbol>
                                        <defs>
                                            <mask id="tick">
                                                <use class="tick mask" href="#tick-path" />
                                            </mask>
                                        </defs>
                                        <use class="tick" href="#tick-path" stroke="currentColor" />
                                        <path fill="white" mask="url(#tick)" d="M18 9C18 10.4464 17.9036 11.8929 17.7589 13.1464C17.5179 15.6054 15.6054 17.5179 13.1625 17.7589C11.8929 17.9036 10.4464 18 9 18C7.55357 18 6.10714 17.9036 4.85357 17.7589C2.39464 17.5179 0.498214 15.6054 0.241071 13.1464C0.0964286 11.8929 0 10.4464 0 9C0 7.55357 0.0964286 6.10714 0.241071 4.8375C0.498214 2.39464 2.39464 0.482143 4.85357 0.241071C6.10714 0.0964286 7.55357 0 9 0C10.4464 0 11.8929 0.0964286 13.1625 0.241071C15.6054 0.482143 17.5179 2.39464 17.7589 4.8375C17.9036 6.10714 18 7.55357 18 9Z" />
                                    </svg>
                                    <svg class="lines" viewBox="0 0 11 11">
                                        <path d="M5.88086 5.89441L9.53504 4.26746" />
                                        <path d="M5.5274 8.78838L9.45391 9.55161" />
                                        <path d="M3.49371 4.22065L5.55387 0.79198" />
                                    </svg>
                                </label>
                            </div>
                        </div>
                    </div>
                </NewModal>
                {/* Регистрация  -> END [1 этап] - Регистрация*/}

                {/* Регистрация  -> [2 этап] - Номер телефона*/}
                <NewModal active={modalRegister.step2} >
                    <div className="blockAuth">
                        <div className="c-i-s-b">
                            <p className="w-p f-s-20 f-w-b">Регистрация</p>
                            <img src="img/icon/close.svg" alt="Назад" onClick={
                                (e) => {
                                }}>
                            </img>
                        </div>
                        <p className="f-s-12 w-p mt-16">Номер телефона</p>
                        <input name="name" type="tel"
                            className="inp-txt f-s-16 c-gray mt-10"
                            placeholder="+7 (ХХХ) ХХХ-ХХ-ХХ"
                            maxLength={11}
                            onChange={(e) => {
                                setPhoneRegister(e.target.value)
                            }}
                            value={phoneRegister} />
                        <div className="landing-links_auth mt-20" onClick={(e) => {
                            sendTwoStepRegister(e)
                        }} >
                            <p className="f-s-16 w-p">Продолжить</p>
                        </div>
                        <div className="mt-20 t-a-c" onClick={
                            (e) => {
                                closeRegisterModal();
                                setModalAuth(true)
                            }
                        }>
                            <p className="w-p f-s-14 t-d-u mt-10">Уже зарегистрированы?</p>
                        </div>
                    </div>
                </NewModal>
                {/* Регистрация  -> END [2 этап] - Номер телефона*/}

                {/* Регистрация  -> [3 этап] - Email */}
                <NewModal active={modalRegister.step3} >
                    <div className="blockAuth">
                        <div className="c-i-s-b">
                            <p className="w-p f-s-20 f-w-500">Регистрация</p>
                            <img src="img/icon/back.svg" alt="Назад"></img>
                        </div>
                        <p className="f-s-12 c-gray mt-8">Введите свой email, на который будет <br /> отправлен шестизначный код подтверждения</p>
                        <p className="f-s-12 w-p mt-20">Email</p>
                        <input name="name" type="email"
                            className="inp-txt f-s-14 c-gray mt-10"
                            placeholder="Введите Email"
                            onChange={(e) => {
                                setEmailRegister(e.target.value)
                            }
                            }
                            value={emailRegister} />
                        <div className="landing-links_auth mt-20" onClick={(e) => {
                            sendTreeStepRegister(e)
                        }} >
                            <p className="f-s-16 w-p f-w-500">Продолжить</p>
                        </div>
                        <div className="mt-20 t-a-c"
                            onClick={
                                (e) => {
                                    closeRegisterModal();
                                    setModalAuth(true)
                                }
                            }>
                            <p className="w-p f-s-14 t-d-u mt-10 f-w-500">Уже зарегистрированы?</p>
                        </div>
                    </div>
                </NewModal>
                {/* Регистрация  -> END [3 этап] - Email */}


                {/* Регистрация  -> [4 этап] - подтверждение Email*/}
                <NewModal active={modalRegister.step4} >
                    <div className="blockAuth">
                        <div className="c-i-s-b">
                            <p className="w-p f-s-20 f-w-500">Регистрация</p>
                            <img src="img/icon/back.svg" alt="Назад"></img>
                        </div>

                        <p className="f-s-12 c-gray mt-8">Введите шестизначный код, который <br /> был отправлен на ваш email адрес</p>
                        <p className="f-s-12 w-p mt-20">Введите код</p>
                        <input name="name" type="tel"
                            className="inp-txt f-s-14 c-gray mt-10"
                            placeholder="Код с почты" maxLength='6'
                            onChange={(e) => {
                                setCodeEmail(e.target.value);
                            }}
                            value={codeEmail}
                        />
                        <div className="landing-links_auth mt-20" onClick={(e) => {
                            sendThowStepRegister()
                        }} >
                            <p className="f-s-16 w-p f-w-500">Продолжить</p>
                        </div>
                        <div className="mt-20 t-a-c" onClick={
                            (e) => {
                                closeRegisterModal();
                                setModalAuth(true)
                            }
                        }>
                            <p className="w-p f-s-14 t-d-u mt-10 f-w-500">Уже зарегистрированы?</p>
                        </div>
                    </div>
                </NewModal>
                {/* Регистрация  -> END [4 этап] - подтверждение Email*/}

                {/* Регистрация  -> [5 этап] - создание паролей*/}
                <NewModal active={modalRegister.step5} >
                    <div className="blockAuth">
                        <div className="c-i-s-b">
                            <p className="w-p f-s-20 f-w-500">Регистрация</p>
                            <img src="img/icon/back.svg" alt="Назад" ></img>
                        </div>
                        <p className="f-s-12 c-gray mt-8">Чем сложнее ваш пароль, <br /> тем безопаснее ваш аккаунт</p>
                        <p className="f-s-12 w-p mt-20">Введите пароль</p>
                        <input name="name" type="password"
                            className="inp-txt f-s-14 c-gray mt-8"
                            placeholder="Введите пароль"
                            onChange={(e) => {
                                setPasswordRestoreOne(e.target.value)
                            }}
                            value={passwordRestoreOne} />
                        <p className="f-s-12 w-p mt-20">Повторите пароль</p>
                        <input name="name" type="password"
                            className="inp-txt f-s-14 c-gray mt-8"
                            placeholder="Введите пароль ещё раз"
                            onChange={(e) => {
                                setPasswordRestoreTwo(e.target.value)
                            }}
                            value={passwordRestoreTwo} />
                        <div className="landing-links_auth mt-20" onClick={(e) => {
                            sendFiveStepRegister()
                        }} >
                            <p className="f-s-16 w-p f-w-500">Войти</p>
                        </div>
                        <div className="mt-20 t-a-c" onClick={
                            (e) => {
                                closeRegisterModal();
                                setModalAuth(true)
                            }
                        }>
                            <p className="w-p f-s-14 t-d-u mt-10 f-w-500">Уже зарегистрированы?</p>
                        </div>
                    </div>
                </NewModal>

                <NewModal active={modalRestorePassword.step1}>
                    <div className="blockAuth">
                        <div className="c-i-s-b">
                            <p className="w-p f-s-20 f-w-500">Восстановление пароля</p>
                            <img src="img/icon/back.svg" alt="Назад" ></img>
                        </div>
                        <p className="f-s-12 c-gray mt-8 mb-16">Введите свой Email, на который будет выслан <br /> шестизначный значный код подтверждения</p>
                        <label className="f-s-12 w-p">Email</label>
                        <input name="name" type="email"
                            className="inp-txt f-s-14 c-gray mt-8" placeholder="Введите Email"
                            onChange={(e) => setRestoreEmail(e.target.value)}
                            value={restoreEmail} />
                        <div className="landing-links_auth mt-16" onClick={(e) => restoreStepOneSend(e)} >
                            <p className="f-s-16 w-p f-w-500">Продолжить</p>
                        </div>
                        <div className="mt-16 t-a-c" onClick={
                            (e) => {
                                setModalAuth(!true)
                                setModalRestorePassword(!true);
                                setModalAuth(true)
                            }
                        }>
                            <p className="w-p f-s-14 t-d-u mt-16 f-w-500">Уже зарегистрированы?</p>
                        </div>
                    </div>
                </NewModal>
                {/* Регистрация  -> END [3 этап] - Email */}

                {/* Регистрация  -> [4 этап] - подтверждение Email*/}
                <NewModal active={modalRestorePassword.step2}>
                    <div className="blockAuth">
                        <div className="c-i-s-b">
                            <p className="w-p f-s-20">Восстановление пароля</p>
                            <img src="img/icon/back.svg" alt="Назад" ></img>
                        </div>

                        <p className="f-s-12 txt_c_online mt-20">Введите шестизначный код, который был отправлен на ваш email адрес</p>
                        <p className="f-s-12 w-p mt-20">Введите код:</p>
                        <input name="name" type="tel"
                            className="inp-txt f-s-16 c-gray mt-10"
                            placeholder="Код с почты" maxLength='6'
                            onChange={(e) => {
                                setCodeEmail(e.target.value);
                            }}
                            value={codeEmail}
                        />

                        <div className="landing-links_auth mt-20" onClick={
                            (e) => {
                                restoreStepTwoSend()
                            }
                        }  >
                            <p className="f-s-16 w-p">Продолжить</p>
                        </div>
                        <div className="mt-20 t-a-c"  >
                            <p className="w-p f-s-14 t-d-u mt-10">Уже зарегистрированы?</p>
                        </div>
                    </div>
                </NewModal>
                {/* Регистрация  -> END [4 этап] - подтверждение Email*/}

                {/* Регистрация  -> [5 этап] - создание паролей*/}
                <NewModal active={modalRestorePassword.step3}  >
                    <div className="blockAuth">
                        <div className="c-i-s-b">
                            <p className="w-p f-s-20">Восстановление пароля</p>
                            <img src="img/icon/back.svg" alt="Назад" ></img>
                        </div>
                        <p className="f-s-12 txt_c_online mt-8">Введите пароль</p>
                        <p className="f-s-12 w-p mt-20">Придумай Пароль</p>
                        <input name="name" type="password"
                            className="inp-txt f-s-16 c-gray mt-10"
                            placeholder="Введите пароль"
                            onChange={(e) => {
                                setPasswordRestoreOne(e.target.value)
                            }}
                            value={passwordRestoreOne} />
                        <input name="name" type="password"
                            className="inp-txt f-s-16 c-gray mt-10"
                            placeholder="Повторите пароль"
                            onChange={(e) => {
                                setPasswordRestoreTwo(e.target.value)
                            }}
                            value={passwordRestoreTwo} />
                        <div className="landing-links_auth mt-20" onClick={(e) => {
                            restoreStepTreeSend();
                        }}  >
                            <p className="f-s-16 w-p f-w-500">Войти</p>
                        </div>
                        <div className="mt-20 t-a-c f-w-500">
                            <p className="w-p f-s-14 t-d-u mt-10">Уже зарегистрированы?</p>
                        </div>
                    </div>
                </NewModal>

                <NewModal active={modelBeta} setModal={setModelBeta} >
                    <form onSubmit={formikAlfa.handleSubmit}>
                        <div className="blockAuth">
                            <div className="c-i-s-b">
                                <p className="w-p f-s-20 f-w-500 mb-10">Регистрация на альфа-тест</p>
                                <img src="img/icon/close.svg" alt="Назад" onClick={(e) => setModelBeta(!modelBeta)}></img>
                            </div>
                            <label className="f-s-12 w-p">Email</label>
                            <input type="email"
                                name="email"
                                value={formikAlfa.values.email}
                                className="inp-txt f-s-14 c-gray mt-10"
                                onChange={formikAlfa.handleChange}
                                autoComplete="off"
                                required
                                ref={input}
                                placeholder="Введите Email"
                            />
                            <div className="checkbox-group">
                                <div className="checkbox-policy f-s-14 white">Продолжая, Вы принимаете условия
                                    <Link to="/legal/privacy_policy">
                                        <span className="white"> политики&nbsp;конфиденциальности</span>
                                    </Link>
                                </div>
                                <label class="checkbox">
                                    <input type="checkbox" class="form-check-label"
                                        value={formikAlfa.values.checkbox}
                                        name="checkbox"
                                        onChange={formikAlfa.handleChange}
                                    />
                                    <svg viewBox="0 0 21 18" style={{
                                        color: "white"
                                    }}>
                                        <symbol id="tick-path" viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.22003 7.26C5.72003 7.76 7.57 9.7 8.67 11.45C12.2 6.05 15.65 3.5 19.19 1.69" fill="none" stroke-width="2.25" strokeLinecap="round" stroke-linejoin="round" />
                                        </symbol>
                                        <defs >
                                            <mask id="tick">
                                                <use class="tick mask" href="#tick-path" />
                                            </mask>
                                        </defs>
                                        <use class="tick" href="#tick-path" stroke="currentColor" />
                                        <path fill="white" mask="url(#tick)" d="M18 9C18 10.4464 17.9036 11.8929 17.7589 13.1464C17.5179 15.6054 15.6054 17.5179 13.1625 17.7589C11.8929 17.9036 10.4464 18 9 18C7.55357 18 6.10714 17.9036 4.85357 17.7589C2.39464 17.5179 0.498214 15.6054 0.241071 13.1464C0.0964286 11.8929 0 10.4464 0 9C0 7.55357 0.0964286 6.10714 0.241071 4.8375C0.498214 2.39464 2.39464 0.482143 4.85357 0.241071C6.10714 0.0964286 7.55357 0 9 0C10.4464 0 11.8929 0.0964286 13.1625 0.241071C15.6054 0.482143 17.5179 2.39464 17.7589 4.8375C17.9036 6.10714 18 7.55357 18 9Z" />
                                    </svg>
                                    <svg class="lines" viewBox="0 0 11 11">
                                        <path d="M5.88086 5.89441L9.53504 4.26746" />
                                        <path d="M5.5274 8.78838L9.45391 9.55161" />
                                        <path d="M3.49371 4.22065L5.55387 0.79198" />
                                    </svg>
                                </label>




                            </div>
                            <SmartCaptcha sitekey="EYankN1SrbEbaTd1asXGXXfgsV5nHKyN1HY3YfQ7" onSuccess={setTokeny} />
                            <div className="landing-button mt-30">
                                <button
                                    type="submit"
                                    className="landing-links_auth btn-alfa"
                                    disabled={!buttonCheckAlfa}
                                >
                                    Отправить
                                </button>
                            </div>
                        </div>
                    </form>
                </NewModal>



            </div >
            <div className="mt-40 text_information f-s-16 w-p mb-10">
                <div className="f-s-24 title">О нас</div>
                <div className="about__team-text">
                    <p className="mt-16 mb-10">
                        Marlo — мультиприложение, которое содержит множество различных полезных сервисов.
                    </p>
                    <p className="mt-16 mb-10 ">
                        Наша миссия — сделать удобный прорывной продукт, который станет твоим основным сервисом на каждый день.
                    </p>
                    <p className="mt-16 mb-10">
                        Только представь, что самое лучшее из популярных сервисов и приложений будет воплощено всего лишь в одном - Marlo.
                    </p>
                    <p className="mt-16 mb-10">
                        Дизайн и функциональность Marlo — отражение современных требований рынка. Мы собираем обратную связь, дорабатываем и добавляем нужные функции.
                    </p>
                </div>
                <div className="social__media">
                    <p className="mt-30">Мы в соц. сетях</p>
                </div>
                <ButtonSocial />
            </div>
            {


            /*
            <div className="mt-40 text_information f-s-16 w-p mb-10">
                <div className="title f-s-24 title">Состав</div>
                <p className="mt-15 mb-10 team-subtitle">Состав команды Marlo представлен <br /> на момент 9 января 2023 года</p>
            </div>
            <ComandaList />
            */

            }
            <div className="footer">
                <p className="footer-copyright f-s-14 w-p">&copy; Marlo, 2023</p>
                <a href="#" className="arrow">
                    <img src="img/landing/arrow-up.svg" alt="" />
                </a>
            </div>
            <Error active={isError} setActive={setError} />
            <Error active={errorEmail} setActive={setErrorEmail}>Email не корректный</Error>
            <Error active={errorCodeEmail} setActive={setErrorCodeEmail}>Код с почты не корректный</Error>
            <Error active={errorRestore} setActive={setErrorRestore} children={errorRestoreComment} />
        </div >
    )

}

export default NewIndex;
