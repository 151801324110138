import React from 'react'
import { Link } from 'react-router-dom'

export default function PrivateZone() {
	return (
		<div className='animation'>
			<div className='m_block c-i-s-b'>
				<Link to='/MainSettings'>
					<div className="block_in_svg_2">
						<img className="exit-component-icon " src='img/icon/right-red.svg' alt="exit:icon" />
					</div>
				</Link>
				<div className='t-a-c c-gray f-s-16 m-0'>
					<p>Верификация</p>
				</div>
			</div>
			<div className='m_block mt-20 c-gray verify-main-text'>
				<p>Верификация является подтверждением, что за профилем или сообществом стоит реальное лицо/организация. Это удобный инструмент для поиска оригинального контента</p>
				<p className='mt-15'>Процесс верификации занимает от 7-14 дней. Если твоя кандидатура будет одобрена, ты получишь сообщение об этом и галочку рядом с твоим профилем</p>
			</div>

			<div className='m_block mt-20 c-gray verify-main-text'>
				<p>Ноооо! Так как сейчас идет альфа-тест, мы тебе дадим галочку быстро и без всяких проверок)))</p>
			</div>
			{/* <div className='m_block mt-20'>
				<p className="white f-s-16 ">Общая информация:</p>
				<div className="mt-10 c-gray f-s-14 verify-textarea">
					<textarea type="text" className='inp-txt f-s-16 c-gray textarea' placeholder='' />
					<p className='mt-10'>Расскажи как можно подробней о себе и своей деятельности. Укажи все ссылки на СМИ и соц. сети, в которых информация о тебе будет актуальный и достоверной.</p>
				</div>
			</div> */}
			{/* <div className='m_block mt-20'>
				<p className="c-gray f-s-16">VK:</p>
				<div className="c-i mt-10">
					<input type="text" className='inp-txt f-s-16 c-gray tel' placeholder='@' />
				</div>
				<p className="c-gray f-s-16 mt-20">YouTube:</p>
				<div className="c-i mt-10">
					<input type="text" className='inp-txt f-s-16 c-gray tel' placeholder='@' />
				</div>
			</div> */}
			<div className='btn__exit-group  mt-20'>
				<button className="btn-main-accen btn-2-red c-i f-s-14" >
					<img src="img/icon/saving.svg" alt="" />
					<p className='white ml-10'>Подать заявку</p>
				</button>
			</div>
		</div>
	)
}
