import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import StaticService from "../../API/StaticService";
import useReques from "../../hooks/useReques";

// Вывод статических документов

export default function FreeZoneDocumentPage({ numberDocument, langDocument, nameDocument }) {
    const [document, setDocument] = useState('Загрузка...');

    function createMarkup() {
        return { __html: document };
    }

    function MyComponent() {
        return <div dangerouslySetInnerHTML={createMarkup()} />;
    }

    function DisplayFreeZoneInfo() {
        return (
            <div className="mt-30 f-s-16 c-gray">
                <p className="mt-30 mb-10">
                    <MyComponent />
                </p>
            </div>
        )
    }
    useEffect(() => {
        fetching(numberDocument, langDocument)
    }, []);

    const [fetching, modal, errorRegister] = useReques(async (uuid, lang) => {
        const response = await StaticService.staticDockument(uuid, lang)
        setDocument(response.data.text)
        console.log(response)
    })

    return (
        <div className="start-animation">
            <main className="container mb-20">
                <div className="c-i t-a-c">
                    <div className="block">
                        <div className="policy-header mb-15">
                            <Link to="/" className="w-p">
                                <img className="back-img-policy" src="../img/icon/right-red.svg" alt="back" />
                            </Link>
                            <div className="policy-header-group">
                                <p className="white f-w-500 f-s-16 c-i"> {nameDocument}</p>
                                <p className="c-gray f-s-14 mt-5 t-a-c">Редакция от 26.11.2022 года</p>
                            </div>
                        </div>
                        <div className="mt-20 bb-g"></div>
                        <DisplayFreeZoneInfo />
                    </div>
                </div>
            </main>
            <div className="footer">
                <p className="footer-copyright f-s-14 w-p">&copy; Marlo, 2023</p>
                <a href="#" className="arrow">
                    <img src="../img/landing/arrow-up.svg" alt="" />
                </a>
            </div>
        </div>
    )

}
