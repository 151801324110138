import React from 'react'
import { Link } from 'react-router-dom'

export default function MenuGroup() {

    return (
        <div className="block-w mt-10">
            <Link to="Group">
                <div className="list__item">
                    <div className="d-f-a-c">
                        <div className="music-ava">
                            <img src="img/png/3.png" className="music-ava" alt="" />
                        </div>
                        <div className="ml-15">
                            <p className="white-s-16">Россия 23</p>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )

}