import React, { useEffect } from 'react';
import "./Modal.css";

const ModalAnalytics = ({ active, setActive }) => {
    useEffect(() => {

        setInterval(
            () => setActive(false),
            3500
        );
    }, []);


    return (
        <div id='achives' className={active ? "modelOptions active" : "modelOptions"} onClick={() => setActive(false)}>
            <div className="block_options" onClick={e => e.stopPropagation()}>
                <div className="no-back_block c-i">
                    <p className='t-a-c ml-10 f-s-16 white-w-500'>Раздел аналитики находится в разработке</p>
                </div>
            </div>
        </div>
    )
}

export default ModalAnalytics;