import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
	name: 'ui',
	initialState: {
		showModal: false,
		typeModal: ''
	},
	reducers: {
		setModal: (state, { payload }) => {
			state.showModal = payload.showModal;
			state.typeModal = payload.typeModal;
		},
		resetModal: (state, action) => {
			state.showModal = false;
			state.typeModal = '';
		},
	},
});

export const uiSelector = (state) => state.ui;
export const { setModal, resetModal } = uiSlice.actions;
export default uiSlice.reducer;