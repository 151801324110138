import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import ErrorPage from './pages/error';
import PrivateZoneHomePage from './pages/private_zone/home';
import PrivateZoneSettingPage from './pages/private_zone/setting';
import PrivateZoneNewsPage from './pages/private_zone/news';
import PrivateZoneSmsPage from './pages/private_zone/sms';
import PrivateZoneOtherPage from './pages/private_zone/other';
import PrivateZoneMainFriends from './pages/private_zone/friends/main_friends';
import PrivateZoneExit from './pages/exit';
import UserProfile from './pages/private_zone/user';
import PrivateZoneSubscribe from './pages/private_zone/groups/Subscribe'
import PrivateZoneSubscribeFriends from './pages/private_zone/friends/SubscribeFriends'
import PrivateZoneOnline from './pages/private_zone/friends/online'
// Друзья
import PrivateZoneFriends from './pages/private_zone/friends/friends'
import PrivateZoneBlackList from './pages/private_zone/friends/BlackList'
import PrivateZoneMyBlackList from './pages/private_zone/friends/MyBlackList'
import PrivateZoneWhoBlackList from './pages/private_zone/friends/WhoBlackList'
// Фотогалерея
import PrivateZonePhoto from './pages/private_zone/photo'
// Группы
import PrivateZoneMainGroup from './pages/private_zone/groups/MainGroup'
import PrivateZoneGroup from './pages/private_zone/groups/Group'
import PrivateZoneGroupList from './pages/private_zone/groups/GroupList'
// Настройки
import PrivateZoneMainSettings from './pages/private_zone/settings/MainSettings'
import PrivateZoneDefaultSettings from './pages/private_zone/settings/DefaultSettings'
import PrivateZoneOptionalSettings from './pages/private_zone/settings/OptionalSettings'
import PrivateZoneVerifySettings from './pages/private_zone/settings/VerifySettings'
import PrivateZoneGroupBlackList from './pages/private_zone/groups/GroupBlackList';
import PrivateZoneGroupWhoBlackList from './pages/private_zone/groups/GroupWhoBlackList';
import PrivateZoneSecuritySettings from './pages/private_zone/settings/SecuritySetting';
import PrivateZoneLanguageSettings from './pages/private_zone/settings/LanguageSettings';
import FreeZoneRecoverConfimPage from './pages/free_zone/recoverConfim';
import FreeZoneRecoverPasswordPage from './pages/free_zone/recoverPassword';
import FreeZoneDocumentPage from './pages/free_zone/document';
import { useState } from 'react';
import NewIndex from './pages/free_zone/newIndex';

import { useSelector, } from 'react-redux';
import { uiSelector } from "./store/slice/uiSlice";
import ModalAlfaTest from './components/Modal/ModalAlfaTest';
function App() {
	const modal = useSelector(uiSelector);

    const [token, setToken] = useState(localStorage.getItem("tokenUser"));


  if (token) {

    return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<PrivateZoneHomePage />} /> {/* Главная страница в неавторизованной зоне*/}
            {/* Редирект на страницу с ошибками */}
            <Route path='*' element={<ErrorPage />} />
            {/*  */}

            {/* Приватная зона, потом пофиксить */}
            <Route path='home' element={<PrivateZoneHomePage />} />
            <Route path='setting' element={<PrivateZoneSettingPage />} />
            <Route path='news' element={<PrivateZoneNewsPage />} />
            <Route path='sms' element={<PrivateZoneSmsPage />} />
            <Route path='other' element={<PrivateZoneOtherPage />} />
            <Route path='main_friends' element={<PrivateZoneMainFriends />} />
            <Route path="id:userID" element={<UserProfile />} />
            <Route path="exit" element={<PrivateZoneExit />} />
            <Route path="login" element={<Navigate to="/home" />} />
            <Route path='photo' element={<PrivateZonePhoto />} />
            <Route path='friends' element={<PrivateZoneFriends />} />
            <Route path='BlackList' element={<PrivateZoneBlackList />} />
            <Route path='MyBlackList' element={<PrivateZoneMyBlackList />} />
            <Route path='WhoBlackList' element={<PrivateZoneWhoBlackList />} />
            <Route path='MainGroup' element={<PrivateZoneMainGroup />} />
            <Route path='Group' element={<PrivateZoneGroup />} />
            <Route path='GroupList' element={<PrivateZoneGroupList />} />
            <Route path='MainSettings' element={<PrivateZoneMainSettings />} />
            <Route path='SecuritySettings' element={<PrivateZoneSecuritySettings />} />
            <Route path='DefaultSettings' element={<PrivateZoneDefaultSettings />} />
            <Route path='OptionalSettings' element={<PrivateZoneOptionalSettings />} />
            <Route path='LanguageSettings' element={<PrivateZoneLanguageSettings />} />
            <Route path='VerifySettings' element={<PrivateZoneVerifySettings />} />
            <Route path='online' element={<PrivateZoneOnline />} />
            <Route path='Subscribe' element={<PrivateZoneSubscribe />} />
            <Route path='SubscribeFriends' element={<PrivateZoneSubscribeFriends />} />
            <Route path='GroupBlackList' element={<PrivateZoneGroupBlackList />} />
            <Route path='GroupWhoBlackList' element={<PrivateZoneGroupWhoBlackList />} />
            {/*  */}

          </Routes>
        </Router>
      </div>
    )
  } else {
    return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<NewIndex />} /> {/* Главная страница в неавторизованной зоне*/}

            <Route path='legal/privacy_policy' element={<FreeZoneDocumentPage
            numberDocument="ea5c38ae-6d64-11ed-8f49-a4bf018c55f4" langDocument="ru"
            nameDocument="Политика конфиденциальности"/>} />

            <Route path="exit" element={<PrivateZoneExit />} />

            {/* Редирект на страницу с ошибками */}
            <Route path='*' element={<ErrorPage />} />
            {/*  */}

          </Routes>

          <ModalAlfaTest />
        </Router>

      </div>
    )
  }
  // Пока только не авторизованная зона

}

export default App;
