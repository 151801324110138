import React, { useEffect, useRef, useState } from "react";
import "./Modal.css";
import { useFormik } from 'formik';
import { emailSchema } from "../../yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { resetModal, uiSelector } from "../../store/slice/uiSlice";
import Errors from "../Modal/Errors";
import { useSelector, } from 'react-redux';

import { SmartCaptcha } from '@yandex/smart-captcha';

const ModalAlfaTest = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const input = useRef();

    const [tokeny, setTokeny] = useState("");

	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

    const modal = useSelector(uiSelector);

	const formik = useFormik({
		initialValues: {
			email: '',
			checkbox: false,
            tokeny,
		},
		validationSchema: emailSchema,
		onSubmit: async ({ email, tokeny }) => {
			try {
				const response = await axios.post('https://dev.api.marlomba.ru/api/alfa_test', { email, tokeny });

				if (response.status) {
					setErrorMessage("Письмо отправлено на почту")
					setError(true)
					setTimeout((e) => (dispatch(resetModal())), 5000)
					formik.resetForm();
				}
			} catch (error) {
				console.error(error);
				setErrorMessage("Почта уже зарегистрирована")
				setError(true)
			}
		},
	});

    const [buttonCheckAlfa, buttonCheckAlfaSet] = useState(false);

    useEffect(() => {
        console.log(buttonCheckAlfa)
    }, [buttonCheckAlfa]);

    useEffect(() => {



        if (formik.values.checkbox && formik.isValid && tokeny){
            buttonCheckAlfaSet(true)
        }   else {
            buttonCheckAlfaSet(false)
        }

    }, [formik.values.checkbox, formik.isValid, tokeny]);


    useEffect(() => {
        formik.values.tokeny = tokeny;
    }, [tokeny]);


	// useEffect(() => {
	// 	input.current.focus();
	// }, []);

	const handleNavigate = () => {
		navigate('legal/privacy_policy');
		window.location.reload();
	}

    if (modal.showModal ) {
        let modal2 = {
            display: "block",
        }
    }  else {
        let modal2 = {
            display: "none",
        }
    }

	return (
		<div
			id='achives'

            className={!modal.showModal ? "modelOptions" : "modelOptions active" }
            style={{
				display: "block"
			}}


		>
			<div style={{
				display: "flex",
				justifyContent: "right",
				position: "fixed",
				width: "100%"
			}}>
				<img src="img/icon/close2.svg" alt=""
					style={{
						marginTop: "15px",
						marginRight: "15px",
						background: "#333",
						borderRadius: "8px"
					}}

					onClick={(e) => {
						dispatch(resetModal());
					}}
				/>
			</div>
			<div className="modelComment_block modal-alfa ">
				<div className="no-back_block c-i">

					<div className="form-alfa">
						<form onSubmit={formik.handleSubmit}>
							<input
								type="email"
								name="email"
								value={formik.values.email}
								className="modal-alfa__input"
								onChange={formik.handleChange}
								autoComplete="off"
								required
								ref={input}

								placeholder="Email"
							/>
							<div className="c-i mt-10">
								<div className="checkbox-group">
									<div className="checkbox-policy f-s-14 white">Продолжая, Вы принимаете условия
										<span
											className="white form-alfa__link"
											onClick={handleNavigate}
										> политики&nbsp;конфиденциальности
										</span>
									</div>
									<label class="checkbox">
										<input
											value={formik.values.checkbox}
											type="checkbox"
											name="checkbox"
											class="form-check-label"
											onChange={formik.handleChange}
										/>
										<svg viewBox="0 0 21 18" style={{
											color: "white"
										}}>
											<symbol id="tick-path" viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg">
												<path d="M5.22003 7.26C5.72003 7.76 7.57 9.7 8.67 11.45C12.2 6.05 15.65 3.5 19.19 1.69" fill="none" stroke-width="2.25" strokeLinecap="round" stroke-linejoin="round" />
											</symbol>
											<defs>
												<mask id="tick" >
													<use class="tick mask" href="#tick-path"
													/>
												</mask>
											</defs>
											<use class="tick" href="#tick-path" stroke="currentColor" />
											<path fill="white" mask="url(#tick)" d="M18 9C18 10.4464 17.9036 11.8929 17.7589 13.1464C17.5179 15.6054 15.6054 17.5179 13.1625 17.7589C11.8929 17.9036 10.4464 18 9 18C7.55357 18 6.10714 17.9036 4.85357 17.7589C2.39464 17.5179 0.498214 15.6054 0.241071 13.1464C0.0964286 11.8929 0 10.4464 0 9C0 7.55357 0.0964286 6.10714 0.241071 4.8375C0.498214 2.39464 2.39464 0.482143 4.85357 0.241071C6.10714 0.0964286 7.55357 0 9 0C10.4464 0 11.8929 0.0964286 13.1625 0.241071C15.6054 0.482143 17.5179 2.39464 17.7589 4.8375C17.9036 6.10714 18 7.55357 18 9Z" />
										</svg>
										<svg class="lines" viewBox="0 0 11 11">
											<path d="M5.88086 5.89441L9.53504 4.26746" />
											<path d="M5.5274 8.78838L9.45391 9.55161" />
											<path d="M3.49371 4.22065L5.55387 0.79198" />
										</svg>
									</label>
								</div>
							</div>
                            <SmartCaptcha sitekey="EYankN1SrbEbaTd1asXGXXfgsV5nHKyN1HY3YfQ7" onSuccess={setTokeny} />
							<div className="landing-button mt-30">
								<button
									type="submit"
									className="landing-links_auth btn-alfa"
									disabled={!buttonCheckAlfa}
								>
									Отправить
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<Errors active={error} setActive={setError}><p style={{
				color: "white"
			}}>{errorMessage}</p></Errors>
		</div>
	);
};

export default ModalAlfaTest;
