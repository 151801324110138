import React from 'react';
import "../Modal/Modal.css";

const Error = ({ active, setActive, children }) => (
	<div
		id='achives'
		className={active ? "model active" : "model"}
		onClick={() => setActive(false)}>
		<div className="block_options" /* onClick={e=>e.stopPropagation()} */>
			<div className="no-back_block c-i">
				<img src="/img/icon/info-circle.svg" alt="" />
				<p className='t-a-c ml-10 f-s-16 white-w-500' style={{
                    color: "white"
                }}>{children ? children : "Что-то пошло не так :("}</p>
			</div>
		</div>
	</div>
);

export default Error;
