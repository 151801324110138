import React from 'react';
import "./Modal.css";

const ModalEditText = ({active, setActive}) => {
    return (
        <div className={active ? "modelOptions active" : "modelOptions"} onClick={() => setActive(false)}>
            <div className="block_options" onClick={e=>e.stopPropagation()}>
                <div className="no-back_block">  
                    <div className=" mt-10 mb-10 t-a-c c-i">
                        <textarea placeholder='Время обдрочиться!' className='textarea f-s-14 name'></textarea>
                    </div>
                    <div className=" mt-20 mb-10 btn-2-red t-a-c c-i" onClick={() => setActive(false)}>
                        <img src="../img/icon/save2.svg" alt='' />
                        <p className="ml-10 white f-s-16">Сохранить</p>
                    </div>
                </div>
                
            </div>
        </div>
    )
}


export default ModalEditText;