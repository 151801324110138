import React from 'react'
import { Link } from 'react-router-dom'
import MenuReactPrivate from '../../../components/UI/menu/MenuReactPrivate'

export default function PrivateZoneWhoBlackList() {
	return (
		<div className='animation-home'>
			<div className='m_block c-i-s-b'>
				<Link to='/main_friends'>
					<img className="exit-component-icon" src='img/icon/right-red.svg' alt="exit:icon" />
				</Link>
				<div className='t-a-c c-gray f-s-16 m-0'>
					<p>Кто у меня в ЧС:</p>
				</div>
				<Link to='/other'>
					<div className='other_ava'>
						<img src='../img/png/1.png' width='100%' alt="" />
					</div>
				</Link>
			</div>
			<div className='search mt-10 pd-t-10'>
				<div className='icon-friend-all'>
					<img src='img/icon/search.svg' alt='search:icon' />
				</div>
				<input
					className='search-friend-all'
					type='search'
					placeholder='Да как его зовут-то...'
				/>
			</div>
			<div className='MyBlacklist m_block mt-10'>
				<p className='f-s-16 whitet-a-c p-10'>Таких пока нет</p>
			</div>
			{/* меню */}
			<MenuReactPrivate />
		</div>
	)
}
