import {config} from '../config/main';
import axios from "axios";
import getURL from './getURL';
/*
Класс, который отвечает за вход
*/

const headers = {
    'Content-type' : 'application/x-www-form-urlencoded',
}

const headers1 = {
    'Content-type' : 'application/raw',
    'Access-Control-Allow-Origin': '*'
}


/* export const Axios = axios.create({
    baseURL: "http://217.18.62.127:3001",
    withCredentials: true
}) */


const URL_API = "https://dev.api.marlomba.ru/api/"

export default class LoginService {

    static async getToken(login, password) {
        const response = await axios.post(URL_API+'auth/login.php', JSON.stringify({
            tel: login,
            pass: password
        }), {
            headers: headers
        })
        return response
    }


    // Получение нового токена
    static async getTokenNew (login, password, fitngerPrint){
        const response = await axios.post(getURL(), {
            tel:login,
            password,
            FINGERPRINT: fitngerPrint,
        })

        return response
    };

	// удаление токена
	static deleteToken = async (token) => {
		const url = getURL(`user/Tocken/${token}/del`);
		const response = await axios.get(url);
		return response;
	};

    static returnURL() {
        return URL_API
    }

    static async checkToken(session_id, id){
        // Доделать
            //const urls =  API_URL+'func/check_token.php'
            //console.log(API_URL+'func/check_token.php')
            const response = await axios.get(URL_API+'/user/Tocken/'+session_id+'/check')
            return response
    }

    static async resetPassword(phone) {
        const response  = await axios.post(URL_API+'auth/change_pass.php', JSON.stringify({
            tel: phone,
            state: "1"
        }), {
            headers: headers
        })
        return response
    }

    static async resetPasswordStepTwo(phone, token) {
        const response  = await axios.post(URL_API+'auth/change_pass.php', JSON.stringify({
            tel: phone,
            code: token,
            state: "2"
        }), {
            headers: headers
        })
        return response
    }

    static async resetPasswordStepThree(phone, password ) {
        const response  = await axios.post(URL_API+'auth/change_pass.php', JSON.stringify({
            tel: phone,
            password: password,
            state: "3"
        }), {
            headers: headers
        })
        return response
    }

}
