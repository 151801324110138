import React from 'react'
import { Link } from 'react-router-dom'

export default function PrivateZoneMainSettings() {
	return (
		<div className='animation'>
			<div className='m_block c-i-s-b'>
				<Link to='/other'>
					<div className="block_in_svg_2">
						<img className="exit-component-icon " src='img/icon/right-red.svg' alt="exit:icon" />
					</div>
				</Link>
				<div className='t-a-c c-gray f-s-16 m-0'>
					<p>Настройки</p>
				</div>
			</div>
			<div className='m_block mt-20'>
				<Link to="/DefaultSettings">
					<div className='setting__main-group d-f-a-c'>
						<div className="setting__main-photo mt-30">
							<img src="./img/png/1.png" className='ava_profil ' alt="" />
						</div>
						<div className='setting__main-info ml-15 c-gray'>
							<p className='setting__main-name w-p'>Damian Derc <img src="img/icon/magic-star.svg" className="ml-7" alt='' /></p>
							<p className='setting__main-tel mt-5'>8 (926) 762-75-17</p>
							<p className='setting__main-email mt-5'>@damian_derc</p>
						</div>
					</div>
				</Link>
			</div>
			<div className='m_block mt-20'>
				<Link to="/DefaultSettings">
					<div className=''>
						<p className="white-16 default-setting-group ml-m-5">Основные настройки</p>
					</div>
				</Link>
				<div className='setting__main-optional-group mt-10'>
					<div className='setting__optional c-gray'>
						
						<Link className='d-f-a-c  mt-10' to="/DefaultSettings">
							<img src="img/icon/accont.svg" alt="" />
							<p className='setting__optional-lang ml-10 white'>Аккаунт</p>
						</Link>
						<Link className='d-f-a-c mt-10 ' to="/SecuritySettings">
							<img src="img/icon/key-square.svg" alt="" />
							<p className='setting__optional-private ml-10 white'>Безопасность</p>
						</Link>
					</div>
				</div>
			</div>
			<div className='m_block mt-20'>
				<Link to="/OptionalSettings">
					<div className=''>
						<p className="white-16 default-setting-group ml-m-5">Дополнительные настройки</p>
					</div>
				</Link>
				<div className='setting__main-optional-group mt-10'>
					<div className='setting__optional '>
						<Link className='d-f-a-c  mt-10 ' to="/OptionalSettings">
							<img src="img/icon/brush.svg" alt="" />
							<p className='setting__optional-theme ml-10 white'>Оформление</p>
						</Link>
						<Link className='d-f-a-c  mt-10 ' to="/LanguageSettings">
							<img src="img/icon/global.svg" alt="" />
							<p className='setting__optional-lang ml-10 mt-2 white'>Язык</p>
						</Link>
					</div>
				</div>
			</div>
			<div className='m_block mt-20'>
			<Link to="/OptionalSettings">
					<div className=''>
						<p className="white-16 default-setting-group ml-m-5">Верификация аккаунта</p>
					</div>
				</Link>
				
				<div className='setting__main-verify-group'>
					<Link className='d-f-a-c mt-10' to="/VerifySettings">
						<img src="img/icon/magic-star-no-active.svg" alt="" />
						<p className='setting__verify ml-10 white'>Получить статус альфа-тестера</p>
					</Link>
				</div>
			</div>

			<div className='btn__exit-group mt-20'>
				<button className="btn-main-accent btn-2-red  c-i">
					<img src="img/icon/logout.svg" alt="" />
					<p className='white ml-10 f-s-16'> Выйти из аккаунта </p>
				</button>
			</div>
		</div >
	)
}
