import React from 'react';
import { Link } from "react-router-dom";

function ButtonSocial() {
    return (
        <div className="social mb-40">
            <div className="social-button">
                <a href="https://t.me/marlo_official">
                    <div className="social-telegram mr-20">
                        <img src="img/landing/Telegram.svg" alt="Телеграм" />
                    </div>
                </a>
            </div>
            <div className="social-button">
                <a href="https://vk.com/marlo_official">
                    <div className="social-vk">
                        <img src="img/landing/Vkontakte.svg" alt="Вконтакте" />
                    </div>
                </a>
            </div>
        </div>
    )
}


export default ButtonSocial;
