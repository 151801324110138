import React from "react";

export default function MenuPosts() {
    return (
        <div>
            <div className="post-wrap m_block mt-10">
                <div className="c-i-s-b">
                    <div className="d-f-a-c">
                        <div className="post_ava">
                            <img src="../img/png/group/group-ava-1.png" alt="" />
                        </div>
                        <div className="ml-10 txt2">
                            <p className="f-s-16 whitegroup-name">
                                <span>Россия 23</span>
                                <svg className='ml-10' width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 9L4.23309 11.4248C4.66178 11.7463 5.26772 11.6728 5.60705 11.2581L14 1" stroke="#D20C34" stroke-linecap="round" />
                                </svg>
                            </p>
                            <span className="mt-10 f-s-13 txt_c_online">Сегодня в 23:00</span>
                        </div>
                    </div>
                    <div className="options">
                        <img src="img/icon/options.svg" alt="" />
                    </div>
                </div>
                <div className='post-user-txt c-gray'>
                    <p>Производитель ликёра Jägermeister остановил поставки в Россию.</p>

                    <p>Об этом заявил омбудсмен в сфере ресторанного бизнеса Сергей Миронов. Он отметил, что поставщики уже уведомили рестораны о повышении цен на остатки напитка.</p>

                    <p>Обновление: по сообщениям СМИ, дистрибьютор Jägermeister не прекращает сотрудничество с производителем ликёра, хотя ранее наблюдались перебои с поставками.</p>
                </div>
                <div className="post_photo mt-20">
                    <img src="./img/png/group/post-img-1.jpg" alt="" />
                </div>
                <div className="mt-10 c-i-s-b">
                    <div className='d-f-a-c '>
                        <div className='d-f-a-c likes-post-act'>
                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.70893 11.5901L9.50278 17.0328C9.64793 17.1691 9.7205 17.2373 9.79942 17.2719C9.9273 17.3279 10.0728 17.3279 10.2007 17.2719C10.2796 17.2373 10.3522 17.1691 10.4973 17.0328L16.2911 11.5901C17.9213 10.0587 18.1192 7.53875 16.7482 5.77164L16.4904 5.43937C14.8503 3.3254 11.5581 3.67993 10.4056 6.09463C10.2428 6.43572 9.75728 6.43572 9.59448 6.09463C8.44202 3.67993 5.14981 3.3254 3.50966 5.43937L3.25186 5.77164C1.88083 7.53875 2.07879 10.0587 3.70893 11.5901Z" />
                            </svg>

                            <p className='white ml-5 f-s-16'>11</p>
                        </div>
                        <div className='ml-10 d-f-a-c'>
                            <img src="img/icon/comment.svg" alt="" />
                            <p className='ml-5 white f-s-16'>2</p>
                        </div>
                        <div className='ml-10 d-f-a-c'>
                            <img src="img/icon/share.svg" alt="" />
                        </div>
                    </div>
                    <div className='d-f-a-c'>
                        <div className='ml-10 d-f-a-c'>
                            <img src="img/icon/eyes-post.svg" alt="" />
                            <p className='ml-5 white f-s-16'>204</p>
                        </div>
                        <div>
                            <div className='ml-10 d-f-a-c save-post-act save-post'>
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">

                                    <path d="M5.18518 8.88878C5.18518 6.79365 5.18518 5.74608 5.83605 5.09521C6.48693 4.44434 7.53449 4.44434 9.62963 4.44434H12.5926C14.6877 4.44434 15.7353 4.44434 16.3862 5.09521C17.037 5.74608 17.037 6.79365 17.037 8.88878V13.9463C17.037 15.9339 17.037 16.9277 16.4116 17.2317C15.7862 17.5356 15.0048 16.9216 13.4419 15.6936L12.9417 15.3006C12.0629 14.6102 11.6235 14.2649 11.1111 14.2649C10.5987 14.2649 10.1593 14.6102 9.28052 15.3006L8.78034 15.6936C7.21744 16.9216 6.43599 17.5356 5.81058 17.2317C5.18518 16.9277 5.18518 15.9339 5.18518 13.9463V8.88878Z" />

                                    <defs>
                                        <clipPath id="clip0_1_5604">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="post-wrap m_block mt-10">
                <div className="c-i-s-b">
                    <div className="d-f-a-c">
                        <div className="post_ava">
                            <img src="../img/png/group/group-ava-1.png" alt="" />
                        </div>
                        <div className="ml-10 txt2">
                            <p className="f-s-16 whitegroup-name">
                                <span>Россия 23</span>
                                <svg className='ml-10' width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 9L4.23309 11.4248C4.66178 11.7463 5.26772 11.6728 5.60705 11.2581L14 1" stroke="#D20C34" stroke-linecap="round" />
                                </svg>
                            </p>
                            <span className="mt-10 f-s-13 txt_c_online">Сегодня в 23:00</span>
                        </div>
                    </div>
                    <div className="options">
                        <img src="img/icon/options.svg" alt="" />
                    </div>
                </div>
                <div className='post-user-txt c-gray'>
                    <p>Производитель ликёра Jägermeister остановил поставки в Россию.</p>

                    <p>Об этом заявил омбудсмен в сфере ресторанного бизнеса Сергей Миронов. Он отметил, что поставщики уже уведомили рестораны о повышении цен на остатки напитка.</p>

                    <p>Обновление: по сообщениям СМИ, дистрибьютор Jägermeister не прекращает сотрудничество с производителем ликёра, хотя ранее наблюдались перебои с поставками.</p>
                    <p>Производитель ликёра Jägermeister остановил поставки в Россию.</p>

                    <p>Об этом заявил омбудсмен в сфере ресторанного бизнеса Сергей Миронов. Он отметил, что поставщики уже уведомили рестораны о повышении цен на остатки напитка.</p>

                    <p>Обновление: по сообщениям СМИ, дистрибьютор Jägermeister не прекращает сотрудничество с производителем ликёра, хотя ранее наблюдались перебои с поставками.</p>
                </div>
                <div className="mt-10 c-i-s-b">
                    <div className='d-f-a-c '>
                        <div className='d-f-a-c likes-post-act'>
                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.70893 11.5901L9.50278 17.0328C9.64793 17.1691 9.7205 17.2373 9.79942 17.2719C9.9273 17.3279 10.0728 17.3279 10.2007 17.2719C10.2796 17.2373 10.3522 17.1691 10.4973 17.0328L16.2911 11.5901C17.9213 10.0587 18.1192 7.53875 16.7482 5.77164L16.4904 5.43937C14.8503 3.3254 11.5581 3.67993 10.4056 6.09463C10.2428 6.43572 9.75728 6.43572 9.59448 6.09463C8.44202 3.67993 5.14981 3.3254 3.50966 5.43937L3.25186 5.77164C1.88083 7.53875 2.07879 10.0587 3.70893 11.5901Z" />
                            </svg>

                            <p className='white ml-5 f-s-16'>11</p>
                        </div>
                        <div className='ml-10 d-f-a-c'>
                            <img src="img/icon/comment.svg" alt="" />
                            <p className='ml-5 white f-s-16'>2</p>
                        </div>
                        <div className='ml-10 d-f-a-c'>
                            <img src="img/icon/share.svg" alt="" />
                        </div>
                    </div>
                    <div className='d-f-a-c'>
                        <div className='ml-10 d-f-a-c'>
                            <img src="img/icon/eyes-post.svg" alt="" />
                            <p className='ml-5 white f-s-16'>204</p>
                        </div>
                        <div>
                            <div className='ml-10 d-f-a-c save-post-act save-post'>
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">

                                    <path d="M5.18518 8.88878C5.18518 6.79365 5.18518 5.74608 5.83605 5.09521C6.48693 4.44434 7.53449 4.44434 9.62963 4.44434H12.5926C14.6877 4.44434 15.7353 4.44434 16.3862 5.09521C17.037 5.74608 17.037 6.79365 17.037 8.88878V13.9463C17.037 15.9339 17.037 16.9277 16.4116 17.2317C15.7862 17.5356 15.0048 16.9216 13.4419 15.6936L12.9417 15.3006C12.0629 14.6102 11.6235 14.2649 11.1111 14.2649C10.5987 14.2649 10.1593 14.6102 9.28052 15.3006L8.78034 15.6936C7.21744 16.9216 6.43599 17.5356 5.81058 17.2317C5.18518 16.9277 5.18518 15.9339 5.18518 13.9463V8.88878Z" />

                                    <defs>
                                        <clipPath id="clip0_1_5604">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="post-wrap m_block mt-10">
                <div className="c-i-s-b">
                    <div className="d-f-a-c">
                        <div className="post_ava">
                            <img src="../img/png/group/group-ava-1.png" alt="" />
                        </div>
                        <div className="ml-10 txt2">
                            <p className="f-s-16 whitegroup-name">
                                <span>Россия 23</span>
                                <svg className='ml-10' width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 9L4.23309 11.4248C4.66178 11.7463 5.26772 11.6728 5.60705 11.2581L14 1" stroke="#D20C34" stroke-linecap="round" />
                                </svg>
                            </p>
                            <span className="mt-10 f-s-13 txt_c_online">Сегодня в 23:00</span>
                        </div>
                    </div>
                    <div className="options">
                        <img src="img/icon/options.svg" alt="" />
                    </div>
                </div>
                <div className="post_photo mt-20">
                    <div className="post-section">
                        <div
                            className="post-item"
                            style={{ backgroundImage: 'url("img/png/photo-1.png")' }}
                        />
                        <div
                            className="post-item"
                            style={{ backgroundImage: 'url("img/png/photo-2.png")' }}
                        />
                        <div
                            className="post-item"
                            style={{ backgroundImage: 'url("img/png/photo-3.png")' }}
                        />
                        <div
                            className="post-item"
                            style={{ backgroundImage: 'url("img/png/photo-4.png")' }}
                        />
                        <div
                            className="post-item"
                            style={{ backgroundImage: 'url("img/png/photo-5.png")' }}
                        />
                        <div className="post-item" style={{ backgroundImage: 'url("img/png/photo-6.png")' }}
                        />
                    </div>
                </div>
                <div className="mt-10 c-i-s-b">
                    <div className='d-f-a-c '>
                        <div className='d-f-a-c likes-post-act'>
                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.70893 11.5901L9.50278 17.0328C9.64793 17.1691 9.7205 17.2373 9.79942 17.2719C9.9273 17.3279 10.0728 17.3279 10.2007 17.2719C10.2796 17.2373 10.3522 17.1691 10.4973 17.0328L16.2911 11.5901C17.9213 10.0587 18.1192 7.53875 16.7482 5.77164L16.4904 5.43937C14.8503 3.3254 11.5581 3.67993 10.4056 6.09463C10.2428 6.43572 9.75728 6.43572 9.59448 6.09463C8.44202 3.67993 5.14981 3.3254 3.50966 5.43937L3.25186 5.77164C1.88083 7.53875 2.07879 10.0587 3.70893 11.5901Z" />
                            </svg>

                            <p className='white ml-5 f-s-16'>11</p>
                        </div>
                        <div className='ml-10 d-f-a-c'>
                            <img src="img/icon/comment.svg" alt="" />
                            <p className='ml-5 white f-s-16'>2</p>
                        </div>
                        <div className='ml-10 d-f-a-c'>
                            <img src="img/icon/share.svg" alt="" />
                        </div>
                    </div>
                    <div className='d-f-a-c'>
                        <div className='ml-10 d-f-a-c'>
                            <img src="img/icon/eyes-post.svg" alt="" />
                            <p className='ml-5 white f-s-16'>204</p>
                        </div>
                        <div>
                            <div className='ml-10 d-f-a-c save-post-act save-post'>
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">

                                    <path d="M5.18518 8.88878C5.18518 6.79365 5.18518 5.74608 5.83605 5.09521C6.48693 4.44434 7.53449 4.44434 9.62963 4.44434H12.5926C14.6877 4.44434 15.7353 4.44434 16.3862 5.09521C17.037 5.74608 17.037 6.79365 17.037 8.88878V13.9463C17.037 15.9339 17.037 16.9277 16.4116 17.2317C15.7862 17.5356 15.0048 16.9216 13.4419 15.6936L12.9417 15.3006C12.0629 14.6102 11.6235 14.2649 11.1111 14.2649C10.5987 14.2649 10.1593 14.6102 9.28052 15.3006L8.78034 15.6936C7.21744 16.9216 6.43599 17.5356 5.81058 17.2317C5.18518 16.9277 5.18518 15.9339 5.18518 13.9463V8.88878Z" />

                                    <defs>
                                        <clipPath id="clip0_1_5604">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="post-wrap m_block mt-10">
                <div className="c-i-s-b">
                    <div className="d-f-a-c">
                        <div className="post_ava">
                            <img src="../img/png/group/group-ava-1.png" alt="" />
                        </div>
                        <div className="ml-10 txt2">
                            <p className="f-s-16 whitegroup-name">
                                <span>Россия 23</span>
                                <svg className='ml-10' width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 9L4.23309 11.4248C4.66178 11.7463 5.26772 11.6728 5.60705 11.2581L14 1" stroke="#D20C34" stroke-linecap="round" />
                                </svg>
                            </p>
                            <span className="mt-10 f-s-13 txt_c_online">Сегодня в 23:00</span>
                        </div>
                    </div>
                    <div className="options">
                        <img src="img/icon/options.svg" alt="" />
                    </div>
                </div>
                <div className='post-user-txt c-gray'>
                    <p>Производитель ликёра Jägermeister остановил поставки в Россию.</p>

                    <p>Об этом заявил омбудсмен в сфере ресторанного бизнеса Сергей Миронов. Он отметил, что поставщики уже уведомили рестораны о повышении цен на остатки напитка.</p>

                    <p>Обновление: по сообщениям СМИ, дистрибьютор Jägermeister не прекращает сотрудничество с производителем ликёра, хотя ранее наблюдались перебои с поставками.</p>
                </div>
                <div className="post_photo mt-20">
                    <img src="../img/png/1.png" alt="" />
                </div>
                <div className="mt-10 c-i-s-b">
                    <div className='d-f-a-c '>
                        <div className='d-f-a-c likes-post-act'>
                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.70893 11.5901L9.50278 17.0328C9.64793 17.1691 9.7205 17.2373 9.79942 17.2719C9.9273 17.3279 10.0728 17.3279 10.2007 17.2719C10.2796 17.2373 10.3522 17.1691 10.4973 17.0328L16.2911 11.5901C17.9213 10.0587 18.1192 7.53875 16.7482 5.77164L16.4904 5.43937C14.8503 3.3254 11.5581 3.67993 10.4056 6.09463C10.2428 6.43572 9.75728 6.43572 9.59448 6.09463C8.44202 3.67993 5.14981 3.3254 3.50966 5.43937L3.25186 5.77164C1.88083 7.53875 2.07879 10.0587 3.70893 11.5901Z" />
                            </svg>

                            <p className='white ml-5 f-s-16'>11</p>
                        </div>
                        <div className='ml-10 d-f-a-c'>
                            <img src="img/icon/comment.svg" alt="" />
                            <p className='ml-5 white f-s-16'>2</p>
                        </div>
                        <div className='ml-10 d-f-a-c'>
                            <img src="img/icon/share.svg" alt="" />
                        </div>
                    </div>
                    <div className='d-f-a-c'>
                        <div className='ml-10 d-f-a-c'>
                            <img src="img/icon/eyes-post.svg" alt="" />
                            <p className='ml-5 white f-s-16'>204</p>
                        </div>
                        <div>
                            <div className='ml-10 d-f-a-c save-post-act save-post'>
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">

                                    <path d="M5.18518 8.88878C5.18518 6.79365 5.18518 5.74608 5.83605 5.09521C6.48693 4.44434 7.53449 4.44434 9.62963 4.44434H12.5926C14.6877 4.44434 15.7353 4.44434 16.3862 5.09521C17.037 5.74608 17.037 6.79365 17.037 8.88878V13.9463C17.037 15.9339 17.037 16.9277 16.4116 17.2317C15.7862 17.5356 15.0048 16.9216 13.4419 15.6936L12.9417 15.3006C12.0629 14.6102 11.6235 14.2649 11.1111 14.2649C10.5987 14.2649 10.1593 14.6102 9.28052 15.3006L8.78034 15.6936C7.21744 16.9216 6.43599 17.5356 5.81058 17.2317C5.18518 16.9277 5.18518 15.9339 5.18518 13.9463V8.88878Z" />

                                    <defs>
                                        <clipPath id="clip0_1_5604">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        </div>
    )
}