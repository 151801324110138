import React from 'react'
import { Link } from 'react-router-dom'

export default function LiSubscribe({ idUser, nameProfile, foto, type, classwhite }) {

	return (
		<div className={classwhite ? classwhite : "friend-list m_block"} >
			<div className='friend-block c-i-s-b'>
				<div className='d-f-a-c1'>
					<Link to='/home'>
						<div className='post_ava'>
							<img src={foto} alt='' />
						</div>
					</Link>
					<div className='ml-10'>
						<p className='f-s-16 name'>{nameProfile}</p>
					</div>
				</div>
				{
					type === "delete" && <div className='delete-friend'>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								className='i-friend'
								fillRule='evenodd'
								clipRule='evenodd'
								d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM7 13H17V11H7V13Z'
								fill='#C8C8C8'
							/>
						</svg>
					</div>
				}

			</div>
		</div>
	)

}