import React from 'react';
import "./Modal.css";

const ModalArchive = ({active, setActive}) => {
        return (
            <div id='achives' className={active ? "modelOptions active" : "modelOptions"} onClick={() => setActive(false)}>
                <div className="block_options" onClick={e=>e.stopPropagation()}>
                    <div className="no-back_block">  
                        <p className='t-a-c'>Добавлен в архив</p>
                    </div>
                </div>
            </div>
        )
}

export default ModalArchive;