// Вывод дня рождения

export const dayPrint = (stringDat) => {
    const mount = {
        1: "января",
        2: "февраля",
        3: "марта",
        4: "апреля",
        5: "мая",
        6: "июня",
        7: "июля",
        8: "августа",
        9: "сентября",
        10: "октября",
        11: "ноября",
        12: "декабря"
    }

    let day = new Date(stringDat);
    console.log(day)
    console.log(stringDat);
    //day.parse(stringDat);

    return String(day.getDate()).padStart(2, '0')  + " " + mount[(day.getMonth() + 1)] + " " + day.getUTCFullYear()
}
