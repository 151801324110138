import React from 'react'
import { Link } from 'react-router-dom'
import LiFriend from '../../../components/Friends/liFrend'
import MenuReactPrivate from '../../../components/UI/menu/MenuReactPrivate'

export default function friends({ classwhite }) {
    const object = [
        {
            id: 1,
            foto: '../img/png/people-friend/1.png',
            name: 'Дмитрий Бузаев',
        },
        {
            id: 2,
            foto: '../img/png/people-friend/2.png',
            name: 'Семён Боров',
        },
        {
            id: 3,
            foto: '../img/png/people-friend/3.png',
            name: 'Ольга Иванова',
        },
        {
            id: 4,
            foto: '../img/png/people-friend/4.png',
            name: 'Данила Лобакин',
        },
        {
            id: 5,
            foto: '../img/png/people-friend/5.png',
            name: 'Лида Панкова',
        },
        {
            id: 6,
            foto: '../img/png/people-friend/6.png',
            name: 'Никита Голявин',
        },
        {
            id: 7,
            foto: '../img/png/people-friend/7.png',
            name: 'Екатерина Нилова',
        },
        {
            id: 8,
            foto: '../img/png/people-friend/8.png',
            name: 'Антон Прокопчук',
        },
        {
            id: 9,
            foto: '../img/png/people-friend/9.png',
            name: 'Татьяна Сорокина',
        },
        {
            id: 10,
            foto: '../img/png/people-friend/10.png',
            name: 'Анастасия Фикус',
        },
        {
            id: 11,
            foto: '../img/png/people-friend/5.png',
            name: 'Лида Панкова',
        },
    ]

    return (
        <div className='animation-home'>
            <div className='m_block c-i-s-b'>
                <Link to='/main_friends'>
                    <img className="exit-component-icon" src='img/icon/right-red.svg' alt="exit:icon" />
                </Link>
                <div className='t-a-c c-gray f-s-16 m-0'>
                    <p>Подписчики: 11</p>
                </div>
                <Link to='/other'>
                    <div className='other_ava'>
                        <img src='../img/png/1.png' width='100%' alt='' />
                    </div>
                </Link>
            </div>
            <div className='search mt-10 pd-t-10'>
                <div className='icon-friend-all'>
                    <img src='img/icon/search.svg' alt='' />
                </div>
                <input
                    className='search-friend-all'
                    type='search'
                    placeholder='Да как его зовут-то...'
                />
            </div>
            <div className='m_block mt-10 pd-t-0'>
            </div>
            {object.map(user => (
                <LiFriend
                    foto={user.foto}
                    nameProfile={user.name}
                    key={user.id}
                />
            ))}
            <MenuReactPrivate />
        </div>
    )
}
